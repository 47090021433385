/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function AboutUs() {
  return (
    <TextCard>
      <header>TENTANG PENERBIT</header>
      <div>
        <Title type="danger" level={4} style={{ margin: 0, textAlign: 'center' }}>
          PT. Media Jaya Bersama Boenarta
        </Title>
        <Title level={5} style={{ margin: '0 0 24px', textAlign: 'center', color: '#2c90ff' }}>
          KEMENKUMHAM NOMOR : SK-AHU-0176974.AH.01.01.TAHUN 2019
        </Title>
        <Title level={5} style={{ margin: '0 0 12px' }}>
          Salam Sejahtera Bagi Kita semua
        </Title>
        <Paragraph>
          Di era teknologi globalisasi perkembangan zaman dalam modernisasi elektronik dan Media
          Online, sudah seharusnya Informasi disajikan secara transparan “Objektif , Berimbang dan
          Terpercaya ” , sehingga Media Online dapat lebih berperan dan di kenal sebagai alat
          Informasi masyarakat, dengan cepat dan mudah di akses di media sosial.
        </Paragraph>
        <Title level={5} style={{ margin: '24px 0' }}>
          Pentingnya bagi kami menyajikan dan menyambungkan keluhan masyarakat kepada pihak-pihak
          terkait ,khususnya pemerintah , dan berbagi Informasi secara luas dengan cara menyajikan
          berbagai Informasi dan ruang pengaduan serta konsultasi hukum untuk permasalahan yang
          dihadapi masyarakat melalui Media Online{' '}
          <span style={{ color: '#f84d4f' }}>kabelins.com</span> yang berbadan Hukum
        </Title>
        <Title level={5} style={{ margin: '0 0 12px' }}>
          <span style={{ color: '#f84d4f' }}>kabelins.com </span> Mempunyai Visi & Misi :
        </Title>
        <Paragraph>
          Visi : Menjadi salah satu media PERS yang diperhitungan dan menjadi media PERS yang
          menjadi favorit masyarakat dengan memberikan serta menyampaikan Berita Nasional yang
          “Objektif , Berimbang , dan Terpercaya” Berbasis Teknologi Informasi .
          <br />
          <br />
          Misi : Menyajikan berita secara Profesional dan Akurat, “Dengan Sumber yang dapat
          Dipertanggungjawabkan.“
          <br />
          <br />
          Memberikan akses Informasi , wawasan , dan edukasi kepada masyarakat luas. <br />
          <br />
          Memberikan informasi secara umum secara netral dan indenpendent. Fokus terhadap Informasi
          publik yang berkembang secara aktual.
        </Paragraph>
        <Title level={5} style={{ margin: '0 0 12px' }}>
          Tujuan Berdirinya media online <span style={{ color: '#f84d4f' }}>kabelins.com :</span>
        </Title>
        <Paragraph>
          Menginformasikan dan menyampaikan pesan Public dari semua kalangan dan lapisan masyarakat.{' '}
          <br />
          <br />
          Memberikan sajian Berita Nasional secara Teks, Audio dan Visual. <br />
          <br />
          Menjadikan Informasi lebih Aktual dan meminalisir pertumbuhan berita HOAX dengan sajian
          berita yang akurat.
          <br />
          <br />
          Menjadikan Portal Informasi secara menyeluruh baik dikanca nasional maupun dikanca
          internasional.
          <br />
          <br />
          Memberikan ruang kepada Jurnalistik dan professional untuk menyalurkan karyanya.
          <br />
          <br />
          Memberikan kesempatan bagi masyarakat yang ingin berkembang di dunia Jurnalistik Internet
          / Elektronik.
          <br />
          <br />
          Memudahkan Informasi bagi Publik untuk mendapatkan informasi terkait hak dan kewajibanya
          sebagai rakyat , dan disediakan kolom/ruang surat untuk mengadukan permasalahan kepada
          pihak terkait ataupun konsultasi hukum terkait permasalahan yang sedang dihadapi dengan
          tersajikan di Mobile Informasi masyarakat.
          <br />
          <br />
          Konsep media kabelins.com terlahir saat melihat banyak masyarakat yang haus akan sebuah
          Informasi Publik dan kurangnya akses tempat pengaduan yang dibutuhkan secara Online,
          dengan hadirnya kabelins.com dengan menyambungkan akses merubah ruang lingkup masyarakat
          terhadap media pers saat ini.
          <br />
          <br />
        </Paragraph>
        <Title level={5} style={{ margin: '24px 0' }}>
          Maka dari itu kami, Media Online <span style={{ color: '#f84d4f' }}>kabelins.com</span> ,
          ikut dalam berpartisipasi memberikan Layanan Publik secara “Objektif , Berimbang dan
          Terpercaya”. Didalam menyajikan Informasi Publik yang benar-benar Aktual bagi masyarakat.
        </Title>
      </div>
    </TextCard>
  );
}
