import { css } from '@emotion/react';

export default ({ isMobile, width, height }) => ({
  container: css`
    width: 100%;
    height: 100%;
  `,
  emptyCard: css`
    width: ${width ?? '100%'};
    min-height: ${height ?? (isMobile ? '70px' : '100%')};
    padding-bottom: 50px;

    .ant-empty {
      display: flex;
      align-items: center;
      flex-direction: column;

      &[data-position='top'] {
        padding-top: 50px;
      }

      &[data-position='center'] {
        justify-content: center;
      }
    }

    .ant-empty-image {
      height: 200px;
    }

    .ant-empty-description {
      width: 250px;
    }
  `
});
