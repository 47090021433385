/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import styles from './styles';
import { Button, Space } from 'antd';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import newsAPI from 'APIs/news';
import moment from 'moment';

export default function CheckSuratWeb() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    newsAPI.viewEndUserSuratOfficial().then((res) => {
      setTableData(res?.data);
    });
  }, []);

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Tanggal Laporan',
      dataIndex: 'created_at',
      width: '20%',
      key: 'created_at',
      render: (time) => moment(time).format('DD-MM-YYYY').toString()
    },
    {
      title: 'Action',
      key: 'operation',
      width: '25%',
      render: ({ id, slug, status }) => (
        <Space>
          <Button ghost type="primary" size="medium" onClick={() => navigate(`/berita/${slug}`)}>
            Lihat
          </Button>
          {status === 2 && (
            <Button
              type="primary"
              size="medium"
              onClick={() => navigate(`/pengaturan-akun/tulis-tanggapan/${id}`)}>
              Tanggapi
            </Button>
          )}
        </Space>
      )
    }
  ];

  return (
    <div css={styles.container}>
      <header>Cek Surat</header>
      <section>
        <Table columns={columns} dataSource={tableData} pagination={{ position: ['bottomLeft'] }} />
      </section>
    </div>
  );
}
