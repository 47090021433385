/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';

import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Typography,
  Upload
} from 'antd';
import DynamicImage from 'components/DynamicImage';
import { getPublicImagePath } from 'utils/getPublicImage';
import { useEffect, useState } from 'react';
import { generateMargin, generatePadding, right } from 'styles/utils';

import styles from './styles';
import { doBuyAds, getAdsPrice } from 'APIs/ads';
import { uploadMedia } from 'APIs/media';

const { Text, Paragraph } = Typography;

const { Panel } = Collapse;

const ADS_LIST = {
  Small: {
    label: 'Kecil',
    size: '350 x 280'
  },
  Medium: {
    label: 'Sedang',
    size: '350 x 600'
  },
  Large: {
    label: 'Besar',
    size: '1080 x 250'
  }
};

export default function BuyAdsMobile() {
  const [formData, setFromData] = useState({
    size_id: '',
    sizePrice: 0,
    duration: 1
  });
  const [fields, setFields] = useState({});
  const [adsData, setAdsData] = useState([]);

  const priceFormatter = new Intl.NumberFormat({
    style: 'currency',
    currency: 'IDR'
  });

  function doValidateData(formData) {
    if (!formData?.size_id) {
      notification.error({
        message: 'Anda belum memilih ukuran iklan'
      });
      return false;
    }
    if (!formData?.duration) {
      notification.error({
        message: 'Anda belum memilih durasi iklan'
      });
      return false;
    }
    if (!formData?.payment_path) {
      notification.error({
        message: 'Anda belum mengupload bukti pembayaran'
      });
      return false;
    }
    if (!formData?.path) {
      notification.error({
        message: 'Anda belum mengupload gambar iklan'
      });
      return false;
    }

    return true;
  }

  const onFinish = (values) => {
    const payload = {
      ...values,
      ...formData
    };

    if (doValidateData(payload)) {
      // upload request
      doBuyAds(payload)
        .then((res) => {
          Modal.success({
            title: 'Pembelian iklan kamu sudah masuk',
            content: 'Pembelian iklan sudah berhasil, mohon menunggu konfirmasi admin kami'
          });
          setTimeout(() => {
            window.location.href = '/pengaturan-akun';
          }, 2000);
        })
        .catch(() => {
          Modal.error({
            title: 'Pembelian iklan gagal',
            content: 'Silahkan check kembali data yang kamu masukan dan coba beberapa saat lagi'
          });
        });
    }
  };

  function handleOnChange(value, key) {
    setFromData((prev) => ({ ...prev, [key]: value }));
  }

  function handlePriceSelect(ads) {
    if (ads?.id === formData?.size_id) {
      handleOnChange('', 'size_id');
      handleOnChange(0, 'sizePrice');
    } else {
      handleOnChange(ads?.id, 'size_id');
      handleOnChange(ads?.price, 'sizePrice');
    }
  }

  const handleInputOnChange = (e) =>
    setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 1) {
        return false;
      }
      notification.error({
        message: 'Error',
        description: `Ukuran File lebih besar dari 1 MB`
      });
      return true;
    } else {
      notification.error({
        message: 'Error',
        description: `${file.name} tidak bertipe jpg, jpeg, atau png `
      });

      return true;
    }
  }

  function handlePaymentUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'payment' })
        .then(({ data }) => {
          const imgPath = data?.path;
          handleOnChange(imgPath, 'payment_path');
        })
        .catch((err) => {
          notification.error({
            message: 'Gagal mengupload bukti pembayaran',
            description: 'Mohon periksa kembali dan upload ulang'
          });
        });
    }
  }

  function handleAdsUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'ads' })
        .then(({ data }) => {
          const imgPath = data?.path;
          handleOnChange(imgPath, 'path');
        })
        .catch((err) => {
          notification.error({
            message: 'Gagal mengupload gambar iklan',
            description: 'Mohon periksa kembali dan upload ulang'
          });
        });
    }
  }

  useEffect(() => {
    getAdsPrice().then((res) => {
      setAdsData(res?.data);
    });
  }, []);

  return (
    <>
      <Card css={styles.cardHeader}>
        <TextCard width="100%">
          <header>Beli Iklan</header>
          <p css={styles.secondaryTitle}>
            Pasang iklan kamu di Website Kabelins, dan raih lebih banyak pelanggan untuk usahamu.
          </p>
        </TextCard>
      </Card>

      <div css={styles.swiper}>
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          spaceBetween={20}
          slidesPerView={3}
          loop={true}
          coverflowEffect={{
            rotate: 0,
            stretch: -115,
            depth: 100,
            modifier: 1,
            slideShadows: false
          }}
          pagination={true}
          className="mySwiper"
          modules={[EffectCoverflow]}>
          {adsData?.map((ads) => (
            <SwiperSlide key={ads?.id}>
              <Button
                type={formData?.size_id === ads?.id ? 'primary' : 'default'}
                css={styles.adsCard(formData?.size_id === ads?.id)}
                data-size="medium"
                onClick={() => handlePriceSelect(ads)}>
                <Text
                  css={[styles.text, generatePadding({ type: 'top', value: '54px' })]}
                  data-size="large"
                  data-weight="bold">
                  {ADS_LIST?.[ads?.size]?.label}
                </Text>
                <Divider css={styles.cardDivider} />
                {/* <Paragraph italic css={styles.saveText}>
                  Hemat Rp.366.000
                </Paragraph> */}
                <Paragraph>
                  <Text css={styles.text} data-size="medium" data-weight="bold">
                    Rp. {priceFormatter.format(ads?.price)}
                  </Text>
                  <Text
                    css={styles.text}
                    data-size="regular"
                    data-weight="medium"
                    type="secondary"
                    italic>
                    /bulan
                  </Text>
                </Paragraph>
                <Text
                  type="secondary"
                  italic
                  css={[
                    styles.text,
                    generatePadding({ type: 'initial', value: '0 15px 30px 15px' })
                  ]}
                  data-size="small"
                  data-weight="medium">
                  Rekomendasi ukuran gambar: {ADS_LIST?.[ads?.size]?.size} <br /> atau kelipatannya
                </Text>
              </Button>
            </SwiperSlide>
          ))}
          {/* <SwiperSlide>
            <Button css={styles.adsCard} data-size="medium">
              <Text
                css={[styles.text, generatePadding({ type: 'top', value: '54px' })]}
                data-size="large"
                data-weight="bold">
                Sedang
              </Text>
              <Divider css={styles.cardDivider} />
              <Paragraph italic css={styles.saveText}>
                Hemat Rp.366.000
              </Paragraph>
              <Paragraph>
                <Text css={styles.text} data-size="medium" data-weight="bold">
                  Rp. 166.000
                </Text>
                <Text
                  css={styles.text}
                  data-size="regular"
                  data-weight="medium"
                  type="secondary"
                  italic>
                  /bulan
                </Text>
              </Paragraph>
              <Text
                type="secondary"
                italic
                css={[styles.text, generatePadding({ type: 'initial', value: '0 15px 30px 15px' })]}
                data-size="small"
                data-weight="medium">
                *pembayaran langsung 12 bulan di depan
              </Text>
            </Button>
          </SwiperSlide>
          <SwiperSlide>
            <Button css={styles.adsCard} data-size="medium">
              <Text
                css={[styles.text, generatePadding({ type: 'top', value: '54px' })]}
                data-size="large"
                data-weight="bold">
                Kecil
              </Text>
              <Divider css={styles.cardDivider} />
              <Paragraph italic css={styles.saveText}>
                Hemat Rp.123.000
              </Paragraph>
              <Paragraph>
                <Text css={styles.text} data-size="medium" data-weight="bold">
                  Rp. 186.000
                </Text>
                <Text
                  css={styles.text}
                  data-size="regular"
                  data-weight="medium"
                  type="secondary"
                  italic>
                  /bulan
                </Text>
              </Paragraph>
              <Text
                type="secondary"
                italic
                css={[styles.text, generatePadding({ type: 'initial', value: '0 15px 30px 15px' })]}
                data-size="small"
                data-weight="medium">
                *pembayaran langsung 12 bulan di depan
              </Text>
            </Button>
          </SwiperSlide> */}
        </Swiper>
      </div>
      <Card css={styles.cardSection}>
        <Row gutter={[0, 16]} style={{ marginBottom: '8px', padding: '6px' }}>
          <Col span={24}>
            <div css={styles.sectionTitle}>Durasi Iklan</div>
            <Space direction="horizontal">
              <InputNumber
                min={1}
                defaultValue={1}
                style={{ width: '200px' }}
                onChange={(value) => handleOnChange(value, 'duration')}></InputNumber>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Bulan</span>
            </Space>
          </Col>
          <Col span={24}>
            <div css={styles.sectionTitle}>Total Biaya</div>
            <div css={styles.sectionTitle}>
              Rp. {priceFormatter.format(formData?.duration * formData?.sizePrice)}
            </div>
          </Col>
        </Row>
        <TextCard>
          <section css={styles.section}>
            <p css={styles.sectionTitle}>Cara pembayaran</p>
            <Collapse defaultActiveKey={['1']} expandIconPosition="right" ghost>
              <Panel
                header={
                  <div css={styles.collapseHeader}>
                    <div>
                      <DynamicImage
                        radius={0}
                        src={getPublicImagePath('bri.png')}
                        height={19}
                        width={49}
                      />
                    </div>
                    <p>Transfer Bank BRI</p>
                  </div>
                }
                key="1">
                <div>
                  <p>Lakukan transfer ke nomor rekening berikut:</p>
                  <Paragraph>
                    <Text strong>Nomor Rekening BRI 041801000918303</Text>
                    <br />
                    <Text strong>Atas Nama PT. Media Jaya Bersama Boenindo</Text>
                    <br />
                    <Text strong>Kode Bank 002</Text>
                    <br />
                    <Text strong>Nominal: </Text>Rp.{' '}
                    {priceFormatter.format(formData?.duration * formData?.sizePrice)}
                  </Paragraph>
                  <p>
                    Setelah melakukan transfer, lakukan screenshot atau foto bukti transfer kamu,
                    lalu upload melalui menu <Text strong>UPLOAD BUKTI PEMBAYARAN</Text>. Proses
                    verifikasi transaksi akan memakan waktu hingga 1x24 jam setelah upload
                  </p>
                </div>
              </Panel>

              {/* 
              <Panel
                header={
                  <div css={styles.collapseHeader}>
                    <div>
                      <DynamicImage
                        radius={0}
                        src={getPublicImagePath('mandiri.png')}
                        height={19}
                        width={63}
                      />
                    </div>
                    <p>Transfer Bank Mandiri</p>
                  </div>
                }
                key="2">
                <div>
                  <p>Lakukan transfer ke nomor rekening berikut:</p>
                  <Paragraph>
                    <Text strong>Nomor Rekening Mandiri 100001234</Text>
                    <br />
                    <Text strong>Atas Nama PT KABELINS INDONESIA</Text>
                    <br />
                    <Text strong>Nominal: </Text>Rp.1.000.000
                  </Paragraph>
                  <p>
                    Setelah melakukan transfer, lakukan screenshot atau foto bukti transfer kamu,
                    lalu upload melalui menu <Text strong>UPLOAD BUKTI PEMBAYARAN</Text>. Proses
                    verifikasi transaksi akan memakan waktu hingga 1x24 jam setelah upload
                  </p>
                </div>
              </Panel>

              <Panel
                header={
                  <div css={styles.collapseHeader}>
                    <div>
                      <DynamicImage
                        radius={0}
                        src={getPublicImagePath('bri.png')}
                        height={19}
                        width={65}
                      />
                    </div>
                    <p>Transfer Bank BRI</p>
                  </div>
                }
                key="3">
                <div>
                  <p>Lakukan transfer ke nomor rekening berikut:</p>
                  <Paragraph>
                    <Text strong>Nomor Rekening BRI 100001234</Text>
                    <br />
                    <Text strong>Atas Nama PT KABELINS INDONESIA</Text>
                    <br />
                    <Text strong>Nominal: </Text>Rp.1.000.000
                  </Paragraph>
                  <p>
                    Setelah melakukan transfer, lakukan screenshot atau foto bukti transfer kamu,
                    lalu upload melalui menu <Text strong>UPLOAD BUKTI PEMBAYARAN</Text>. Proses
                    verifikasi transaksi akan memakan waktu hingga 1x24 jam setelah upload
                  </p>
                </div>
              </Panel> */}
            </Collapse>

            <Form layout="vertical" onFinish={onFinish} onChange={handleInputOnChange}>
              <Row
                css={[
                  generateMargin({ type: 'top', value: '40px' }),
                  generateMargin({ type: 'bottom', value: '15px' })
                ]}>
                <Col span={24}>
                  <Paragraph>
                    <span css={styles.sectionTitle}>Upload bukti pembayaran</span>
                    <p css={styles.info}>
                      Ukuran file maksimal 1MB. Format yang didukung adalah .jpg, .png, dan .jpeg.
                    </p>
                  </Paragraph>
                  <Form.Item>
                    <Upload
                      maxCount={1}
                      accept=".jpg,.png,.jpeg"
                      showUploadList={{
                        showRemoveIcon: false
                      }}
                      beforeUpload={handleBeforeUpload}
                      onChange={handlePaymentUploader}>
                      <Button shape="round" ghost type="primary" css={styles.buttonText}>
                        Pilih File
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Paragraph>
                    <span css={styles.sectionTitle}>
                      Upload file iklan <span css={styles.info}>(opsional)</span>
                    </span>
                    <p css={styles.info}>
                      Ukuran file maksimal 1MB. Format yang didukung adalah .jpg, .png, dan .jpeg.
                    </p>
                  </Paragraph>
                  <Form.Item>
                    <Upload
                      maxCount={1}
                      accept=".jpg,.png,.jpeg"
                      showUploadList={{
                        showRemoveIcon: false
                      }}
                      beforeUpload={handleBeforeUpload}
                      onChange={handleAdsUploader}>
                      <Button shape="round" ghost type="primary" css={styles.buttonText}>
                        Pilih File
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph>
                    <span css={styles.sectionTitle}>Deskripsi iklan</span>
                  </Paragraph>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Mohon masukan deskripsi singkat mengenai iklan anda'
                      }
                    ]}>
                    <Input.TextArea
                      maxLength={255}
                      showCount
                      rows={10}
                      placeholder="Deskripsi singkat mengenai iklan"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ marginTop: '12px' }}>
                <Button
                  block
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  css={styles.buttonText}>
                  Kirim Permintaan
                </Button>
              </Form.Item>
            </Form>
          </section>
        </TextCard>
      </Card>
    </>
  );
}
