import { css } from '@emotion/react';

export const generateMargin = ({ type, value }) => {
  const margin = {
    left: css`
      margin-left: ${value};
    `,
    right: css`
      margin-right: ${value};
    `,
    top: css`
      margin-top: ${value};
    `,
    bottom: css`
      margin-bottom: ${value};
    `,
    horizontal: css`
      margin-right: ${value};
      margin-left: ${value};
    `,
    vertical: css`
      margin-top: ${value};
      margin-bottom: ${value};
    `,
    initial: css`
      margin: ${value};
    `
  };

  return margin[type];
};

export const generatePadding = ({ type, value }) => {
  const padding = {
    left: css`
      padding-left: ${value};
    `,
    right: css`
      padding-right: ${value};
    `,
    top: css`
      padding-top: ${value};
    `,
    bottom: css`
      padding-bottom: ${value};
    `,
    horizontal: css`
      padding-right: ${value};
      padding-left: ${value};
    `,
    vertical: css`
      padding-top: ${value};
      padding-bottom: ${value};
    `,
    initial: css`
      padding: ${value};
    `
  };

  return padding[type];
};

export const spaceBetween = css`
  display: flex;
  justify-content: space-between;
`;

export const inline = css`
  display: flex;
`;

export const center = css`
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
`;

export const verticalCenter = css`
  display: flex;
  align-items: center;
`;

export const right = css`
  display: flex;
  justify-content: flex-end;
  text-align: right;
`;
