/** @jsxImportSource @emotion/react */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Skeleton, Avatar, Card as ANTDCard, notification, Space } from 'antd';
import {
  CommentOutlined,
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
  RightOutlined
} from '@ant-design/icons';
import parse from 'html-react-parser';

import Card from 'components/Card';
import CategoryBadge from 'components/CategoryBadge';
import DynamicImage from 'components/DynamicImage';
import CarouselNewsThumbnail from 'components/CarouselNewsThumbnail';

import { removeHTMLTagsFromString } from 'utils/string';
import useGlobal from 'contexts/global';
import newsAPI from 'APIs/news';
import useAuth from 'contexts/auth';

import { generateMargin } from 'styles/utils';
import { getPublicImagePath } from 'utils/getPublicImage';

import getStyles from './styles';
import { formatNewsBanner, getBannersThumbnail } from 'utils/getBanners';

export default {
  WithActionIcon,
  WithDetails
};

const iconActionInitialState = {
  likes: 0,
  dislikes: 0,
  action: ''
};

function ActionIcon({
  likes = 0,
  dislikes = 0,
  commentSize = 0,
  position = '',
  styleTypes = 'withActionIcon',
  newsId
}) {
  const { authenticated } = useAuth();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile })[styleTypes];

  const [iconAction, setState] = useState(iconActionInitialState);

  const handleLike = () => {
    if (!authenticated) {
      notification.info({
        message: 'Anda harus login terlebih dahulu untuk melakukan aksi tersebut'
      });
      return;
    }
    if (styleTypes !== 'withDetails') return;
    if (iconAction.action === 'liked') return;

    setState((prev) => ({
      ...prev,
      likes: Number(prev.likes) + 1,
      dislikes,
      action: 'liked'
    }));

    newsAPI.likeOrDislikeNews(newsId, 1);
  };

  const handleDislike = () => {
    if (!authenticated) {
      notification.info({
        message: 'Anda harus login terlebih dahulu untuk melakukan aksi tersebut'
      });
      return;
    }
    if (styleTypes !== 'withDetails') return;
    if (iconAction.action === 'disliked') return;

    setState((prev) => ({
      ...prev,
      likes,
      dislikes: Number(prev.dislikes) + 1,
      action: 'disliked'
    }));

    newsAPI.likeOrDislikeNews(newsId, -1);
  };

  const handleCommentIconClicked = () => {
    if (styleTypes !== 'withDetails') return;

    const commetSectionOffset = document
      .getElementById('detailsComment')
      .getBoundingClientRect().top;

    window.scrollTo({ top: commetSectionOffset, behavior: 'smooth' });
  };

  useEffect(() => {
    setState({
      likes,
      dislikes
    });
  }, [likes, dislikes]);

  return (
    <div css={styles.sosmedInfo} data-position={position}>
      <div onClick={handleLike} data-state={iconAction.action === 'liked' ? 'liked' : ''}>
        {iconAction.action === 'liked' ? <LikeFilled /> : <LikeOutlined />}
        {iconAction.likes || 0}
      </div>
      <div onClick={handleDislike} data-state={iconAction.action === 'disliked' ? 'disliked' : ''}>
        {iconAction.action === 'disliked' ? <DislikeFilled /> : <DislikeOutlined />}
        {iconAction.dislikes || 0}
      </div>
      {/* <div onClick={handleCommentIconClicked}>
        <CommentOutlined />
        {commentSize}
      </div> */}
    </div>
  );
}

function WithActionIcon({ data, isLoading, onClick }) {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile }).withActionIcon;

  return (
    <Card
      style={styles.card}
      onClick={onClick}
      cover={
        <DynamicImage
          src={getBannersThumbnail(data?.banner_path)}
          width={isMobile ? 223 : 330}
          height={isMobile ? 125 : 212}
          alt={data?.title}
        />
      }>
      <div css={generateMargin({ type: 'initial', value: isMobile ? '5px' : '14px' })}>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: isMobile ? 1 : 3 }} />
        ) : (
          <>
            <div css={generateMargin({ type: 'bottom', value: isMobile ? '8px' : '15px' })}>
              <Space wrap>
                <CategoryBadge color={data?.category?.color} title={data?.category?.name} />
              </Space>
            </div>
            <div css={styles.cardTitle}>{data?.title}</div>
            <div css={styles.cardDescription}>{removeHTMLTagsFromString(data?.content)}</div>
            <ActionIcon
              position="bottom"
              styleTypes="withActionIcon"
              likes={data?.total_like}
              dislikes={data?.total_dislike}
              commentSize={data?.comments?.length}
              newsId={data?.id}
            />
          </>
        )}
      </div>
    </Card>
  );
}

const defaultWithDetailsConfig = {
  withReadMoreButton: true,
  withRawContent: true,
  withCarousel: false
};

function WithDetails({ data, isLoading, onClick, config = defaultWithDetailsConfig }) {
  const configs = { ...defaultWithDetailsConfig, ...config };
  const avatarInitial = data?.created_by?.name.charAt(0) ?? 'K';
  const avatarPicture = data?.created_by?.profile_picture;

  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile }).withDetails;

  const shareToSosmed = (type) => {
    const encodedLink = new URL(location.href).toString();
    const shareWording = `${encodedLink}%0a%0aBaca berita lainnya di website https://kabelins.com`;
    const linkOnlyWording = encodedLink;
    const sosmedLink = {
      whatsapp: `https://api.whatsapp.com/send/?phone&text=${shareWording}`,
      facebook: `http://www.facebook.com/sharer.php?u=${linkOnlyWording}`,
      twitter: `https://twitter.com/intent/tweet?text=${shareWording}`,
      telegram: `https://t.me/share/url?url=${shareWording}`
    };

    window.open(sosmedLink[type], '_blank');
  };

  const renderThumbnail = configs.withCarousel ? (
    <CarouselNewsThumbnail
      data={formatNewsBanner(data?.banner_path)}
      width="100%"
      height={isMobile ? 223 : 416}
    />
  ) : (
    <DynamicImage
      src={getBannersThumbnail(data?.banner_path)}
      alt={data?.title}
      height={isMobile ? 223 : 416}
    />
  );

  const renderRawContent = configs?.withRawContent
    ? parse(data?.content || '')
    : removeHTMLTagsFromString(data?.content);

  const renderReadMoreButton = configs?.withReadMoreButton && (
    <Button type="link" css={styles.button}>
      Baca selengkapnya
      <RightOutlined />
    </Button>
  );

  return (
    <Card
      style={styles.card}
      onClick={() => onClick && onClick(data?.slug)}
      cover={
        <>
          <Space wrap>
            <CategoryBadge
              color={data?.category?.color}
              title={data?.category?.name}
              style={styles.category}
            />
            {/* {data?.sub_categories?.[0] && (
              <CategoryBadge
                key={data?.sub_categories?.[0]?.id}
                color={data?.sub_categories?.[0]?.color}
                title={data?.sub_categories?.[0]?.name}
              />
            )} */}
            {data?.sub_categories?.map((subCtg) => (
              <CategoryBadge key={subCtg?.id} color={subCtg?.color} title={subCtg?.name} />
            ))}
          </Space>
          <div css={styles.title}>{data?.title}</div>
          <ANTDCard.Meta
            avatar={
              <Avatar css={styles.avatar} size={isMobile ? 25 : 35} src={avatarPicture}>
                {avatarInitial}
              </Avatar>
            }
            description={
              <div css={styles.profileContainer}>
                <p>
                  Oleh{' '}
                  <span css={styles.writerName}>
                    {data?.created_by?.name ?? 'Kabelins Super Admin'}
                  </span>
                </p>
                <div>
                  Diterbitkan pada:{' '}
                  <span css={styles.publishedDate}>
                    {moment(data?.created_at).format('dddd, DD MMMM YYYY - hh:mm')}
                  </span>
                </div>
              </div>
            }
          />
          <div css={styles.shareButtonContainer}>
            <div css={styles.shareButton}>
              {!isMobile && <>BAGIKAN :</>}
              <img
                src={getPublicImagePath('facebook.png')}
                width={isMobile ? 23 : 35}
                height={isMobile ? 23 : 35}
                alt="facebook icon"
                onClick={() => shareToSosmed('facebook')}
              />
              <img
                src={getPublicImagePath('whatsapp.png')}
                width={isMobile ? 23 : 35}
                height={isMobile ? 23 : 35}
                alt="whatsapp icon"
                onClick={() => shareToSosmed('whatsapp')}
              />
              <img
                src={getPublicImagePath('twitter.webp')}
                width={isMobile ? 23 : 35}
                height={isMobile ? 23 : 35}
                alt="twitter icon"
                onClick={() => shareToSosmed('twitter')}
              />
              <img
                src={getPublicImagePath('telegram.webp')}
                width={isMobile ? 23 : 35}
                height={isMobile ? 23 : 35}
                alt="telegram icon"
                onClick={() => shareToSosmed('telegram')}
              />
            </div>
            <ActionIcon
              styleTypes="withDetails"
              likes={data?.total_like}
              dislikes={data?.total_dislike}
              commentSize={data?.comments?.length}
              newsId={data?.id}
            />
          </div>
          {renderThumbnail}
        </>
      }>
      <div css={configs?.withRawContent ? styles.content : styles.limitedContent}>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: configs?.withRawContent ? 15 : 1 }} />
        ) : (
          renderRawContent
        )}
      </div>
      {renderReadMoreButton}
    </Card>
  );
}
