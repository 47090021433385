/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';

import Card from 'components/Card';
import { Card as ANTDCard } from 'antd';
import CategoryBadge from 'components/CategoryBadge';
import HeaderTitle from 'components/HeaderTitle';
import DynamicImage from 'components/DynamicImage';
import HorizonatalCard from 'components/NewsCard/HorizontalCard';
import Empty from 'components/Empty';

import newsAPI from 'APIs/news';
import { removeHTMLTagsFromString } from 'utils/string';
import { checkIsMobile } from 'utils/isMobile';

import { generateMargin } from 'styles/utils';
import styles, { tabStyle } from './styles';
import { categories } from './data';
import { getBannersThumbnail } from 'utils/getBanners';

const isMobile = checkIsMobile();

const initialState = {
  isLoading: false,
  error: null,
  data: Array.from({ length: 3 }),
  categories: categories,
  activeTab: categories[0]['id']
};

function Tabs({ title, active = false, setActiveTab, tabId, color }) {
  const styles = tabStyle({ active, isMobile });

  return (
    <CategoryBadge
      onClick={() => setActiveTab(tabId)}
      style={styles.container}
      color={color}
      title={title}
    />
  );
}

function NewsCard({ isLoading, data = [] }) {
  const navigate = useNavigate();
  const haveNoDataAndIsLoading = data.length === 0 && isLoading;
  const haveNoDataAndNotLoading = data.length === 0 && !isLoading;

  const redirectToDetail = (slug) => {
    navigate(isLoading ? '/' : `/berita/${slug}`);
    window.scrollTo({ top: 0 });
  };

  if (haveNoDataAndIsLoading) data = initialState.data;
  if (haveNoDataAndNotLoading)
    return <Empty height={isMobile ? '294px' : '465px'} width={isMobile ? '100%' : '656px'} />;

  if (isMobile)
    return (
      <HorizonatalCard.MobileCard isLoading={isLoading} data={data} onClick={redirectToDetail} />
    );

  return (
    <Card style={styles.card}>
      {data?.map((news, idx) => (
        <ANTDCard.Meta
          key={idx}
          onClick={() => redirectToDetail(news?.slug)}
          avatar={
            <DynamicImage
              src={getBannersThumbnail(news?.banner_path)}
              alt={news?.title}
              width={176}
              height={123}
            />
          }
          description={
            <>
              {isLoading ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <>
                  <CategoryBadge color={news?.category?.color} title={news?.category?.name} />
                  {data?.sub_categories?.[0] && (
                    <CategoryBadge
                      key={data?.sub_categories?.[0]?.id}
                      color={data?.sub_categories?.[0]?.color}
                      title={data?.sub_categories?.[0]?.name}
                    />
                  )}
                  <div css={styles.cardTitle}>{news?.title}</div>
                  <div css={styles.cardDescription}>{removeHTMLTagsFromString(news?.content)}</div>
                </>
              )}
            </>
          }
        />
      ))}
    </Card>
  );
}

export default function TopPicks() {
  const [{ categories, isLoading, data, activeTab }, setState] = useState(initialState);

  const setActiveTab = useCallback((key) => {
    setState((prev) => ({ ...prev, activeTab: key }));
  }, []);

  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await newsAPI.getTopPic(activeTab);

    setState((prev) => ({ ...prev, data, error, isLoading: false }));
  }, [activeTab]);

  return (
    <div css={styles.container}>
      <div css={generateMargin({ type: 'bottom', value: '10px' })}>
        <HeaderTitle primaryText="3 Berita " secondaryText="Pilihan" />
        <div css={styles.cardHeaderTabs}>
          {categories.map(({ name, id, color }) => (
            <Tabs
              key={id}
              tabId={id}
              title={name}
              color={color}
              active={id === activeTab}
              setActiveTab={setActiveTab}
            />
          ))}
        </div>
      </div>
      <NewsCard isLoading={isLoading} data={data} />
    </div>
  );
}
