import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRefreshToken } from 'utils/auth';

export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getRefreshToken()) {
      navigate('/login');
    }
  }, []);

  return children;
}
