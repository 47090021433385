/** @jsxImportSource @emotion/react */

import { Skeleton } from 'antd';
import moment from 'moment';

import Card from 'components/Card';
import { Card as ANTDCard } from 'antd';
import CategoryBadge from 'components/CategoryBadge';
import DynamicImage from 'components/DynamicImage';

import { removeHTMLTagsFromString } from 'utils/string';

import styles from './styles';
import { getBannersThumbnail } from 'utils/getBanners';

export default {
  MobileCard
};

const initialConfig = {
  displayDate: false,
  displayCategory: true
};

function MobileCard({ data, isLoading, onClick, config = initialConfig, children }) {
  const configs = { ...initialConfig, ...config };

  return (
    <Card style={styles.card}>
      {data?.map((news, idx) => (
        <ANTDCard.Meta
          key={idx}
          onClick={() => onClick(news?.slug)}
          avatar={
            <DynamicImage
              src={getBannersThumbnail(news?.banner_path)}
              alt={news?.title}
              width={110}
              height={78}
            />
          }
          description={
            <>
              {isLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <>
                  {configs?.displayCategory && (
                    <CategoryBadge color={news?.category?.color} title={news?.category?.name} />
                  )}
                  <div css={styles.cardTitle}>{news?.title}</div>
                  <div css={styles.cardDescription}>
                    {configs?.displayDate
                      ? moment(news?.created_at).format('MMMM DD, YYYY')
                      : removeHTMLTagsFromString(news?.content)}
                  </div>
                </>
              )}
            </>
          }
        />
      ))}
      {children}
    </Card>
  );
}
