import Cookies from 'js-cookie';

export function setAccessToken(token) {
  Cookies.set('jwt_token', token, {
    secure: true,
    sameSite: 'strict'
  });
}

export function getAccessToken() {
  return Cookies.get('jwt_token');
}

export function removeAccessToken() {
  Cookies.remove('jwt_token');
}

export function setRefreshToken(token) {
  Cookies.set('refresh_token', token, {
    secure: true,
    sameSite: 'strict',
    expires: 7
  });
}

export function getRefreshToken() {
  return Cookies.get('refresh_token');
}

export function removeRefreshToken() {
  Cookies.remove('refresh_token');
}

export function setUserData(data) {
  localStorage.setItem(
    'user',
    JSON.stringify({
      name: data?.name,
      phoneNumber: data?.phone,
      email: data?.email,
      picture: data?.ktp_picture
    })
  );
}

export function removeUserData() {
  localStorage.removeItem('user');
  localStorage.removeItem('currentUser');
}
