/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';
import { Col, Divider, Modal, Row, Space, Table, Typography } from 'antd';
import { checkIsMobile } from 'utils/isMobile';
import { useEffect, useState } from 'react';
import { getStructures } from 'APIs/structure';

const { Title, Paragraph } = Typography;

export default function StrukturRedaksi() {
  const isMobile = checkIsMobile();
  const [mainTeam, setMainTeam] = useState([]);
  const [representativeTeam, setRepresentativeTeam] = useState([]);

  useEffect(async () => {
    getStructures()
      .then((res) => {
        const { data } = res;
        setMainTeam(data?.slice()?.filter((teamData) => teamData?.is_main_team));
        setRepresentativeTeam(data?.slice()?.filter((teamData) => !teamData?.is_main_team));
      })
      .catch(() => {
        Modal.error({
          title: 'Gagal Mendapatkan Data',
          content: 'Gagal mendapatkan data konsultan, silahkan coba refresh halaman'
        });
      });
  }, []);

  return (
    <TextCard>
      <header>Redaksi</header>
      <Title level={5}>
        Terbit berdasarkan Undang-undang PERS Nomor 40 Tahun 1999 Badan Hukum Perusahaan Media:
      </Title>
      <Paragraph strong style={{ lineHeight: '20px' }}>
        <div>
          <strong> PT. MEDIA JAYA BERSAMA BOENINDO</strong>
        </div>
        <div>
          <strong>NPWP: 43.897.448.7-360.000</strong>
        </div>
        <div>
          <strong>NOMOR : AHU-0176974.AH.01.11.Tahun 2021</strong>
        </div>
        <div>
          <strong>NIB: 1410210048468</strong>
        </div>
        <br />
        <br />
        Situs berita online <span style={{ color: '#f84d4f' }}>kabelins.com</span> aktif online
        berdiri sejak Oktober 2021, dan media ini didirikan untuk menjawab kebutuhan informasi
        melalui dunia cyber yang akuntabel profesional dengan penuh manfaat bagi masyarakat.{' '}
        <span style={{ color: '#f84d4f' }}> kabelins.com </span> dinaungi badan Hukum Perusahaan
        Media yakni PT. Media Jaya Bersama Boenindo. Melalui berbagai rubrik/konten yang
        ditampilkan, <span style={{ color: '#f84d4f' }}> kabelins.com </span>
        senantiasa melakukan pembenahan untuk memenuhi kebutuhan pembaca sesuai kekiniannya.
        <span style={{ color: '#f84d4f' }}> kabelins.com </span> dalam penyajiannya mengemban fungsi
        informasi, pendidikan, hiburan dan kontrol sosial.
        <br />
        <br />
        Dan Situs berita <span style={{ color: '#f84d4f' }}>www.kabelins.com</span> dalam
        menjalankan tugas jurnalistik sehari-hari terikat oleh undang-undang dan kode etik
        Jurnalistik Nomor 40 Tahun 1999.
      </Paragraph>
      <Title level={4}>Susunan Personalia Redaksi</Title>
      <Paragraph strong>
        <Row gutter={[8, 8]}>
          {mainTeam?.map((teamData) => (
            <Col span={24} key={teamData?.id}>
              <Row gutter={[8, 8]}>
                <Col span={isMobile && 24}>
                  <div style={{ fontSize: '14px' }}>{teamData?.position} :</div>
                </Col>
                <Col span={isMobile && 24}>
                  <div style={{ fontSize: '14px', marginBottom: isMobile ? '16px' : '0' }}>
                    {teamData?.name}
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Paragraph>

      <Title level={3}>Perwakilan</Title>
      <Divider style={{ marginTop: 0 }}></Divider>
      <Paragraph strong>
        <Row gutter={[8, 8]}>
          {representativeTeam?.map((teamData) => (
            <Col span={24} key={teamData?.id}>
              <Row gutter={[8, 8]}>
                <Col span={isMobile && 24}>
                  <div style={{ fontSize: '14px' }}>{teamData?.position} :</div>
                </Col>
                <Col span={isMobile && 24}>
                  <div style={{ fontSize: '14px', marginBottom: isMobile ? '16px' : '0' }}>
                    {teamData?.name}
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
        <div style={{ margin: '24px 0 10px' }}>Catatan :</div>
        <ul>
          <li style={{ margin: ' 0 0 6px 20px' }}>
            Redaksi menerima karya tulis dan atau konten dari pembaca.
          </li>
          <li style={{ margin: ' 0 0 6px 20px' }}>
            Redaksi berwenang mengubah isi tanpa mengurangi substansi bagi setiap tulisan/konten
            yang dimuat.
          </li>
          <li style={{ margin: ' 0 0 6px 20px' }}>
            Wartawan <span style={{ color: '#f84d4f' }}>kabelins.com</span> dibekali id card pers
            dan surat tugas.
          </li>
          <li style={{ margin: ' 0 0 6px 20px' }}>
            Wartawan <span style={{ color: '#f84d4f' }}>kabelins.com</span> tidak diperkenankan
            menerima pemberian uang atau bentuk lainnya sebagai imbalan dalam tugas peliputan.
          </li>
        </ul>
      </Paragraph>

      <Title level={3}>Alamat</Title>
      <Divider style={{ marginTop: 0 }}></Divider>
      <Paragraph strong style={{ lineHeight: '20px' }}>
        <span style={{ color: '#f84d4f' }}> PT. MEDIA JAYA BERSAMA BOENINDO </span>
        <br />
        Jalan.Jelambar Selatan II No.B7 Rt/Rw : 002/002
        <br />
        Kelurahan : Jelambar Baru - Kecamatan : Grogal Pertamburan .<br />
        Kota : Jakarta Barat - Provinsi DKI Jakarta Raya – 11460 Indonesia
      </Paragraph>
    </TextCard>
  );
}
