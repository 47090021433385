/** @jsxImportSource @emotion/react */

import Card from 'components/Card';
import DynamicImage from 'components/DynamicImage';
import { useCallback, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Button, Skeleton, Card as ANTDCard, Modal } from 'antd';

import useGlobal from 'contexts/global';

import { section } from 'styles/layout';
import getStyles from './styles';
import { getConsultants } from 'APIs/consultant';

export default function LawConsultation() {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const [consultants, setConsultants] = useState([]);

  useEffect(async () => {
    getConsultants()
      .then((res) => {
        const { data } = res;
        setConsultants(data);
      })
      .catch(() => {
        Modal.error({
          title: 'Gagal Mendapatkan Data',
          content: 'Gagal mendapatkan data konsultan, silahkan coba refresh halaman'
        });
      });
  }, []);

  const handleContact = useCallback((phone) => {
    window.open(`https://wa.me/${phone}`, '_blank', 'noopener noreferrer');
  }, []);

  return (
    <div css={section}>
      <Card style={styles.headerCard}>
        <p css={styles.headerTitle}>Konsultasi Hukum</p>
        <p css={styles.headerDescription}>
          Perlu bantuan perihal hukum? Silahkan hubungi salah satu kontak dibawah ini untuk
          mendapatkan bantuan.
        </p>
      </Card>
      <div css={styles.content}>
        {consultants.map((item) => (
          <Card key={item.id} style={styles.card}>
            <ANTDCard.Meta
              avatar={
                <DynamicImage
                  width={isMobile ? 112 : 178}
                  height={isMobile ? 168 : 267}
                  alt={item.name}
                  src={item.profile_path}
                />
              }
              description={
                <>
                  <p css={styles.name}>{item?.name}</p>
                  <p css={styles.address}>{item?.address}</p>
                  <p css={styles.consultantContent}>{parse(item?.description || '')}</p>
                  <Button
                    type="primary"
                    shape="round"
                    css={styles.button}
                    onClick={() => handleContact(item?.phone)}>
                    Hubungi Sekarang
                  </Button>
                </>
              }
            />
          </Card>
        ))}
      </div>
    </div>
  );
}
