import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { mainMenu } from 'constants/navbar';
import newsAPI from 'APIs/news';

const useTabList = () => {
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState({
    main: mainMenu,
    list: []
  });

  useEffect(async () => {
    const { data = [] } = await newsAPI.getNewsCategories();

    if (data?.length) {
      const tempList = [];
      const lastArr = [];
      const tempMain = menuList.main;

      data?.forEach((item) => {
        if (item.slug === 'kotak-surat') {
          return;
        }
        if (item.slug === 'surat-pengirim' || item.slug === 'tanggapan') {
          const idx = mainMenu.findIndex((nav) => nav.dataIndex === item.slug);
          tempMain[idx]['isActive'] = item.is_actives;
        } else {
          if (item.slug === 'gallery' || item.slug === 'konsultasi-hukum') {
            lastArr.push(item);
          } else {
            tempList.push(item);
          }
        }
      });

      setMenuList({
        main: mainMenu,
        list: [...tempList, ...lastArr]
      });
    }
  }, []);

  const redirectPage = (name) => {
    navigate(name);
  };

  return {
    menuList,
    redirectPage,
    navigate
  };
};

export default useTabList;
