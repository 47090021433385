/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Button, Col, Input, message, Modal, notification, Row, Upload } from 'antd';
import { Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import styles from './styles';
import { useEffect, useState } from 'react';
import { uploadMedia } from 'APIs/media';
import { addGalleryUser, deleteGalleryUser, getGalleryUser } from 'APIs/gallery';
import DynamicImage from 'components/DynamicImage';

export default function CheckOpinionWeb() {
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [uploadData, setUploadData] = useState({
    name: '',
    path: ''
  });

  function fetchData() {
    getGalleryUser()
      .then((res) => {
        setTableData(res?.data);
      })
      .catch(() => {
        message.error('Gagal mendapatkan data dari server silahkan refresh halaman anda');
      });
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 1) {
        return false;
      }
      message.error('Ukuran File lebih besar dari 1 MB');
      return true;
    } else {
      message.error(`${file.name} tidak bertipe jpg, jpeg, atau png `);
      return true;
    }
  }

  function handleImageUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'gallery' })
        .then(({ data }) => {
          const imgPath = data?.path;
          handleOnChange(imgPath, 'path');
        })
        .catch(() => {
          notification.error({
            message: 'Gallery gagal upload',
            description: 'Mohon periksa kembali dan upload ulang gambarnya'
          });
        });
    }
  }

  function doValidation() {
    if (!uploadData?.name) {
      message.error('Judul harus diisi');
      return false;
    }
    if (!uploadData?.path) {
      message.error('Gambar harus diupload terlebih dahulu');
      return false;
    }
    return true;
  }

  function handleOnChange(value, key) {
    setUploadData((prev) => ({ ...prev, [key]: value }));
  }

  function handleDelete(id) {
    deleteGalleryUser(id)
      .then(() => {
        message.success('Berhasil menghapus gallery');
        fetchData();
      })
      .catch(() => {
        message.error('Gagal mengahapus gallery, silahkan coba beberapa saat lagi');
      });
  }

  function handleSubmit() {
    if (doValidation()) {
      addGalleryUser(uploadData)
        .then(() => {
          message.success('Berhasil mengupload gallery');
          setShowModal(false);
          fetchData();
        })
        .catch(() => {
          message.error('Gagal mengupload gallery, silahkan coba kembali beberapa saat');
        });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'name',
      width: 340,
      key: 'name'
    },
    {
      title: 'Link',
      dataIndex: 'path',
      key: 'id',
      align: 'center',
      render: (path) => (
        <a href={path} target="_blank" rel="noreferrer">
          Link
        </a>
      )
    },
    {
      title: 'Terverifikasi',
      align: 'center',
      width: 100,
      render: (data) => (
        <>{data?.verifier_id ? <CheckOutlined css={styles.checklistStyle} /> : '-'}</>
      )
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      render: (record) => (
        <>
          <Button type="primary" danger onClick={() => handleDelete(record?.id)}>
            Hapus
          </Button>
        </>
      )
    }
  ];

  return (
    <div css={styles.container}>
      <header>Cek Gallery</header>
      <section>
        <Row justify="end" style={{ margin: '16px 0' }}>
          <Col>
            <Button type="primary" onClick={() => setShowModal(true)}>
              Tambah Gallery
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={tableData} pagination={{ position: ['bottomLeft'] }} />
      </section>
      <Modal
        className="styModal"
        title="Upload Gallery"
        visible={showModal}
        centered
        maskClosable
        mask
        footer={null}
        onCancel={() => {
          setShowModal(false);
        }}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div>Judul</div>
            <Input
              placeholder="Masukan judul gambar"
              onChange={(e) => handleOnChange(e?.target?.value, 'name')}
            />
          </Col>
          {uploadData?.path && (
            <Col span={24} style={{ textAlign: 'center' }}>
              <DynamicImage src={uploadData?.path} width={342}></DynamicImage>
            </Col>
          )}
          <Col span={24} style={{ marginTop: '16px' }} css={styles.styUpload}>
            <div className="upload-notes">
              Note: format yang diperbolehkan adalah jpg,jpeg,png dan maximum 1 Mb
            </div>
            <Upload
              maxCount={1}
              listType="picture"
              accept=".jpg,.png,.jpeg"
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleImageUploader}>
              <Button block ghost type="primary">
                Upload File
              </Button>
            </Upload>
          </Col>
          <Col span={24}>
            <Button block type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
