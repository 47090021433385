/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Button, Carousel, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import useGlobal from 'contexts/global';
import newsAPI from 'APIs/news';

import getStyles from './styles';
import { useEffect, useState } from 'react';
import DynamicImage from 'components/DynamicImage';
import { STATUS_NEWS } from 'constants/status';

export default function LetterDetail() {
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const { id } = useParams();
  const [newsData, setNewsData] = useState({});
  const [banners, setBanners] = useState([]);

  function fetchData() {
    newsAPI.viewSuratPengirimDetail(id).then((res) => {
      setNewsData(res?.data);
      setBanners(JSON.parse(res?.data?.banner_path));
    });
  }

  function verifySurat(status) {
    newsAPI.verifyEditSuratPengirim({ id: id, status: status }).then(() => {
      fetchData();
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div css={styles.container}>
      <header>
        <ArrowLeftOutlined
          onClick={() => navigate(`/pengaturan-akun/cek-surat-saya`)}
          css={styles.arrowBack}
        />
        Cek Perubahan
      </header>
      <section>
        {/* <Tabs onChange={callback} type="card">
          <TabPane tab="Sebelum" key="1">
           
          </TabPane>
          <TabPane tab="Sesudah" key="2" css={generateMargin({ type: 'left', value: '0px' })}>
            <div css={styles.opinionTextContainer}>
              <div>
                <p css={styles.labelText}>Judul</p>
                <p>Lorem ipsum dolor sit amet</p>
              </div>
              <div>
                <p css={styles.labelText}>Kategori</p>
                <p>Kategori 1</p>
              </div>
              <div>
                <p css={styles.labelText}>Isi Berita</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean luctus sem dui, a
                  condimentum odio molestie eget. Suspendisse facilisis condimentum enim, id
                  fermentum eros placerat non. Nulla elit orci, fringilla ac feugiat nec, imperdiet
                  vel ante. Pellentesque faucibus ac leo sit amet convallis. Duis at sagittis nisl.
                  Etiam efficitur semper diam. Sed ac consequat odio. Etiam ultrices metus ut purus
                  posuere, id porttitor risus tincidunt. Mauris ultrices facilisis dictum. Aliquam
                  erat volutpat. In lobortis sodales tempus. Vivamus id lobortis dolor, ut elementum
                  neque. Vestibulum id mollis sapien, sed tempus ipsum. Cras aliquam porta velit,
                  vel elementum augue luctus sit amet. Integer posuere lobortis nunc, sed lobortis
                  leo maximus et. Aliquam erat volutpat. Proin sed sem elit. In non est vel dolor
                  posuere congue id vitae libero. Phasellus eget rhoncus nisi. Suspendisse molestie
                  quis lectus eu faucibus. Orci varius natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus.
                </p>
              </div>
            </div>
          </TabPane>
        </Tabs> */}

        <div css={styles.opinionTextContainer}>
          <div>
            <p css={styles.labelText}>Status Surat Anda</p>
            <p>
              <div style={{ color: STATUS_NEWS?.[newsData?.status]?.color }}>
                {STATUS_NEWS?.[newsData?.status]?.label}
              </div>
            </p>
          </div>
          <div>
            <p css={styles.labelText}>Judul</p>
            <p>{newsData?.title}</p>
          </div>
          <div>
            <p css={styles.labelText}>Ditujukan ke pihak</p>
            <p>{newsData?.for_user?.name}</p>
          </div>
          <div>
            <p css={styles.labelText}>Gambar Bukti</p>
            <Carousel autoplay dotPosition="bottom" style={{ width: 653 }}>
              {banners.map((img, index) => (
                <div key={index}>
                  <DynamicImage
                    width={isMobile ? 319 : 653}
                    height={isMobile ? 223 : 416}
                    src={img}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div>
            <p css={styles.labelText}>Isi Surat</p>
            <p dangerouslySetInnerHTML={{ __html: newsData?.content }}></p>
          </div>
        </div>
        {newsData?.status == 1 && (
          <Space align="center" css={styles.buttonContainer}>
            <Button
              htmlType="submit"
              shape="round"
              ghost
              type="primary"
              onClick={() => verifySurat(3)}>
              Tidak Setuju
            </Button>
            <Button type="primary" htmlType="submit" shape="round" onClick={() => verifySurat(2)}>
              Setujui Perubahan
            </Button>
          </Space>
        )}
        {/* </div> */}
      </section>
    </div>
  );
}
