/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Button, Tabs } from 'antd';
import { generateMargin } from 'styles/utils';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import useGlobal from 'contexts/global';

import getStyles from './styles';

const { TabPane } = Tabs;

export default function OpinionDetail() {
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  return (
    <div css={styles.container}>
      <header>
        <ArrowLeftOutlined
          onClick={() => navigate(`/pengaturan-akun/cek-opini`)}
          css={styles.arrowBack}
        />
        Detail Opini
      </header>
      <section>
        <Tabs type="card">
          <TabPane tab="Sebelum" key="1">
            <div css={styles.opinionTextContainer}>
              <div>
                <p css={styles.labelText}>Judul</p>
                <p>Lorem ipsum dolor sit amet</p>
              </div>
              <div>
                <p css={styles.labelText}>Kategori</p>
                <p>Kategori 1</p>
              </div>
              <div>
                <p css={styles.labelText}>Isi Berita</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean luctus sem dui, a
                  condimentum odio molestie eget. Suspendisse facilisis condimentum enim, id
                  fermentum eros placerat non. Nulla elit orci, fringilla ac feugiat nec, imperdiet
                  vel ante. Pellentesque faucibus ac leo sit amet convallis. Duis at sagittis nisl.
                  Etiam efficitur semper diam. Sed ac consequat odio. Etiam ultrices metus ut purus
                  posuere, id porttitor risus tincidunt. Mauris ultrices facilisis dictum. Aliquam
                  erat volutpat. In lobortis sodales tempus. Vivamus id lobortis dolor, ut elementum
                  neque. Vestibulum id mollis sapien, sed tempus ipsum. Cras aliquam porta velit,
                  vel elementum augue luctus sit amet. Integer posuere lobortis nunc, sed lobortis
                  leo maximus et. Aliquam erat volutpat. Proin sed sem elit. In non est vel dolor
                  posuere congue id vitae libero. Phasellus eget rhoncus nisi. Suspendisse molestie
                  quis lectus eu faucibus. Orci varius natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus.
                </p>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Sesudah" key="2" css={generateMargin({ type: 'left', value: '0px' })}>
            <div css={styles.opinionTextContainer}>
              <div>
                <p css={styles.labelText}>Judul</p>
                <p>Lorem ipsum dolor sit amet</p>
              </div>
              <div>
                <p css={styles.labelText}>Kategori</p>
                <p>Kategori 1</p>
              </div>
              <div>
                <p css={styles.labelText}>Isi Berita</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean luctus sem dui, a
                  condimentum odio molestie eget. Suspendisse facilisis condimentum enim, id
                  fermentum eros placerat non. Nulla elit orci, fringilla ac feugiat nec, imperdiet
                  vel ante. Pellentesque faucibus ac leo sit amet convallis. Duis at sagittis nisl.
                  Etiam efficitur semper diam. Sed ac consequat odio. Etiam ultrices metus ut purus
                  posuere, id porttitor risus tincidunt. Mauris ultrices facilisis dictum. Aliquam
                  erat volutpat. In lobortis sodales tempus. Vivamus id lobortis dolor, ut elementum
                  neque. Vestibulum id mollis sapien, sed tempus ipsum. Cras aliquam porta velit,
                  vel elementum augue luctus sit amet. Integer posuere lobortis nunc, sed lobortis
                  leo maximus et. Aliquam erat volutpat. Proin sed sem elit. In non est vel dolor
                  posuere congue id vitae libero. Phasellus eget rhoncus nisi. Suspendisse molestie
                  quis lectus eu faucibus. Orci varius natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus.
                </p>
              </div>
            </div>
          </TabPane>
        </Tabs>
        <div css={styles.buttonContainer}>
          <Button htmlType="submit" shape="round" ghost type="primary">
            Tidak Setuju
          </Button>
          <Button type="primary" htmlType="submit" shape="round">
            Setujui Perubahan
          </Button>
        </div>
      </section>
    </div>
  );
}
