import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  card: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 74px;
    grid-row-gap: 27px;
  `,
  menuIcon: css`
    cursor: pointer;
    width: 14.98;
    height: 13.19;
  `,
  menuItem: css`
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 78px;

    img,
    span {
      height: 20px;
      color: ${colors.primaryBlue};
      font-size: 1rem;
      margin-bottom: 7px;
    }

    p {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 14.63px;
      margin-bottom: 0;
    }
  `
};
