import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    width: ${isMobile ? '100%' : '1080px'};
  `,
  content: css`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: ${isMobile ? '100%' : '1080px'};
    overflow-x: ${isMobile ? 'scroll' : 'initial'};
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  carouselButton: isMobile
    ? css`
        display: none;
      `
    : css`
        color: ${colors.primaryBlue};
        background-color: transparent;
        border-color: ${colors.primaryBlue};

        :first-of-type {
          margin-right: 10px;
        }
      `
});
