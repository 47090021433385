import { axiosHttpRequest } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';
import {
  removeAccessToken,
  removeRefreshToken,
  removeUserData,
  setAccessToken,
  setRefreshToken,
  setUserData
} from 'utils/auth';

export default {
  register: async (body) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/auth/register`,
      body
    });

    return { data: data?.data, error };
  },

  login: async (body) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/auth/login`,
      body
    });

    if (!error) {
      setAccessToken(data?.data?.data?.access_token);
      setRefreshToken(data?.data?.data?.refresh_token);
    }

    return { data: data?.data, error };
  },

  refreshToken: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: '/auth/refresh',
      withAuth: true,
      withRefreshToken: true
    });

    if (error) {
      removeAccessToken();
      removeRefreshToken();
      removeUserData();
    } else {
      setAccessToken(data?.data?.data?.access_token);
    }
  },
  getCurrentUser: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/auth/me`,
      withAuth: true
    });

    if (error) {
      removeAccessToken();
      removeRefreshToken();
      removeUserData();
    } else {
      setUserData(data?.data?.data);
    }

    return { data: data?.data, error };
  },
  logout: async () => {
    removeAccessToken();
    removeRefreshToken();
    removeUserData();

    return { error: null };
  },
  resetPassword: async (email) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.PUT,
      url: `/auth/reset-password`,
      body: {
        email
      }
    });

    return { data: data?.data, error };
  }
};
