import { css } from '@emotion/react';

export default ({ isMobile, width }) => ({
  card: css`
    width: 100%;
    height: ${isMobile ? '100%' : 'max-content'};
    padding: ${isMobile ? 17 : 24}px;

    .ant-card-body {
      font-weight: 400;
      font-size: ${isMobile ? 0.688 : 0.75}rem;
      line-height: ${isMobile ? 13.89 : 14.63}px;
      // width: ${isMobile ? '100%' : width ?? '828.73px'};
      width: 100%;

      header {
        font-weight: 700;
        font-size: ${isMobile ? 1.063 : 1.5}rem;
        line-height: ${isMobile ? 22 : 30}px;
        margin-bottom: ${isMobile ? 9 : 22}px;
      }
    }
  `
});
