import { css } from '@emotion/react';

export default ({ isMobile }) => ({
  title: css`
    font-weight: 600;
    font-size: ${isMobile ? 1.063 : 1.5}rem;
  `,
  secondaryTitle: css`
    font-weight: ${isMobile ? 400 : 500};
  `
});
