import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    width: ${isMobile ? '100%' : '518px'};
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  headerText: css`
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0;
  `,
  secondaryHeaderText: css`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  `,
  card: css`
    padding: 24px;
    width: ${isMobile ? '100%' : '518px'};

    form {
      font-size: 0.875rem;
      font-weight: 400;
      label::before {
        display: none !important;
      }

      .ant-form-item-explain-error {
        width: ${isMobile ? '100%' : '225px'};
        font-size: 0.65rem;
        margin-top: 5px;
      }

      .ant-form-item {
        margin-bottom: 0;

        :nth-of-type(1) {
          margin-bottom: 10px;
        }
      }
    }
  `,
  input: css`
    overflow: hidden;
    width: ${isMobile ? '100%' : '225px'};

    height: 33px;
    box-sizing: border-box;
    border-radius: 20px;
  `,
  submitButton: css`
    width: 100%;

    button {
      width: 100%;
      height: 41px;
      font-weight: 600;
    }
  `,
  tncText: css`
    margin-top: 5px;
    margin-bottom: 23.45px;

    span {
      text-align: center;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      span {
        cursor: pointer;
        color: ${colors.primaryBlue};
      }
    }
  `,
  registerText: css`
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;

    margin-top: 17px;
    margin-bottom: 0;

    span {
      cursor: pointer;
      color: ${colors.primaryBlue};
    }
  `,
  divider: css`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    span {
      padding: 0 10px;
      font-size: 0.75rem;
    }
  `
});
