import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    header {
      font-size: 0.875rem;
      font-weight: 600;
    }
    section {
      margin-top: 14px;
      width: 100%;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
      width: 175px;
    }
    .ant-card-body {
      padding: 18px 16px;
    }
    .ant-row,
    .ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  `,
  text: css`
    color: ${colors.tertiaryGrey};
    font-size: 0.625rem;
    font-weight: 400;
    width: max-content;
    margin-top: 9px;
  `,
  checklistStyle: css`
    color: ${colors.primaryGreen};
    font-size: 1.25rem;
    font-weight: bolder;
  `,
  cardItemContainer: css`
    margin-bottom: 14px;
    border-radius: 15px;
  `,
  categoryInfoStyles: css`
    font-size: 0.813rem;
    font-weight: 500;
    flex-direction: column;
    align-items: flex-start !important;

    span:first-of-type {
      font-size: 0.75rem;
      color: ${colors.secondaryGrey};
    }
  `,
  isiCategoryStyles: css`
    font-size: 0.688rem !important;
    font-weight: 300;
    margin-top: 21px;
  `,
  styUpload: css`
    .ant-upload {
      width: 100%;
    }
    .upload-notes {
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
      font-size: 12px;
      margin: 8px;
    }
  `
};
