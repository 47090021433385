/** @jsxImportSource @emotion/react */

import TrendingNews from 'components/pages/TrendingNews';
import TopPicks from 'components/pages/TopPicks';
import TodaysHeadline from 'components/pages/TodayHeadline';
import RecentNews from 'components/pages/RecentNews';

import Ads from 'components/Ads';
import YouTubeAds from 'components/YouTubeAds';
import useGlobal from 'contexts/global';

import { section } from 'styles/layout';
import { Row, Col } from 'antd';
import { center, generateMargin, spaceBetween } from 'styles/utils';

export default function Home() {
  const { isMobile } = useGlobal();

  return (
    <div css={section}>
      <Row css={generateMargin({ type: 'vertical', value: '24px' })}>
        <Col span={24} css={isMobile && center}>
          <Ads height={isMobile ? 96 : 250} width={isMobile ? '100%' : 1080} size="Large" />
        </Col>
      </Row>
      <Row css={[spaceBetween, generateMargin({ type: 'bottom', value: '27px' })]}>
        <Col span={isMobile ? 24 : 'initial'}>
          <Row>
            <TodaysHeadline />
          </Row>
        </Col>
        <Col>
          <Row css={generateMargin({ type: 'vertical', value: isMobile ? '22px' : '60px' })}>
            <YouTubeAds />
          </Row>
          <Row css={center}>
            <Ads height={isMobile ? 280 : 280} width={isMobile ? '100%' : 350} size="Small" />
          </Row>
        </Col>
      </Row>
      <Row css={generateMargin({ type: 'bottom', value: isMobile ? '22px' : '30px' })}>
        <Col span={24}>
          <RecentNews />
        </Col>
      </Row>
      <Row css={[spaceBetween, generateMargin({ type: 'bottom', value: '46px' })]}>
        <Col
          css={generateMargin({ type: 'bottom', value: isMobile ? '22px' : 0 })}
          span={isMobile ? 24 : 'initial'}>
          <TopPicks />
        </Col>
        <Col span={isMobile ? 24 : 'initial'}>
          <TrendingNews />
        </Col>
      </Row>
    </div>
  );
}
