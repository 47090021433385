/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import RecentNews from 'components/pages/RecentNews';
import OtherNews from 'components/pages/OtherNews';
import NewsDetails from 'components/pages/NewsDetails';
import Ads from 'components/Ads';

import { section } from 'styles/layout';
import { generateMargin } from 'styles/utils';

import useGlobal from 'contexts/global';
import newsAPI from 'APIs/news';

import getStyles from './styles';
import Comment from 'components/Comment';

const initialState = {
  isLoading: false,
  error: null,
  daat: {}
};

export default function DetailsPage() {
  const { isMobile } = useGlobal();
  const styles = getStyles();

  const { slug } = useParams();
  let [{ data, isLoading }, setState] = useState(initialState);
  const [newsSEO, setNewsSEO] = useState({
    title: '',
    description: '',
    image: ''
  });

  useEffect(() => {
    if (data) {
      setNewsSEO({
        title: data?.title,
        description: 'Baca berita terlengkap dan terbaru di https://kabelins.com',
        image:
          String(data?.banner_path).indexOf('[') !== -1
            ? JSON.parse(data?.banner_path)?.[0]
            : data?.banner_path
      });
    }
  }, [data]);

  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await newsAPI.getNewsBySlug(slug);
    setState((prev) => ({ ...prev, error, data: data?.data, isLoading: false }));
  }, [slug]);

  return (
    <>
      <Helmet>
        <title>{newsSEO?.title}</title>
        <meta itemProp="name" content={newsSEO?.title} />
        <meta itemProp="description" content={newsSEO?.description} />
        <meta itemProp="image" content={newsSEO?.banner_path} />
      </Helmet>
      <div css={section}>
        <Row css={generateMargin({ type: 'vertical', value: '24px' })}>
          <Row>
            <Ads height={isMobile ? 96 : 250} width={isMobile ? '100%' : 1080} size="Large" />
          </Row>
        </Row>
        <Row css={styles.inlineBlock}>
          <Col css={generateMargin({ type: 'bottom', value: isMobile ? '22px' : 0 })}>
            <NewsDetails data={data} isLoading={isLoading} />
          </Col>
          <Col>
            <Row>
              <OtherNews />
            </Row>
            <Row
              css={[
                generateMargin({ type: 'top', value: '27px' }),
                generateMargin({ type: 'bottom', value: isMobile ? 0 : '27px' })
              ]}>
              <Ads height={isMobile ? 280 : 280} width={isMobile ? '100%' : 350} size="Small" />
            </Row>
            {!isMobile && (
              <Row>
                <Ads width={350} height={600} size="Medium" />
              </Row>
            )}
          </Col>
        </Row>
        <Row css={generateMargin({ type: 'bottom', value: '30px' })}>
          <Col>
            <RecentNews />
          </Col>
        </Row>
        <Row css={generateMargin({ type: 'bottom', value: '30px' })}>
          <Col span={24}>
            <Comment data={data?.comments ?? []} newsId={data?.id} />
          </Col>
        </Row>
      </div>
    </>
  );
}
