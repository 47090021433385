/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';
import { Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

export default function AdsInfo() {
  return (
    <TextCard>
      <header>Mengenai Iklan</header>
      <Paragraph>
        Bagi para pengguna yang sudah mendaftarkan diri menjadi anggota dapat menikmati layanan
        beriklan di website kabelins.com. Iklan yang disediakan di kabelins.com dibagi menjadi 3
        ukuran yaitu:
      </Paragraph>
      <div style={{ margin: '12px 0 12px 18px' }}>
        1. <Text strong>Besar:</Text> Iklan dengan ukuran ini akan ditampilkan dibagian atas website
        dan memiliki ukuran gambar sebesar 1080 x 250 pixel.
      </div>
      <div style={{ margin: '0 0 12px 18px' }}>
        2. <Text strong>Sedang:</Text> Iklan dengan ukuran ini akan ditampilkan dibagian samping
        website dan memiliki ukuran gambar sebesar 360 x 600 pixel.
      </div>
      <div style={{ margin: '0 0 12px 18px' }}>
        3. <Text strong>Kecil:</Text> Iklan dengan ukuran ini akan ditampilkan dibagian samping
        website dan memiliki ukuran gambar sebesar 350 x 280 pixel.
      </div>
      <Paragraph>
        Fitur beriklan dapat diakses melalui menu <Text strong>Pengaturan Akun</Text> kemudian{' '}
        <Text strong>Beli Iklan</Text>. Lengkapi semua data berupa ukuran yang diinginkan, durasi
        iklan, bukti pembayaran, gambar iklan yang ingin ditampilkan, dan informasi singkat mengenai
        iklan yang ingin anda tampilkan. Setelah pengisian data berhasil maka pihak kabelins.com
        akan melakukan verifikasi dan konfirmasi untuk mengecek kelayakan dan jika adanya
        pelanggaran ataupun hal yang bersifat dilarang untuk ditampilkan. Jika ditemukannya
        data-data yang bersifat melanggar aturan hukum ataupun tidak lengkap maka anda akan
        dihubungi oleh pihak kabelins.com untuk melakukan konfirmasi ataupun perubahan data yang
        sudah diberikan sebelumnya. Kemudian jika sudah terkonfirmasi oleh pihak terkait maka iklan
        akan segera dimunculkan di website kabelins.com
      </Paragraph>
    </TextCard>
  );
}
