/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import useGlobal from 'contexts/global';

// import BuyAdsMobile from './Mobile';
import BuyAdsWeb from './Desktop';
import BuyAdsMobile from './Mobile';

export default function BuyAds() {
  const { isMobile } = useGlobal();

  return isMobile ? <BuyAdsMobile /> : <BuyAdsWeb />;
}
