import { css } from '@emotion/react';
import colors from 'styles/colors';
import { checkIsMobile } from 'utils/isMobile';

const isMobile = checkIsMobile();
export default {
  html: css`
    box-sizing: border-box;
    font-size: 16px;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    #root {
      overflow: hidden;
    }
  `,

  '*': css`
    box-sizing: inherit;
  `,
  '*:before': css`
    box-sizing: inherit;
  `,
  '*:after': css`
    box-sizing: inherit;
  `,
  body: css`
    margin: 0;
    padding: 0;
    min-width: ${isMobile ? 'initial' : '1080px'};
    font-family: Montserrat;
    -webkit-font-smoothing: antialiased;
    background-color: ${colors.neutral};
  `,
  '.ant-tabs-tab + .ant-tabs-tab': css`
    margin: 0 0 0 25px;
  `,
  '.ant-picker-panel-container .ant-picker-panels': isMobile
    ? css`
        display: flex !important;
        flex-wrap: wrap !important;
        direction: ltr !important;
        width: min-content !important;
      `
    : ``,
  '.ant-dropdown': isMobile
    ? css`
        left: 0 !important;
      `
    : css``
};
