import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    header {
      font-size: 1rem;
      font-weight: 600;
      padding-bottom: 13px;
      border-bottom: 1px solid ${colors.neutral};
    }
    section {
      margin-top: 20px;
      width: 100%;
    }
    label {
      font-size: 0.75rem;
      font-weight: 600;
    }
    input {
      font-size: 0.75rem;
      height: 40px;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
    }
  `,
  ktpVerification: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 9px;
    margin-top: 32px;
    border-bottom: 1px solid ${colors.neutral};

    div {
      font-size: 0.875rem;
      font-weight: 600;

      svg {
        color: ${colors.primaryYellow};
      }
    }
  `,
  text: css`
    margin-top: 9px;
    font-size: 0.75rem;
    font-weight: 400;
  `,
  imageInfo: css`
    color: ${colors.secondaryGrey};
    margin-top: 19px;
    font-size: 0.625rem;
    font-weight: 400;
  `,
  fileUploadInfo: css`
    color: ${colors.tertiaryGrey};
    margin-left: 21px;
    font-size: 0.75rem;
    font-weight: 400;
  `
};
