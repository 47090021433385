/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Form, Button, Input, Modal, notification } from 'antd';
import { doChangePassword } from 'APIs/user';
import regex from 'constants/regex';
import useGlobal from 'contexts/global';

import getStyles from './styles';

export default function ChangePassword() {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  const onFinish = (values) => {
    doChangePassword(values)
      .then((res) => {
        notification.success({
          message: 'Password Updated',
          description: 'Password anda sudah diupdate'
        });
        setTimeout(() => {
          window.location.href = '/pengaturan-akun';
        }, 1000);
      })
      .catch((err) => {
        notification.error({
          message: 'Password Failed Updated',
          description:
            'Password anda gagal diupdate, silahkan periksa kembali data anda dan coba kembali'
        });
      });
  };

  return (
    <div css={styles.container}>
      <header>Ganti Password</header>
      <section>
        <Form name="validate_other" layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="old_password"
            label="Password Lama"
            rules={[
              {
                required: true,
                message: 'Tolong masukkan password Anda!'
              },
              {
                pattern: regex.password,
                message:
                  'Pastikan password kamu terdiri dari minimal 8 karakter alphanumerik dan 1 huruf besar'
              }
            ]}
            hasFeedback>
            <Input.Password
              type="password"
              name="oldPassword"
              css={styles.input}
              placeholder="Masukkan password"
              minLength={8}
              autoComplete="true"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password Baru"
            rules={[
              {
                required: true,
                message: 'Tolong masukkan password Anda!'
              },
              {
                pattern: regex.password,
                message:
                  'Pastikan password kamu terdiri dari minimal 8 karakter alphanumerik dan 1 huruf besar'
              }
            ]}
            hasFeedback>
            <Input.Password
              type="password"
              name="password"
              css={styles.input}
              placeholder="Masukkan password"
              minLength={8}
              autoComplete="true"
            />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            label="Konfirmasi Password Baru"
            dependencies={['password']}
            autoComplete="true"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password yang dimasukkan tidak cocok'));
                }
              })
            ]}>
            <Input.Password
              type="confirmPassword"
              name="password_confirmation"
              css={styles.input}
              placeholder="Konfirmasi Password"
            />
          </Form.Item>
          <p css={styles.text}>
            * Pastikan password kamu terdiri dari minimal 8 karakter, huruf besar dan 1 karakter
            spesial
          </p>
          <Form.Item>
            <Button type="primary" htmlType="submit" shape="round" block={isMobile}>
              Konfirmasi
            </Button>
          </Form.Item>
        </Form>
      </section>
    </div>
  );
}
