/** @jsxImportSource @emotion/react */
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

import Card from 'components/Card';
import { otherMenuList } from 'constants/navbar';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

function Menu() {
  const navigate = useNavigate();

  return (
    <Card>
      <div css={styles.card}>
        {otherMenuList.map(({ name, slug, Icon }) => (
          <div key={slug} css={styles.menuItem} onClick={() => navigate(`/${slug}`)}>
            <Icon />
            <p>{name}</p>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default function DropdownMenu() {
  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown overlay={<Menu />} placement="bottomRight">
          <MenuOutlined css={styles.menuIcon} />
        </Dropdown>
      </Space>
    </Space>
  );
}
