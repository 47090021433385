import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    header {
      font-size: ${isMobile ? 0.875 : 1}rem;
      font-weight: 600;
      padding-bottom: 13px;
      border-bottom: 1px solid ${colors.neutral};
    }
    section {
      margin-top: 20px;
    }
    label {
      font-size: 0.75rem;
      font-weight: 600;
    }

    textarea {
      font-size: 0.75rem;
    }

    input {
      font-size: 0.75rem;
      height: 40px;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
    }
    .ql-editor {
      min-height: 300px;
    }
  `,
  text: css`
    color: ${colors.tertiaryGrey};
    font-size: 0.625rem;
    font-weight: 400;
    width: max-content;

    margin-top: 9px;
  `,

  imageInfo: css`
    color: ${colors.secondaryGrey};
    margin-top: 19px;
    font-size: 0.625rem;
    font-weight: 400;
  `,
  fileUploadInfo: css`
    color: ${colors.tertiaryGrey};
    margin-left: 20px;
    font-size: 0.75rem;
    font-weight: 400;
  `,
  titleImageUpload: css`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    margin-top: 43px;
  `
});
