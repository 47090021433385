import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    position: sticky;
    top: 0;
    z-index: 99;
    padding: 22px 16px 1px 16px;
    background-color: ${colors.white};

    > div {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;

        > span {
          padding: 5px 15px;
        }
      }
    }
  `,
  logo: css`
    cursor: pointer;
  `,
  uploadButton: css`
    font-weight: 500;
    font-size: 0.688rem !important;
    border-color: ${colors.tertiaryGrey} !important;
  `,
  menu: css`
    margin-top: 65px;
    width: 100vw;
    font-style: normal;
    font-weight: 500;
    font-size: 1.063rem;
    color: ${colors.black};

    li.ant-menu-item-selected {
      background-color: ${colors.white} !important;
    }
  `,
  avatarStyle: css`
    display: flex;
    align-items: center;

    .ant-avatar {
      margin-right: 12px;

      img {
        width: inherit;
        height: inherit;
      }
    }
  `,
  searchInput: css`
    border-radius: 10em;
    margin-top: 15px;
  `,
  buttonContainer: css`
    padding: 16px;
    height: max-content !important;
    background-color: ${colors.white};

    button:first-of-type {
      margin-bottom: 16px;
    }
  `,
  button: css`
    border-radius: 10em;
    height: 43px;
    width: 100%;

    span {
      font-size: 0.938rem;
      font-weight: 500;
    }
  `,
  menuIcon: css`
    font-size: 1.25rem;
  `
};
