import {
  ApartmentOutlined,
  FileSearchOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MailOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { css } from '@emotion/react';
import { Divider } from 'antd';
import colors from 'styles/colors';

export const otherMenuList = [
  {
    name: 'Tentang Kami',
    slug: 'tentang-kami',
    Icon: () => <img width={'auto'} height={17.64} src="user.svg" />
  },
  {
    name: 'Pedoman Media Siber',
    slug: 'pedoman-cyber-media',
    Icon: () => <img width={'auto'} height={14.88} src="play.svg" />
  },
  {
    name: 'Panduan Komunitas',
    slug: 'panduan-komunitas',
    Icon: () => <img width={'auto'} height={19.1} src="users.svg" />
  },
  {
    name: 'Pendoman Hak Jawab',
    slug: 'syarat-dan-ketentuan',
    Icon: () => <ProfileOutlined styles={{ height: 20 }} />
  },
  {
    name: 'Kode Etik',
    slug: 'bantuan',
    Icon: () => <InfoCircleOutlined styles={{ height: 20 }} />
  },
  {
    name: 'Mengenai Iklan',
    slug: 'mengenai-iklan',
    Icon: () => <img width={'auto'} height={25} src="ads.svg" />
  },
  {
    name: 'Struktur Redaksi',
    slug: 'struktur-redaksi',
    Icon: () => <ApartmentOutlined />
  }
];

export const mainMenu = [
  {
    name: 'Home',
    slug: '',
    icon: <HomeOutlined />,
    iconStyle: css`
      color: ${colors.primaryBlue};
    `
  },
  {
    name: 'Surat Pengirim',
    slug: 'kategori/surat-pengirim',
    dataIndex: 'surat-pengirim',
    icon: <MailOutlined />,
    iconStyle: css`
      color: ${colors.primaryGreen};
    `
  },
  {
    name: 'Tanggapan',
    slug: 'kategori/tanggapan',
    dataIndex: 'tanggapan',
    icon: <FileSearchOutlined />,
    iconStyle: css`
      color: ${colors.primaryRed};
    `
  },
  {
    tab: <Divider type="vertical" style={{ height: 32.5 }} />,
    slug: 'tab',
    props: {
      disabled: true
    }
  }
];

export const sidebarMenu = [
  {
    name: 'Edit Profile',
    slug: '',
    isOfficialMenu: false,
    isVerifMenu: false,
    isMobileMenu: true
  },
  {
    name: 'Ganti Password',
    slug: 'ganti-password',
    isOfficialMenu: false,
    isVerifMenu: false,
    isMobileMenu: true
  },
  {
    name: 'Opini',
    slug: null,
    childrens: [
      { name: 'Tulis Opini', slug: 'tulis-opini' },
      { name: 'Cek Opini', slug: 'cek-opini' }
    ],
    isOfficialMenu: false,
    isVerifMenu: true,
    isMobileMenu: false
  },
  {
    name: 'Tanggapan',
    slug: null,
    childrens: [{ name: 'Cek Tanggapan', slug: 'cek-tanggapan' }],
    isOfficialMenu: false,
    isVerifMenu: true,
    isMobileMenu: false
  },
  {
    name: 'Cek Surat Saya',
    slug: 'cek-surat-saya',
    isOfficialMenu: false,
    isVerifMenu: true,
    isMobileMenu: true
  },
  {
    name: 'Gallery Saya',
    slug: 'gallery-saya',
    isOfficialMenu: false,
    isVerifMenu: true,
    isMobileMenu: true
  },
  {
    name: 'Beli Iklan',
    slug: 'beli-iklan',
    isOfficialMenu: false,
    isVerifMenu: false,
    isMobileMenu: true
  }
];

export const sidebarMenuOfc = [
  {
    name: 'Edit Profile',
    slug: '',
    isOfficialMenu: false,
    isVerifMenu: false
  },
  {
    name: 'Ganti Password',
    slug: 'ganti-password',
    isOfficialMenu: false,
    isVerifMenu: false
  },
  {
    name: 'Surat Pengirim',
    slug: 'surat-pengirim',
    isOfficialMenu: false,
    isVerifMenu: false
  },
  {
    name: 'Tanggapan Saya',
    slug: 'tanggapan-saya',
    isOfficialMenu: false,
    isVerifMenu: false
  }
];
