/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Form, Button, Upload, Row, Col, Input, Modal, notification } from 'antd';
import DynamicImage from 'components/DynamicImage';
import { InfoCircleFilled } from '@ant-design/icons';
import useAuth from 'contexts/auth';
import { useEffect, useState } from 'react';
import regex from 'constants/regex';

import styles from './styles';
import { uploadMedia } from 'APIs/media';
import { doUpdateUserData, doUpdateUserKTP, doUpdateUserProfile } from 'APIs/user';

export default function EditProfileWeb() {
  const { currentUser, fetchCurrentUser } = useAuth();
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const onFinish = () => {
    doUpdateUserData({
      name: user?.name,
      phone: user?.phone
    })
      .then(() => {
        Modal.success({
          title: 'Profile berhasil diupdate',
          content: 'Data profile anda sudah diupdate',
          maskClosable: true
        });
        fetchCurrentUser();
      })
      .catch(() => {
        Modal.error({
          title: 'Profile gagal diupdate',
          content: 'Mohon periksa kembali data yang anda masukan atau refresh halaman',
          maskClosable: true
        });
      });
  };

  const handleInputOnChange = (e) =>
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 1) {
        return false;
      }
      Modal.error({
        title: 'Error',
        content: `Ukuran File lebih besar dari 1 MB`,
        maskClosable: true
      });
      return true;
    } else {
      Modal.error({
        title: 'Error',
        content: `${file.name} tidak bertipe jpg, jpeg, atau png `,
        maskClosable: true
      });

      return true;
    }
  }

  const handleKTPStatus = () => {
    if (user?.is_verified_user) {
      return 'Akun anda sudah diverifikasi';
    }

    if (!user?.is_verified_user && user?.ktp_picture) {
      return 'Mohon menunggu untuk data anda diverifikasi';
    }

    return 'Lakukan verifikasi untuk mengirim surat, menulis opini, dan mengupload gallery';
  };

  function handleImageUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'profile' })
        .then(({ data }) => {
          const imgPath = data?.path;
          doUpdateUserProfile({
            profile: imgPath
          })
            .then(() => {
              notification.success({
                message: 'Profile berhasil diupdate'
              });
            })
            .catch(() => {
              notification.error({
                message: 'Profile gagal diupdate',
                description: 'Mohon periksa kembali dan upload ulang gambarnya'
              });
            })
            .finally(() => {
              fetchCurrentUser();
            });
        })
        .catch((err) => {
          notification.error({
            message: 'Profile gagal upload',
            description: 'Mohon periksa kembali dan upload ulang gambarnya'
          });
        });
    }
  }

  function handleKTPUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'ktp' })
        .then(({ data }) => {
          const imgPath = data?.path;
          doUpdateUserKTP({
            ktp: imgPath
          })
            .then(() => {
              Modal.success({
                title: 'KTP berhasil diupload',
                content: 'Mohon menunggu sampai data anda di verifikasi oleh team terkait',
                maskClosable: true
              });
            })
            .catch(() => {
              Modal.error({
                title: 'KTP gagal diupdate',
                content: 'Mohon periksa kembali dan upload ulang gambarnya',
                maskClosable: true
              });
            })
            .finally(() => {
              fetchCurrentUser();
            });
        })
        .catch((err) => {
          Modal.error({
            title: 'KTP gagal upload',
            content: 'Mohon periksa kembali dan upload ulang gambarnya',
            maskClosable: true
          });
        });
    }
  }

  return (
    <div css={styles.container}>
      <header>Edit Profile</header>
      <section>
        <Form
          name="validate_other"
          layout="vertical"
          onFinish={onFinish}
          onChange={handleInputOnChange}>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <DynamicImage width={100} height={125} src={user.profile_picture} />
              <p css={styles.imageInfo}>
                Ukuran file maksimal 1MB. Format yang didukung adalah .jpg dan jpeg.
              </p>
              <Upload
                maxCount={1}
                listType="picture"
                accept=".jpg,.png,.jpeg"
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                onChange={handleImageUploader}>
                <Button shape="round" ghost type="primary">
                  Pilih File
                </Button>
              </Upload>
            </Col>
            <Col span={24}>
              <Form.Item
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'E-mail yang Anda masukkan tidak valid!'
                  }
                ]}>
                <Input
                  type="email"
                  name="email"
                  placeholder="Masukkan email"
                  value={user.email}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Nama">
                <Input type="text" name="name" placeholder="Nama" value={user.name} />
              </Form.Item>
              <Form.Item
                label="Nomor Telepon"
                rules={[
                  {
                    pattern: regex.IDPhoneNumber,
                    message: 'Tolong masukkan no telepon yang valid!'
                  }
                ]}>
                <Input type="text" name="phone" placeholder="08•••••••••" value={user.phone} />
              </Form.Item>

              <Form.Item>
                <Button block type="primary" htmlType="submit" shape="round">
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {!currentUser?.is_official && !currentUser?.is_verified_user && (
            <>
              <Row css={styles.ktpVerification}>
                <div>
                  Verifikasi KTP <InfoCircleFilled />
                </div>
                <Upload
                  maxCount={1}
                  listType="picture"
                  accept=".jpg,.png,.jpeg"
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                  onChange={handleKTPUploader}>
                  <Button type="primary" disabled={user?.is_verified_user && user?.ktp_picture}>
                    Upload
                  </Button>
                </Upload>
              </Row>
              <p css={styles.text}>{handleKTPStatus()}</p>
            </>
          )}
        </Form>
      </section>
    </div>
  );
}
