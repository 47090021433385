import axios from 'axios';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';
import { getAccessToken, getRefreshToken, removeAccessToken, removeRefreshToken } from './auth';

import authAPI from 'APIs/auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const unauthorized = error.response.status === 401 || error.response.status === 403;

    const containAuthorization =
      originalRequest?.headers?.Authorization &&
      !originalRequest?.headers?.Authorization?.includes('undefined');

    if (unauthorized && !originalRequest._retry && containAuthorization) {
      originalRequest._retry = true;
      await authAPI.refreshToken();
      return axiosInstance(originalRequest);
    }

    if (unauthorized) {
      removeAccessToken();
      removeRefreshToken();
      window.location.pathname = '/login';
      return;
    }

    return Promise.reject(error);
  }
);

const axiosRequest = {
  GET: ({ url, config }) => axiosInstance.get(url, config),
  POST: ({ url, data = null, config }) => axiosInstance.post(url, data, config),
  PUT: ({ url, data = null, config }) => axiosInstance.put(url, data, config),
  DELETE: ({ url, config }) => axiosInstance.delete(url, config)
};

export const axiosHttpRequest = async ({
  url = '',
  body = null,
  method = HTTP_REQUEST_METHOD.GET,
  withAuth = false,
  withRefreshToken = true,
  isUploadFile = false
}) => {
  let config = {};

  if (withAuth) {
    config.headers = {
      Authorization: `Bearer ${withRefreshToken ? getRefreshToken() : getAccessToken()}`
    };
  }

  if (isUploadFile) {
    config.headers = {
      Authorization: `Bearer ${withRefreshToken ? getRefreshToken() : getAccessToken()}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
  }

  let error, data;

  try {
    data = await axiosRequest[`${method}`]({ url, data: body, config });
  } catch (e) {
    error = e;
    throw e;
  }

  return { data, error };
};

export const httpRequestWithoutAwait = ({
  url = '',
  body = null,
  method = HTTP_REQUEST_METHOD.GET,
  withAuth = false,
  withRefreshToken = true
}) => {
  let config = {};

  if (withAuth) {
    config.headers = {
      Authorization: `Bearer ${withRefreshToken ? getRefreshToken() : getAccessToken()}`
    };
  }

  return axiosRequest[`${method}`]({ url, data: body, config });
};
