import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  filter: css`
    width: 100%;
    max-width: ${isMobile ? '100%' : '349px'};
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 22px;

    p {
      font-size: 0.875rem;
    }
    label {
      font-size: 0.75rem;
    }
    .ant-divider {
      margin: 11px 0px;
    }
  `,
  input: css`
    width: -webkit-fill-available !important;
    border-radius: 7.06625px;
    height: 39.85px;
    font-size: 0.75rem;

    input {
      font-size: 0.75rem;
    }

    .ant-select-selector {
      border-radius: 7.06625px !important;
      height: 39.85px !important;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
    }
  `,
  button: css`
    width: 104px;
    height: 43px;
    border-color: ${colors.primaryBlue};
    color: ${colors.primaryBlue};
  `
});
