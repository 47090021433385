import { css } from '@emotion/react';
import { checkIsMobile } from 'utils/isMobile';

const isMobile = checkIsMobile();

export const section = css`
  width: ${isMobile ? '100%' : '1080px'};
  margin: 0 auto;
  padding: ${isMobile ? '6px' : '0px'};
`;
