/** @jsxImportSource @emotion/react */

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { getPublicImagePath } from 'utils/getPublicImage';
import useAuth from 'contexts/auth';
import useGlobal from 'contexts/global';

import styles from './styles';
import { useEffect } from 'react';

export default function LoginRegisterPage({ children }) {
  const { isMobile } = useGlobal();

  const { authenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated]);

  if (isMobile) {
    return <div css={styles.mobileContainer}>{children}</div>;
  }

  return (
    <div css={styles.container}>
      <div css={styles.left}>
        <div css={styles.description}>
          <img src={getPublicImagePath('compass.png')} width={63.49} height={63.49} />
          <p css={styles.primaryText}>
            Ada banyak hal yang terjadi di sekelilingmu, <span>Jelajahi sekarang !</span>
          </p>
          <p css={styles.secondaryText}>
            Kamu hanya beberapa langkah ke berita terbaru di sekeliling kamu
          </p>
          <Button type="ghost" shape="round" onClick={() => navigate('/')}>
            Jelajahi Sekarang
          </Button>
        </div>
      </div>
      <div css={styles.right}>{children}</div>
    </div>
  );
}
