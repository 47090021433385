import { css } from '@emotion/react';

export default ({ isMobile }) => ({
  container: css`
    width: ${isMobile ? '100%' : '349px'};
  `,
  card: css`
    cursor: pointer;
    width: 100%;
    padding: 16.5px;

    .ant-card-body {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 28px;

      :before,
      :after {
        content: none;
      }
    }
  `,
  title: css`
    font-weight: 600;
    font-size: 1.5rem;
  `,
  secondaryTitle: css`
    font-weight: 500;
  `,
  seeAllButton: css`
    font-weight: 600;
  `,
  cardItem: css`
    cursor: pointer;
    display: flex;
  `,
  cardDescription: css`
    width: 190px;
    margin-left: 24px;
  `,
  cardTitle: css`
    margin-top: 10px;
    margin-bottom: 7px;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 17px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  cardDate: css`
    font-size: 0.625rem;
    font-weight: 400;
  `
});
