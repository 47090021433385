/** @jsxImportSource @emotion/react */
import { memo } from 'react';

import { generateMargin, spaceBetween } from 'styles/utils';

import useGlobal from 'contexts/global';

import getStyles from './styles';

export default memo(function HeaderTitle({ primaryText, secondaryText, LeftComponent = null }) {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  return (
    <div css={[generateMargin({ type: 'bottom', value: `${isMobile ? 11 : 20}px` }), spaceBetween]}>
      <div css={styles.title}>
        {primaryText} <span css={styles.secondaryTitle}>{secondaryText}</span>
      </div>
      {LeftComponent && <LeftComponent />}
    </div>
  );
});
