/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Dropdown, Form, Input, Menu } from 'antd';
import {
  YoutubeFilled,
  FacebookFilled,
  InstagramOutlined,
  SearchOutlined,
  PlusOutlined,
  DownOutlined
} from '@ant-design/icons';

import { spaceBetween, generatePadding, generateMargin, verticalCenter } from 'styles/utils';
import { section } from 'styles/layout';
import useAuth from 'contexts/auth';

import TabList from 'components/Navbar/components/TabList';
import DropdownMenu from 'components/Navbar/components/DropdownMenu';

import styles from './styles';
import { getPublicImagePath } from 'utils/getPublicImage';

function RenderButton() {
  const { authenticated, logout, currentUser } = useAuth();
  const profilePicture = currentUser?.profile_picture;

  const navigate = useNavigate();

  if (authenticated) {
    const menu = (
      <Menu>
        <Menu.Item key="pengaturan-akun" onClick={() => navigate('/pengaturan-akun')}>
          Pengaturan Akun
        </Menu.Item>
        <Menu.Item key="logout" onClick={logout}>
          Keluar
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} placement="bottomRight">
        <Button
          css={styles.profileButton}
          shape="round"
          icon={
            <Avatar size={25.79} src={profilePicture}>
              {currentUser?.name?.charAt(0)}
            </Avatar>
          }>
          My Profile
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  }

  return (
    <>
      <Button
        type="primary"
        shape="round"
        onClick={() => navigate('/login')}
        css={[styles.button, generateMargin({ type: 'right', value: '12px' })]}>
        Masuk
      </Button>
      <Button
        type="primary"
        ghost
        shape="round"
        onClick={() => navigate('/register')}
        css={styles.button}>
        Daftar
      </Button>
    </>
  );
}

export default function NavbarWeb() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [form] = Form.useForm();

  const onSubmit = ({ search }) => {
    form.resetFields();

    if (!search || search?.length == 0) return;
    navigate(`hasil-pencarian?title=${search}`);
  };

  return (
    <div css={styles.container}>
      <div css={[styles.navbarHeader, spaceBetween]}>
        <div css={[section, spaceBetween]}>
          <span>{moment().locale('id').format('dddd, DD MMMM YYYY')}</span>
          <div>
            <a
              href="https://www.facebook.com/profile.php?id=100087158550022"
              target={'_blank'}
              rel="noreferrer">
              <FacebookFilled css={styles.iconButton} />
            </a>
            <InstagramOutlined css={styles.iconButton} />
            <YoutubeFilled css={styles.iconButton} />
          </div>
        </div>
      </div>
      <div css={styles.navbarContent}>
        <div css={section}>
          <div
            css={[
              verticalCenter,
              spaceBetween,
              generatePadding({ type: 'top', value: '15px' }),
              generatePadding({ type: 'bottom', value: '5px' })
            ]}>
            <img
              src={getPublicImagePath('logo.jpg')}
              alt="kabelins logo"
              width={'auto'}
              height={60}
              css={styles.logo}
              onClick={() => navigate('/')}
            />
            <div css={spaceBetween}>
              <Form onFinish={onSubmit} form={form} name="search-form" css={styles.searchContainer}>
                <Form.Item name="search">
                  <Input
                    placeholder="Cari judul berita"
                    prefix={<SearchOutlined css={styles.colorGrey} fill="blue" />}
                    type="search"
                    css={styles.searchInput}
                  />
                </Form.Item>
              </Form>
              {Boolean(currentUser?.is_verified_user) && (
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusOutlined />}
                  css={[styles.uploadButton, generateMargin({ type: 'right', value: '12px' })]}
                  onClick={() => navigate('/upload-surat')}
                  ghost>
                  Upload Surat
                </Button>
              )}
              <RenderButton />
            </div>
          </div>
          <div css={[spaceBetween, styles.tabNavbar]}>
            <TabList />
            <DropdownMenu />
          </div>
        </div>
      </div>
    </div>
  );
}
