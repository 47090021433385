import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  withActionIcon: {
    card: css`
      width: ${isMobile ? 235 : 342}px;
      height: ${isMobile ? 245 : 409}px;
      padding: 6px;
    `,
    cardTitle: css`
      font-size: ${isMobile ? 0.689 : 0.875}rem;
      line-height: ${isMobile ? 11.97 : 17.07}px;
      margin-bottom: ${isMobile ? 4 : 7}px;
      font-weight: 700;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `,
    cardDescription: css`
      font-size: ${isMobile ? 0.438 : 0.625}rem;
      line-height: ${isMobile ? 8.31 : 12.19}px;
      font-weight: 400;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `,
    sosmedInfo: css`
      margin-top: ${isMobile ? 8 : 24.67}px;
      margin-bottom: ${isMobile ? 0 : 20}px;
      font-size: ${isMobile ? 0.689 : 0.876}rem !important;
      font-weight: 600;

      display: flex;

      &[data-position='bottom'] {
        position: absolute;
        bottom: ${isMobile ? 10 : 0}px;
      }

      .anticon {
        color: ${colors.secondaryGrey};
        margin-right: ${isMobile ? 8.85 : 12.67}px;
      }

      > div {
        cursor: pointer;
        margin-right: ${isMobile ? 23.35 : 37}px;
      }
    `
  },

  withDetails: {
    category: css`
      width: fit-content;
      margin-bottom: 7px;
    `,
    card: css`
      width: 100%;
      max-width: ${isMobile ? '100%' : '703px'};
      padding: ${isMobile ? '16px 12px 10px 12px' : '41px 25px 20px 25px'};

      .ant-card-meta {
        margin-bottom: 13px !important;
      }

      .ant-comment-avatar {
        img {
          width: inherit;
          height: inherit;
        }
      }
    `,
    title: css`
      font-weight: 700;
      font-size: ${isMobile ? 1.2 : 1.75}rem;
      line-height: ${isMobile ? 22 : 34}px;
      width: ${isMobile ? '100%' : '637.59px'};
      margin-bottom: ${isMobile ? 18 : 30}px;
      margin-top: ${isMobile ? 6 : 10}px;
    `,

    imageDescription: css`
      font-weight: 400;
      font-size: 0.815rem;
      line-height: 15.63px;
      margin-top: 14px;
    `,

    profileContainer: css`
      font-size: ${isMobile ? 0.563 : 0.75}rem;
      font-weight: 400;
      color: ${colors.black};
      align-items: center;

      > p {
        margin-bottom: 0;
      }
    `,
    avatar: css`
      font-size: ${isMobile ? 0.863 : 1}rem !important;
    `,
    writerName: css`
      font-weight: 600;
      color: ${colors.primaryBlue};
    `,
    publishedDate: css`
      font-weight: 500;
    `,
    limitedContent: css`
      font-weight: 400;
      margin-top: 23.5px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `,
    content: css`
      font-weight: 400;
      font-size: ${isMobile ? 0.688 : 0.875}rem;
      line-height: ${isMobile ? 13.19 : 20.99}px;
      margin-top: 23.5px;

      & > p {
        margin-bottom: 0px;
        line-height: 22px;
        font-size: 13px;
      }

      & img {
        width: 100%;
      }
    `,
    shareButtonContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: ${isMobile ? 10 : 15}px 0;
    `,
    shareButton: css`
      // width: ${isMobile ? 185 : 235}px !important;
      font-size: ${isMobile ? 0.563 : 0.75}rem;
      font-weight: 600;
      color: ${colors.black};

      img {
        cursor: pointer;
        border-radius: 100%;
        margin-left: ${isMobile ? 5 : 10}px;
      }
    `,
    sosmedInfo: css`
      display: flex;
      justify-content: flex-end;
      font-weight: 600;
      font-size: ${isMobile ? 0.689 : 0.876}rem !important;
      color: ${colors.black};

      .anticon {
        color: ${colors.secondaryGrey};
        margin-right: ${isMobile ? 8.85 : 12.67}px;
        margin-left: ${isMobile ? 23.35 : 37}px;
      }
      > div {
        cursor: pointer;

        &[data-state='liked'] {
          color: ${colors.primaryGreen} !important;

          .anticon {
            color: ${colors.primaryGreen} !important;
          }
        }

        &[data-state='disliked'] {
          color: ${colors.primaryRed} !important;
          .anticon {
            color: ${colors.primaryRed} !important;
          }
        }
      }
    `,
    button: css`
      font-weight: 600;
      font-size: ${isMobile ? 0.689 : 0.875}rem;
      line-height: ${isMobile ? 11.74 : 17}px;
      padding: 0;
      margin-top: 5px;
    `
  }
});
