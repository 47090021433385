// remove this later when integration
export const initialLargeAds = [
  {
    id: '1',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLWYaCH_-ON7UsT-ZynbBKD6imdbjIaUcPlzPAnBZ6-QHsHcnPfp3voRTdmAC_kGBpWh27byBZALmZa1E3Gh0k3u_1moGRPOpCaq6iLGRZQsZLF8twEtR800mBxPyjaAFsvMVcW-dbTqm-gVQlNEz4Y=w1024-h364-no?authuser=0',
    link: 'www.kabelins.com'
  },
  {
    id: '2',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLVAndBJv-Jpx-_ehU0VckeTN_TI8u6bedvaYjLu-Wv1TQ1sQ8CZ0CCaDeNSpQIfIm1T7AqBFwmp4leiW2q1KNHZBbMlcKuH3duXvWwc1uL0Rj1hI7rO1ycZ0zN3lBDYKRqVUWbhkPXIj9ikiGCWwWk=w1024-h545-no?authuser=0',
    link: 'www.kabelins.com'
  },

  {
    id: '4',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLUtyX22tMQdK6kQDWopT1FbZ05A3IL2KleMDfKL0uk57tofOZbVrLJdUVpogHie2oWEUWwfe0e1PiJ3R0ohML0ZXD3h8aRRma53p9J3p1H7JbO8KLdWQKB8V5Px9AA5Qq3L6Q8RW89DIyi6CB8OVBo=w1080-h250-no?authuser=0',
    link: 'www.kabelins.com'
  },
  {
    id: '5',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLXo9PdtcNCLwaFE_itoajB_YV_w5B-kxhiNsBNAJVhknubGoUcnjVyvQ_wx25ozFMSVb4D8QUi44Tu-KBym_ETLEuNFNXMV0qUsTiKQGZlx8lf7ioBSAJoqhmQQPH0b2nIaOXJCcmhxiy6PJPLLIoc=w1455-h337-no?authuser=0',
    link: 'https://panel.niagahoster.co.id/ref/348647'
  },
  {
    id: '6',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLXudkxthKh2XA_NLFVfi0QuFnLh_1_Q8NGDBGe8OjfM8BQuQ79lQLn-ReLy1aeVct6PwdZPp5MI-ae6xCt1O6Rc2HUa4GPwqyKfwKjdg06yenT0NmxERRzN7TsfZwF0Y1BECOWV5yn5HSbeltQapVU=w1455-h337-no?authuser=0',
    link: 'www.kabelins.com'
  }
];

export const initialSmallAds = [
  {
    id: '1',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLW5DHK0FFQA1xmZj5N1_SSkZAaNn98_h75f6HWma8yZwTsYsKJvtoTP_62QQqfPCbJJFAkxhtcPZjSZZCzp2XTgSYzpRqc9Nwt39HLotKktnvzPnWOlepOC9a9e0RzmltEkGU0LWpGEWtLoy7_vQgg=w700-h560-no?authuser=0',
    link: 'www.kabelins.com'
  },
  {
    id: '2',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLW8Tgf4mSPBVHtSxAyAeubtzElVVr_EX08l7jgJJaUy-WwbNdub460rA-P9A7dWx5Abz1YhgPd0Hpzc5p-VzbO0SWvrAKGP6G4zXz49QySOiZg9borlzt5c6YTcz0Rj2zCBUERsf_Bowg2P6aJ8J9I=w636-h639-no?authuser=0',
    link: 'www.kabelins.com'
  },
  {
    id: '3',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLVfIHUw73LDuIb75KTSuVqjYmoNT7bTZYJAMRoXhSA0-PD-A1vTuT7xLjvTcAUYSKv0qnClwAi1sa_5qGwwYj2dK9bo8ZeqccZhyCE0lBHeUtHihZPo-PlA8j5Relvf3H9MIPKOnm0ut2ZGtO3Mbr0=w1083-h784-no?authuser=0',
    link: 'www.kabelins.com'
  },
  {
    id: '4',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLWq5cFgcWm5oG20k1XxTqJ-yrpt3SiJMwdBo_6-Vz6okpjwSgPow44ocEfFCInJ8cg3CHGMYE9A6EYdK_INFI4m7NuzhASx_UmTSW57ScbJ2j2fv2qEGwDeO4ktD-vc2oHq6-ItdD6xtCg-MZ5t35k=s841-no?authuser=0',
    link: 'www.kabelins.com'
  },
  {
    id: '5',
    imageUrl:
      'https://lh3.googleusercontent.com/pw/AM-JKLWIAvLzSAJpQBN_i25LJBLaMa9kpyPVQUrv49sGIFinA5Mmh-1HXvLi-_8ocNpGUBrFZXXFbS95VMUn7wgonL1ZYg3yAIcJNv907tuq0ZV47i21wfxq5gX-lOgkipaGtU45MN0TQvRsZFDQerZdfEo=s841-no?authuser=0',
    link: 'www.kabelins.com'
  }
];

export const initialMediumAds = [
  {
    id: '1',
    imageUrl: 'https://www.fast.or.id/wp-content/uploads/2020/07/placeholder-2.png',
    link: 'www.kabelins.com'
  }
];
