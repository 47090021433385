import { css } from '@emotion/react';
import colors from 'styles/colors';
import { checkIsMobile } from 'utils/isMobile';

const isMobile = checkIsMobile();

export default {
  container: css`
    width: ${isMobile ? '100vw' : '607px'};
  `,
  card: css`
    cursor: pointer;
    width: 100%;
    max-width: ${isMobile ? '100%' : '607px'};
    padding: ${isMobile ? 6 : 10}px;
    height: ${isMobile ? 390 : 614}px;

    &[data-state='empty'] {
      padding-top: ${isMobile ? 25 : 50}px;
      height: ${isMobile ? 50 : 100}vh;

      .ant-empty-image {
        height: 200px;
      }
    }
  `,
  cardHeader: css`
    display: flex;
    justify-content: space-between;

    margin-bottom: ${isMobile ? 11 : 20}px;
    margin-top: ${isMobile ? 15 : 16.5}px;
  `,
  cardTitle: css`
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-size: ${isMobile ? 0.938 : 1.375}rem;
    line-height: ${isMobile ? 18.07 : 26.82}px;
    margin-bottom: ${isMobile ? 2 : 7}px;
  `,
  cardDescription: css`
    font-size: ${isMobile ? 0.688 : 0.875}rem;
    line-height: ${isMobile ? 13.19 : 17.07}px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  button: css`
    font-size: ${isMobile ? 0.688 : 0.875}rem;
    font-weight: 600;
    padding: 0;
    margin-top: ${isMobile ? 0 : 20}px;
  `,
  sosmedInfo: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${isMobile ? 23.25 : 37}px;
    font-weight: 600;
    font-size: ${isMobile ? 0.689 : 0.876}rem !important;

    .anticon {
      color: ${colors.secondaryGrey};
      margin-right: ${isMobile ? 8 : 12.67}px;
    }
  `,

  secondaryCard: css`
    cursor: pointer;
    margin-top: ${isMobile ? 17 : 27}px;
    padding: ${isMobile ? 11 : 17}px;

    width: 100%;
    max-width: ${isMobile ? '100%' : '607px'};
  `,
  secondaryCardCategory: css`
    margin-top: ${isMobile ? 2 : 13}px;
    margin-bottom: ${isMobile ? 7 : 10}px;
  `,
  secondCardTitle: css`
    margin-bottom: ${isMobile ? 0 : 7}px;
    font-size: ${isMobile ? 0.626 : 0.875}rem;
    line-height: ${isMobile ? 10.97 : 17.07}px;

    color: ${colors.black};
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  secondCardDescription: css`
    font-size: ${isMobile ? 0.563 : 0.75}rem;

    color: ${colors.black};
    font-weight: 400;
  `,
  secondButton: css`
    font-size: ${isMobile ? 0.688 : 0.875}rem;
    bottom: ${isMobile ? 0 : 7}px;
    right: ${isMobile ? 10 : 20}px;

    font-weight: 600;
    padding: 0px;
    position: absolute;
  `
};
