import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    header {
      font-size: ${isMobile ? 0.875 : 1}rem;
      font-weight: 600;
      padding-bottom: 13px;
      border-bottom: 1px solid ${colors.neutral};
    }
    section {
      margin-top: 20px;
      width: 100%;
      position: relative;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
    }
    .ant-tabs-tab {
      font-size: ${isMobile ? 0.75 : 0.875}rem!important;
    }
  `,
  text: css`
    color: ${colors.tertiaryGrey};
    font-size: 0.62rem;
    font-weight: 400;
    width: max-content;
    margin-top: 9px;
  `,
  buttonContainer: css`
    position: ${isMobile ? 'none' : 'absolute'};
    right: 10px;
    top: -6px;
    display: flex;
    gap: 14px;
    z-index: 1;
    margin-left: ${isMobile ? '8px' : '0'};
  `,
  opinionTextContainer: css`
    gap: ${isMobile ? '23' : '30'}px;
    padding: ${isMobile ? '27px 8px 15px 8px' : '27px 16px'};
    font-size: ${isMobile ? '0.625' : '0.75'}rem;
    display: flex;
    flex-direction: column;
  `,
  labelText: css`
    font-weight: 600;
    margin-bottom: 6px;
  `,
  arrowBack: css`
    color: ${colors.primaryBlue};
    margin-right: 14px;
    font-size: 1.1rem;
  `
});
