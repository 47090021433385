import { axiosHttpRequest } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';

export default {
  getLocations: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: '/locations'
    });

    return { data: data?.data?.data, error };
  },
  getSorts: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: '/sorts'
    });

    return { data: data?.data?.data, error };
  },
  getSubCtg: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: '/categories/filter'
    });

    return { data: data?.data?.data, error };
  }
};
