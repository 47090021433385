/** @jsxImportSource @emotion/react */
import { Divider } from 'antd';

import { FacebookFilled, InstagramOutlined, YoutubeFilled } from '@ant-design/icons';

import { getPublicImagePath } from 'utils/getPublicImage';
import useGlobal from 'contexts/global';

import { section } from 'styles/layout';
import { inline } from 'styles/utils';
import getStyles from './styles';

export default function Footer() {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  return (
    <div css={[styles.container]} id="footer">
      <div css={[section, inline]}>
        <div css={styles.leftContent}>
          <img
            src={getPublicImagePath('logo.jpg')}
            alt="kabelins logo"
            width={'auto'}
            height={isMobile ? 25.18 : 57}
          />
          <p css={styles.address}>
            Jalan Jelambar Selatan II No. B7 RT 002 / RW 02 <br />
            Kel. Jelambar Baru. Kec. Grogol Petamburan <br />
            Jakarta Barat - DKI Jakarta 11460 <br />
            No. Kontak : 0858 8333 7773
          </p>
          <p css={styles.customerService}>Costumer Service : support@kabelins.com</p>
          <div style={{ fontSize: '11px', paddingBottom: '8px' }}>Version 1.0.0</div>
        </div>
        <Divider type="vertical" css={styles.divider} />
        <div css={styles.rightContent}>
          <p css={styles.contactUs}>Kontak Kami:</p>
          <a
            href="https://www.facebook.com/profile.php?id=100087158550022"
            target={'_blank'}
            rel="noreferrer">
            <FacebookFilled css={styles.iconButton} />
          </a>
          <InstagramOutlined css={styles.iconButton} />
          <YoutubeFilled css={styles.iconButton} />
        </div>
      </div>
    </div>
  );
}
