/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Button, Col, Card, Row } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import styles from './styles';
import { generateMargin } from 'styles/utils';

export default function CheckOpinionMobile() {
  const navigate = useNavigate();

  const data = [];
  for (let i = 0; i < 30; i++) {
    data.push({
      key: i,
      judul: `Edrward ${i}`,
      kunjungan: 32,
      likes: 99,
      dislikes: 33
    });
  }

  return (
    <div css={styles.container}>
      <header>Cek Opini Saya</header>
      <section>
        {data.map((item) => (
          <Card key={item.key} css={styles.cardItemContainer}>
            <Row>
              <Col>
                <p>{item.judul}</p>
              </Col>
              <Col>
                <CheckCircleFilled css={styles.checklistStyle} />
              </Col>
            </Row>
            <Row css={generateMargin({ type: 'vertical', value: '27px' })}>
              <Col span={8}>
                <Row>
                  <p>{item.kunjungan}</p>
                </Row>
                <Row>
                  <span>Kunjungan</span>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <p>{item.likes}</p>
                </Row>
                <Row>
                  <span>Likes</span>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <p>{item.dislikes}</p>
                </Row>
                <Row>
                  <span>Dislikes</span>
                </Row>
              </Col>
            </Row>
            <Button
              type="primary"
              size="medium"
              shape="round"
              onClick={() => navigate(`/pengaturan-akun/opini-detail/${item.judul}`)}>
              Lihat Detail
            </Button>
          </Card>
        ))}
      </section>
    </div>
  );
}
