import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    width: 100%;
  `,
  card: css`
    cursor: pointer;
    width: 656px;

    .ant-card-body {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 24px;

      :before,
      :after {
        content: none;
      }
    }
  `,
  title: css`
    font-weight: 600;
    font-size: 1.5rem;
  `,
  secondaryTitle: css`
    font-weight: 500;
  `,
  cardItem: css`
    cursor: pointer;
    display: flex;
  `,
  cardTitle: css`
    width: 405px;
    color: ${colors.black};
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 17px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  cardDescription: css`
    width: 300px !important;
    color: ${colors.black};
    font-size: 0.625rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `,
  cardHeaderTabs: css`
    display: flex;
    margin-bottom: 20px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `
};

export const tabStyle = ({ active, isMobile }) => {
  return {
    container: css`
      cursor: pointer;
      background-color: ${active ? 'default' : 'initial'};
      padding: 5px 25px;
      border-radius: 15.45px;
      width: fit-content;
      margin: 5px 0;
    `,
    text: css`
      color: ${active ? colors.primaryBlue : colors.secondaryGrey};
      font-size: ${isMobile ? 0.688 : 1.063}rem;
      font-weight: 600;
      width: max-content;
    `
  };
};
