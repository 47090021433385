import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  sidebar: css`
    width: 306px;
    margin-right: 20px;
    padding: 0 17px;
    height: max-content;

    .ant-card-body {
      font-size: 0.875rem;
      font-weight: 500;
      [data-active='true'] {
        color: ${colors.primaryBlue};
      }

      > div {
        padding: 17px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid ${colors.neutral};

        div {
          font-size: 0.75rem;
          padding: 17px 0;
          color: ${colors.secondaryGrey};
          padding-left: 23px;

          :first-of-type {
            padding-top: 34px;
          }
        }
      }
    }
  `,
  cardHeaderTabs: css`
    display: flex;
    margin-bottom: 20px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `
};
