/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { MasonryInfiniteGrid } from '@egjs/react-infinitegrid';

import Card from 'components/Card';
import DynamicImage from 'components/DynamicImage';

import useGlobal from 'contexts/global';
import newsAPI from 'APIs/news';

import { section } from 'styles/layout';
import getStyles from './styles';
import { Spin } from 'antd';

function getItems(data = [], key) {
  const nextItems = [];

  data.forEach((image) => {
    nextItems.push({ path: image.path, groupKey: key });
  });
  return nextItems;
}

export default function Gallery() {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const [items, setItems] = useState(() => getItems([], 0));
  const [maxPage, setMaxpage] = useState(0);

  const getGallery = async (page = 1) => {
    const { data, error } = await newsAPI.getGallery(page);

    if (!error) {
      setItems((prev) => [...prev, ...getItems(data?.data, page)]);
      setMaxpage(Math.ceil(data.total / data.per_page));
    }
  };

  useEffect(() => {
    getGallery();
  }, []);

  return (
    <div css={[styles.container, section]}>
      <Card style={styles.headerCard}>
        <p css={styles.headerTitle}>Gallery</p>
        <p css={styles.headerDescription}>Kumpulan foto kiriman para member Kabelins</p>
      </Card>
      <MasonryInfiniteGrid
        loading={<Spin />}
        className="container"
        gap={5}
        onRequestAppend={(e) => {
          const nextGroupKey = (e.groupKey || 1) + 1;
          if (nextGroupKey <= maxPage) {
            e.wait();
            setTimeout(() => {
              e.ready();
              getGallery(nextGroupKey);
            }, 1000);
          }
        }}>
        {items.length > 0
          ? items.map(({ groupKey, path }) => (
              <div css={styles.galleryImageWrapper} data-grid-groupkey={groupKey} key={path}>
                <DynamicImage src={path} width="100%" radius={0} preview />
              </div>
            ))
          : Array.from({ length: 3 }).map((item, idx) => (
              <div css={styles.galleryImageWrapper} data-grid-groupkey={0} key={idx}>
                <DynamicImage src={item?.path} width="100%" radius={0} preview />
              </div>
            ))}
      </MasonryInfiniteGrid>
    </div>
  );
}
