/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import styles from './styles';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { generateMargin } from 'styles/utils';
import { useEffect, useState } from 'react';
import newsAPI from 'APIs/news';

export default function CheckFeedbackMobile() {
  const navigate = useNavigate();

  const handleNavigate = (id) => navigate();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    newsAPI.viewEndUserSuratOfficial().then((res) => {
      setTableData(res?.data);
    });
  }, []);

  return (
    <div css={styles.container}>
      <header>Cek Tanggapan Saya</header>
      <section>
        {tableData.map((item) => (
          <Card key={item.key} css={styles.cardItemContainer}>
            <Row>
              <Col>
                <p>{item.judul}</p>
              </Col>
              <Col>
                <RightOutlined css={styles.arrowStyle} />
              </Col>
            </Row>
            <Row css={generateMargin({ type: 'top', value: '30px' })}>
              <Col css={styles.dateInfoStyles}>
                <span>Dibuat pada:</span>
                <span>{item.tanggalPembuatan}</span>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="medium"
                  shape="round"
                  onClick={handleNavigate(item.judul)}>
                  Lihat Detail
                </Button>
              </Col>
            </Row>
          </Card>
        ))}
      </section>
    </div>
  );
}
