/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import {
  Button,
  Typography,
  Form,
  Input,
  Select,
  Upload,
  Row,
  Col,
  Modal,
  Carousel,
  message,
  notification
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import TextCard from 'components/pages/TextCard';

import useGlobal from 'contexts/global';
import useAuth from 'contexts/auth';

import { generateMargin } from 'styles/utils';
import getStyles from './styles';
import { uploadMedia } from 'APIs/media';
import DynamicImage from 'components/DynamicImage';
import { getOfficialUserList } from 'APIs/user';
import newsAPI from 'APIs/news';

const { Text, Link } = Typography;
const { Option } = Select;

function UploadLetterForm() {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const [newsData, setNewsData] = useState({
    title: '',
    content: ''
  });
  const [banners, setBanners] = useState([]);
  const [offUser, setOffUser] = useState([]);

  function doValidate(form) {
    if (!form?.title) {
      message.error('Masukan judul article');
      return false;
    }
    if (!form?.for_user) {
      message.error('Pilih pihak yang dituju');
      return false;
    }
    if (banners?.length < 1) {
      message.error('Masukan gambar pendukung');
      return false;
    }
    if (!form?.content) {
      message.error('Content artikel tidak boleh kosong');
      return false;
    }

    return true;
  }

  const onFinish = () => {
    const payload = {
      ...newsData,
      banner_path: JSON.stringify(banners?.map((img) => img?.url))
    };
    if (doValidate(payload)) {
      newsAPI
        .createSuratPengirim(payload)
        .then((res) => {
          notification.success({
            message: 'Berhasil mengupload surat'
          });
          setTimeout(() => {
            window.location.href = '/pengaturan-akun/cek-surat-saya';
          }, 1500);
        })
        .catch(() => {
          notification.error({
            message: 'Gagal mengupload surat, silahkan coba kembali'
          });
        });
    }
  };

  function handleOnChange(value, key) {
    setNewsData((prev) => ({ ...prev, [key]: value }));
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 1) {
        return false;
      }
      Modal.error({
        title: 'Error',
        content: `Ukuran File lebih besar dari 1 MB`,
        maskClosable: true
      });

      return true;
    } else {
      Modal.error({
        title: 'Error',
        content: `${file.name} tidak bertipe jpg, jpeg, atau png `,
        maskClosable: true
      });

      return true;
    }
  }

  function handleImageUploaderRemove(file) {
    setBanners((prev) => prev?.filter((img) => img?.name !== file?.name));
  }

  function handleImageUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'news' }).then((res) => {
        const imgData = res?.data;
        setBanners((prev) => [...prev, { name: imgData?.name, url: imgData?.path }]);
      });
    }
  }

  useEffect(() => {
    getOfficialUserList().then((res) => {
      setOffUser(res?.data || []);
    });
  }, []);

  return (
    <TextCard>
      <header>Publikasikan surat & artikel anda</header>
      <p>
        Pada layar ini, kamu dapat mempublikasikan surat dan artikel mengenai hal yang sedang
        terjadi di sekeliling anda, yang mungkin bisa menarik perhatian pembaca.
        <Text strong>
          Dimohon untuk mengecek kembali validitas berita yang akan anda publikasikan.
        </Text>
      </p>
      <Text strong>
        Tim kami akan mengecek surat atau artikel anda. Apabila telah approve, artikel anda akan
        segera dipublikasikan.
      </Text>

      <Row css={generateMargin({ type: 'top', value: '40px' })}>
        <Col span={24}>
          <Form css={styles.form} onFinish={onFinish} autoComplete="off" layout="vertical">
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Form.Item label="Judul">
                  <Input
                    placeholder="Masukkan judul berita..."
                    value={newsData?.title}
                    onChange={(e) => {
                      handleOnChange(e.target.value, 'title');
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Ditujukan ke">
                  <Select
                    showSearch
                    placeholder="Pilih pihak yang dituju"
                    onSelect={(_, opt) => handleOnChange(opt?.key, 'for_user')}>
                    {offUser?.map((usr) => (
                      <Option key={usr?.id} value={usr?.name}>
                        {usr?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Gambar Pendukung">
                  <Upload.Dragger
                    fileList={banners}
                    listType="picture"
                    accept="jpg,jpeg,png"
                    maxCount={5}
                    beforeUpload={handleBeforeUpload}
                    onChange={handleImageUploader}
                    onRemove={handleImageUploaderRemove}
                    style={{ width: '550px', maxWidth: '550px' }}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Upload gambar (Max 1MB)</p>
                    <p className="ant-upload-hint">
                      Format .jpg,.jpeg,.png
                      <br />
                      dimensi yang direkomendasikan 653 x 416px atau 1306 x 832px
                      <br />
                      Minimum 1 gambar dan maksimum 5 gambar
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
              <Col span={24}>
                {Boolean(banners?.length) && (
                  <Carousel autoplay dotPosition="bottom" style={{ width: 653 }}>
                    {banners?.map((img, index) => (
                      <div key={index}>
                        <DynamicImage width={653} height={416} src={img?.url} />
                      </div>
                    ))}
                  </Carousel>
                )}
              </Col>
              <Col span={24}>
                <Form.Item label="Isi Surat">
                  <ReactQuill
                    value={newsData.content}
                    onChange={(val) => handleOnChange(val, 'content')}
                    modules={{
                      toolbar: {
                        container: [
                          [{ header: [1, 2, 3, 4, false] }],
                          ['bold', 'underline'],
                          [{ list: 'ordered' }, { list: 'bullet' }]
                        ]
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" shape="round" block>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </TextCard>
  );
}

function UploadLetterInfo() {
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  return (
    <TextCard>
      <header>Publikasikan surat & artikel anda</header>
      <p css={styles.secondaryTitle}>
        Mohon dibaca dengan baik dan teliti sebelum menulis surat & artikel di kabelins.com
      </p>
      <br />
      <p>
        Saat Anda mengirim Surat atau artikel, Anda dianggap telah membaca dan menyetujui semua
        &nbsp;
        <Link onClick={() => navigate('/syarat-dan-ketentuan')} target="_self" underline>
          syarat dan ketentuan
        </Link>
        &nbsp; di situs ini, termasuk namun tidak terbatas, ketentuan berikut:
      </p>
      <br />
      <p>1. Surat atau Artikel hanya akan diterbitkan jika dianggap layak oleh redaksi.</p>
      <br />
      <p>
        2. Surat atau Artikel hanya akan diterbitkan jika penulis mengirimkan foto identitas ke
        email redaksi. Foto identitas hanya untuk kepentingan redaksi dan tidak akan dibagikan
        kepada pihak manapun sesuai dengan kebljakan privasi kami.
      </p>
      <br />
      <p>
        3. Surat atau Artikel yang telah terbit TIDAK DAPAT DISUNTING dan TIDAK DAPAT DIHAPUS OLEH
        PENULIS. Surat atau artikel saat dimuat telah bersifat milik publik dan bisa diakses secara
        terbuka oleh semua pengguna internet. Penyuntingan atau penghapusan surat atau artikel yang
        sudah diterbitkan diputuskan sepenuhnya oleh redaksi.
      </p>
      <div css={styles.info}>
        <p>
          <Text type="danger" strong>
            PENTING !{' '}
          </Text>
          Jika anda TIDAK MENYETUJUI&nbsp;
          <Link onClick={() => navigate('/syarat-dan-ketentuan')} target="_self" underline>
            syarat dan ketentuan
          </Link>
          &nbsp; di situs ini, mohon untuk tidak mengirimkan surat atau artikel ke situs ini
        </p>
        <p>Jika Anda menyetujui, silakan lanjutkan dengan menekan tombol di bawah:</p>
      </div>
      <Button shape="round" type="primary" onClick={() => navigate('/login')}>
        Masuk
      </Button>
    </TextCard>
  );
}

export default function UploadLetter() {
  const { authenticated } = useAuth();
  return authenticated ? <UploadLetterForm /> : <UploadLetterInfo />;
}
