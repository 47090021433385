/** @jsxImportSource @emotion/react */

import { Button } from 'antd';
import Card from 'components/Card';
import { Card as ANTDCard } from 'antd';
import { useState } from 'react';
import { generateMargin, inline } from 'styles/utils';

import useGlobal from 'contexts/global';

import getStyles from './styles';

const initialState = {
  id: '3',
  imageUrl: 'https://www.fast.or.id/wp-content/uploads/2020/07/placeholder-2.png',
  link: 'www.kabelins.com'
};

export default function YouTubeAds() {
  const [youTubeData] = useState(initialState);
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  return (
    <Card
      style={styles.card}
      cover={
        <img
          src={youTubeData.imageUrl}
          width="100%"
          height="100%"
          css={styles.youTubeImage}
          alt="Youtube Previer Image"
        />
      }>
      <div css={[inline, generateMargin({ type: 'top', value: '16px' })]}>
        <ANTDCard.Meta
          avatar={
            <img
              src="youtube-icon.png"
              width="100%"
              height="100%"
              css={styles.youtubeIcon}
              alt="Youtube Icon"
            />
          }
          description={
            <>
              <p css={styles.title}>Channel Youtube Kami</p>
              <p css={styles.description}>
                Kami membuat video agar kamu tau apa yang terjadi di sekelilingmu
              </p>
              <Button type="primary" shape="round" size="medium" css={styles.youtubeButton}>
                Lihat Channel
              </Button>
            </>
          }
        />
      </div>
    </Card>
  );
}
