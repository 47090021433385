import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    header {
      font-size: ${isMobile ? 0.875 : 1}rem;
      font-weight: 600;
      padding-bottom: 13px;
      border-bottom: 1px solid ${colors.neutral};
    }
    section {
      margin-top: 20px;
      width: 100%;
      position: relative;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
    }
    .ant-tabs-tab {
      font-size: ${isMobile ? 0.75 : 0.875}rem!important;
    }
  `,
  text: css`
    color: ${colors.tertiaryGrey};
    font-size: 0.625rem;
    font-weight: 400;
    width: max-content;
    margin-top: 9px;
  `,
  buttonContainer: css`
    justify-content: center;
    display: flex;
    margin-left: ${isMobile ? '8px' : '0'};
  `,
  opinionTextContainer: css`
    gap: ${isMobile ? '16' : '16'}px;
    padding: ${isMobile ? '0' : '16px 16px'};
    font-size: ${isMobile ? '0.625' : '0.75'}rem;
    display: flex;
    flex-direction: column;
  `,
  labelText: css`
    font-weight: 600;
    margin-bottom: 6px;
  `,
  arrowBack: css`
    color: ${colors.primaryBlue};
    margin-right: 14px;
    font-size: 1.1rem;
  `
});
