import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    width: 100%;
    height: max-fit-content;
    padding: 24px;

    font-weight: 400;
    font-size: 0.75rem;
    line-height: 14.63px;
    width: 100%;

    header {
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 22px;
    }
  `,
  secondaryTitle: css`
    font-weight: 500;
    font-size: 0.75rem;
    margin-top: -15px;
    color: ${colors.secondaryGrey};
  `,
  section: css`
    margin: 0 auto;

    textarea {
      font-size: 0.75rem;
      border-radius: 6px;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      font-weight: 400;
      font-size: 0.75rem !important;
      line-height: 14.63px;
    }

    .ant-collapse-ghost > .ant-collapse-item {
      border: 1px solid ${colors.tertiaryGrey};
      border-radius: 6px;
      margin-bottom: 22px;
    }
  `,
  sectionTitle: css`
    font-weight: 700;
    font-size: 1rem;
  `,
  collapseHeader: css`
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 0.75rem;
    line-height: 14.63px;

    > div {
      flex: 1;
      width: 149px;
    }
    p {
      margin-bottom: 0;
      font-weight: 500;
    }
  `,
  fileUploadInfo: css`
    color: ${colors.tertiaryGrey};
    margin-left: 20px;
    font-size: 0.75rem;
  `,
  buttonText: css`
    font-size: 0.75rem;
    font-weight: 500;
  `,
  info: css`
    font-size: 0.688rem;
    font-weight: 500;
    color: ${colors.secondaryGrey};
  `,
  adsCardContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin: 54px 20px;
  `,
  adsCard: css`
    &[data-size='large'] {
      width: 210px;
      height: 230px;
    }

    &[data-size='medium'] {
      width: 190px;
      height: 200px;
    }

    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;

    border-radius: 10px;
    position: relative;
  `,
  text: css`
    white-space: break-spaces !important;
    &[data-weight='bold'] {
      font-weight: 700;
    }

    &[data-weight='medium'] {
      font-weight: 500;
    }

    &[data-size='large'] {
      font-size: 1.25rem;
    }

    &[data-size='medium'] {
      font-size: 1rem;
    }

    &[data-size='regular'] {
      font-size: 0.75rem;
    }

    &[data-size='small'] {
      font-size: 0.563rem;
    }
  `,
  cardDivider: css`
    min-width: 26px;
    width: 26px;
    text-align: center;
    border-top: 1px solid ${colors.tertiaryGrey};
    margin-top: 12px;
  `,
  saveText: css`
    color: ${colors.primaryBlue};
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0 !important;
  `,
  badge: css`
    color: ${colors.white};
    font-size: 0.875rem;
    font-weight: 700;
    font-style: italic;
    background-color: ${colors.primaryBlue};
    padding: 7px 30px;

    position: absolute;
    top: 0;
    border-radius: 0px 0px 11.1149px 11.1149px;
  `,

  swiper: css`
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
};
