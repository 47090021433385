/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import VerticalCard from 'components/NewsCard/VerticalCard';

export default function NewsDetails({ data, isLoading }) {
  return (
    <VerticalCard.WithDetails
      isLoading={isLoading}
      data={data}
      config={{
        withReadMoreButton: false,
        withCarousel: true
      }}
    />
  );
}
