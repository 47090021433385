import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  secondaryTitle: css`
    font-weight: 500;
    font-size: 0.688 rem;
    margin-top: -5px;
    color: ${colors.secondaryGrey};
  `,
  cardHeader: css`
    margin-bottom: 22px;
    border-radius: 13px;

    .ant-card-body {
      padding: 18px 0;
    }

    div {
      margin: 0;
    }

    p {
      margin-bottom: 0;
      font-size: 0.625rem;
    }

    header {
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 6px;
    }
  `,
  cardSection: css`
    margin-top: 24px;
    border-radius: 13px;

    .ant-card-body {
      padding: 6px 12px;
    }
  `,
  section: css`
    width: 100%;
    margin: 0 auto;

    textarea {
      font-size: 0.75rem;
      border-radius: 6px;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      font-weight: 400;
      font-size: 0.688rem !important;
      line-height: 13.89 px;
    }

    .ant-collapse-ghost > .ant-collapse-item {
      border: 1px solid ${colors.tertiaryGrey};
      border-radius: 6px;
      margin-bottom: 13px;
    }
  `,
  sectionTitle: css`
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 8px;
  `,
  collapseHeader: css`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 13.89px;

    > div {
      flex: 1;
      width: 77px;
    }
    p {
      margin-bottom: 0;
      font-weight: 500;
    }
  `,
  fileUploadInfo: css`
    color: ${colors.tertiaryGrey};
    margin-left: 20px;
    font-size: 0.688rem;
  `,
  buttonText: css`
    font-size: 0.688rem;
    font-weight: 500;
  `,
  info: css`
    font-size: 0.688rem;
    font-weight: 500;
    color: ${colors.secondaryGrey};
  `,
  adsCard: (isChoosen = false) => css`
    &[data-size='large'] {
      width: 258px;
    }

    &[data-size='medium'] {
      width: 231px;
    }

    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 275px;
    ${isChoosen && `background: rgba(64, 169, 255, .2);`}

    border-radius: 10px;
    position: relative;

    &:hover,
    &:focus !importants {
      ${isChoosen && `background: rgba(64, 169, 255, .2) !important;`}
    }
  `,
  text: css`
    &[data-weight='bold'] {
      font-weight: 700;
    }

    &[data-weight='medium'] {
      font-weight: 500;
    }

    &[data-size='large'] {
      font-size: 1.625rem;
    }

    &[data-size='medium'] {
      font-size: 1.25rem;
    }

    &[data-size='regular'] {
      font-size: 0.75rem;
    }

    &[data-size='small'] {
      font-size: 0.563rem;
    }
  `,
  cardDivider: css`
    min-width: 26px;
    width: 26px;
    text-align: center;
    border-top: 1px solid ${colors.tertiaryGrey};
  `,
  saveText: css`
    color: ${colors.primaryBlue};
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0 !important;
    margin-top: 20px;
  `,
  badge: css`
    color: ${colors.white};
    font-size: 0.875rem;
    font-weight: 700;
    font-style: italic;
    background-color: ${colors.primaryBlue};
    padding: 7px 30px;

    position: absolute;
    top: 0;
    border-radius: 0px 0px 11.1149px 11.1149px;
  `,

  swiper: css`
    padding: 0 16px;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
};
