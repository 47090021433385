/** @jsxImportSource @emotion/react */
import { Tag } from 'antd';
import { memo } from 'react';
import getStyles from './styles';
import useGlobal from 'contexts/global';

export default memo(function CategoryBadge({ color = 'blue', title = 'Berita', style, ...props }) {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  return (
    <Tag css={[styles.container, style]} color={color} {...props}>
      {title}
    </Tag>
  );
});
