import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    width: 100%;
  `,
  headerText: css`
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0;
  `,
  secondaryHeaderText: css`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  `,
  card: css`
    padding: 24px;
    width: 100%;
    max-width: ${isMobile ? '100%' : '358px'};

    form {
      font-size: 0.875rem;
      font-weight: 400;

      label::before {
        display: none !important;
      }

      .ant-form-item-explain-error {
        width: ${isMobile ? '100%' : '225px'};
        font-size: 0.65rem;
        margin-top: 5px;
      }

      .ant-form-item {
        margin-bottom: 0;

        :nth-of-type(1) {
          margin-bottom: 10px;
        }
      }
    }
  `,
  input: css`
    width: ${isMobile ? '100%' : '310px'};

    height: 33px;

    box-sizing: border-box;
    border-radius: 20px;
  `,
  forgetPassword: css`
    font-weight: 600;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    float: right;
  `,
  submitButton: css`
    width: 100%;

    button {
      width: 100%;
      height: 41px;
      font-weight: 600;
    }
  `,
  registerText: css`
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;

    margin-top: 17px;
    margin-bottom: 0;

    span {
      cursor: pointer;
      color: ${colors.primaryBlue};
    }
  `,
  divider: css`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    span {
      padding: 0 10px;
      font-size: 0.75rem;
    }
  `
});
