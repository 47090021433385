import { css } from '@emotion/react';

export default ({ width, height }) => {
  return {
    container: css`
      height: ${height};
      width: ${width};
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    `
  };
};
