/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react';
import { Comment, Avatar, Form, Button, List, Input, Tooltip, notification } from 'antd';
import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import moment from 'moment';

import HeaderTitle from 'components/HeaderTitle';
import Card from 'components/Card';

import useAuth from 'contexts/auth';
import { checkIsMobile } from 'utils/isMobile';
import newsAPI from 'APIs/news';

import { generateMargin } from 'styles/utils';
import getStyles from './styles';

const isMobile = checkIsMobile();
const styles = getStyles({ isMobile });

const { TextArea } = Input;

function CommentListComponent({
  datetime,
  author,
  avatar,
  content,
  likes = 0,
  dislikes = 0,
  id,
  newsId,
  diplayPicture
}) {
  const [iconAction, setState] = useState({ likes, dislikes, action: '' });
  const { authenticated } = useAuth();

  const handleLike = () => {
    if (!authenticated) {
      notification.info({
        message: 'Anda harus login terlebih dahulu untuk melakukan aksi tersebut'
      });
      return;
    }

    if (iconAction.action === 'liked') return;

    setState((prev) => ({
      ...prev,
      likes: Number(prev.likes) + 1,
      dislikes,
      action: 'liked'
    }));

    newsAPI.likeOrDislikComment(newsId, id, 1);
  };

  const handleDislike = () => {
    if (!authenticated) {
      notification.info({
        message: 'Anda harus login terlebih dahulu untuk melakukan aksi tersebut'
      });
      return;
    }

    if (iconAction.action === 'disliked') return;

    setState((prev) => ({
      ...prev,
      likes,
      dislikes: Number(prev.dislikes) + 1,
      action: 'disliked'
    }));

    newsAPI.likeOrDislikComment(newsId, id, -1);
  };

  const actions = [
    <span key="comment-basic-like" css={styles.sosmedInfo}>
      <div onClick={handleLike} data-state={iconAction.action === 'liked' ? 'liked' : ''}>
        {iconAction.action === 'liked' ? <LikeFilled /> : <LikeOutlined />}
        {iconAction.likes}
      </div>
    </span>,
    <span
      key="comment-basic-dislike"
      css={[generateMargin({ type: 'left', value: '37px' }), styles.sosmedInfo]}>
      <div onClick={handleDislike} data-state={iconAction.action === 'disliked' ? 'disliked' : ''}>
        {iconAction.action === 'disliked' ? <DislikeFilled /> : <DislikeOutlined />}
        {iconAction.dislikes}
      </div>
    </span>
  ];

  return (
    <Comment
      // actions={actions}
      css={styles.commentContainer}
      datetime={
        <Tooltip css={styles.date}>
          {/* <span>{moment(datetime).format('YYYY-MM-DD')}</span> */}
        </Tooltip>
      }
      author={
        <span css={styles.commentWriter}>
          {author} <div css={styles.dot} />
        </span>
      }
      avatar={
        <Avatar alt="profile picture" size={isMobile ? 25 : 44} src={diplayPicture}>
          {avatar}
        </Avatar>
      }
      content={<p css={styles.commentContent}>{content}</p>}
    />
  );
}

function CommentList({ comments }) {
  return (
    <List
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={(props) => {
        const newprops = {
          author: props?.author ?? 'Anonymous',
          avatar: props?.author?.charAt(0) ?? 'A',
          content: props.comment,
          datetime: props.created_at,
          newsId: props?.news_id,
          id: props?.id,
          likes: props?.total_like,
          dislikes: props?.total_dislike,
          diplayPicture: props?.author_picture
        };

        return <CommentListComponent {...newprops} />;
      }}
    />
  );
}

function Editor({ onChange, onSubmit, loading, value }) {
  return (
    <>
      <Form.Item>
        <TextArea rows={4} onChange={onChange} value={value} />
      </Form.Item>
      <div css={styles.buttonContainer}>
        <Button htmlType="submit" shape="round" loading={loading} onClick={onSubmit} type="primary">
          Post
        </Button>
      </div>
    </>
  );
}

const initialState = {
  loading: false,
  value: '',
  comments: []
};

export default function CommentComponent({ data = [], newsId }) {
  const [{ loading, comments, value }, setState] = useState(initialState);
  const { currentUser } = useAuth();
  const { isMobile } = useAuth();

  useEffect(() => {
    setState((prev) => ({ ...prev, comments: data }));
  }, [data, currentUser]);

  const handleSubmit = async () => {
    if (!value) {
      return;
    }

    setState((prev) => ({
      ...prev,
      loading: true
    }));

    newsAPI.addComment(newsId, value);

    setState((prev) => ({
      ...prev,
      loading: false,
      value: '',
      comments: [
        {
          author: currentUser?.name,
          initial: currentUser?.name?.charAt(0),
          comment: prev.value,
          created_at: `${moment().fromNow()}`,
          author_picture: currentUser?.profile_picture
        },
        ...prev.comments
      ]
    }));
  };

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      value: e.target.value
    }));
  };

  if (comments?.length === 0 && !currentUser?.name) return <></>;

  return (
    <>
      <HeaderTitle primaryText={`${comments.length} komentar`} />
      <Card style={styles.card} id="detailsComment">
        {currentUser?.name && (
          <Comment
            avatar={
              <Avatar
                alt="profile picture"
                size={isMobile ? 25 : 44}
                src={currentUser?.profile_picture}>
                {currentUser?.name?.charAt(0)}
              </Avatar>
            }
            content={
              <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={loading}
                value={value}
              />
            }
          />
        )}
        {comments?.length > 0 && <CommentList comments={comments} />}
      </Card>
    </>
  );
}
