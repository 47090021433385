import { axiosHttpRequest } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';

export const doUpdateUserData = async (payload) => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    url: '/users/end/update',
    body: payload,
    withAuth: true
  });

  return { data: data?.data?.data, error };
};

export const doUpdateUserProfile = async (payload) => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    url: '/users/end/update/profile',
    body: payload,
    withAuth: true
  });

  return { data: data?.data?.data, error };
};

export const doUpdateUserKTP = async (payload) => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    url: '/users/end/update/ktp',
    body: payload,
    withAuth: true
  });

  return { data: data?.data?.data, error };
};

export const doChangePassword = async (payload) => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    url: '/users/end/change-password',
    body: payload,
    withAuth: true
  });

  return { data: data?.data?.data, error };
};

export const getOfficialUserList = async () => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.GET,
    url: '/users/end/get-official-users',
    withAuth: true
  });

  return { data: data?.data?.data, error };
};
