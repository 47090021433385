/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Form, Button, Input, Row, Col, notification, message } from 'antd';
import { useParams } from 'react-router-dom';
import useGlobal from 'contexts/global';

import getStyles from './styles';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import newsAPI from 'APIs/news';

export default function WriteFeedback() {
  const { id } = useParams();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const [parentNews, setParentNews] = useState({});
  const [newsData, setNewsData] = useState({
    title: '',
    content: ''
  });
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    newsAPI.getSuratById(id).then((res) => {
      setParentNews(res?.data);
    });
  }, []);

  // check for update
  useEffect(() => {
    newsAPI.getTanggapanByParentId(id).then((res) => {
      if (res?.data?.title) {
        setNewsData({
          curr_id: res?.data?.id,
          title: res?.data?.title,
          content: res?.data?.content
        });
        setIsUpdate(true);
      }
    });
  }, []);

  function handleOnChange(value, key) {
    setNewsData((prev) => ({ ...prev, [key]: value }));
  }

  const onFinish = () => {
    const payload = {
      ...newsData,
      news_id: id
    };

    if (isUpdate) {
      newsAPI
        .updateTanggapan(payload, payload?.curr_id)
        .then((res) => {
          message.success('Berhasil mengupdate tanggapan');
          setTimeout(() => {
            window.location.href = '/pengaturan-akun/tanggapan-saya';
          }, 1500);
        })
        .catch(() => {
          message.error('Gagal mengupdate tanggapan, silahkan coba kembali beberapa saat');
        });
    } else {
      newsAPI
        .createTanggapan(payload)
        .then((res) => {
          message.success('Berhasil mensubmit tanggapan');
          setTimeout(() => {
            window.location.href = '/pengaturan-akun/surat-pengirim';
          }, 1500);
        })
        .catch(() => {
          message.error('Gagal mensubmit tanggapan, silahkan coba kembali beberapa saat');
        });
    }
  };

  return (
    <div css={styles.container}>
      <header>Tulis Tanggapan</header>
      <section>
        <div style={{ marginBottom: '16px' }}>
          <div style={{ fontWeight: 'bold' }}>Menanggapi Laporan:</div>
          <div>
            <a href={`/berita/${parentNews?.slug}`} rel="noopenner">
              {parentNews?.title}
            </a>
          </div>
        </div>
        <Form onFinish={onFinish} autoComplete="off" layout="vertical">
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Form.Item label="Judul">
                <Input
                  placeholder="Masukkan judul berita..."
                  value={newsData?.title}
                  onChange={(e) => {
                    handleOnChange(e.target.value, 'title');
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Isi Tanggapan">
                <ReactQuill
                  value={newsData?.content}
                  onChange={(val) => handleOnChange(val, 'content')}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: [1, 2, 3, 4, false] }],
                        ['bold', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }]
                      ]
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" shape="round" block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </section>
    </div>
  );
}
