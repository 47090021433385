import { axiosHttpRequest } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';

export const getGalleryUser = async () => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.GET,
    url: '/medias/enduser/view',
    withAuth: true
  });

  return { data: data?.data?.data, error };
};

export const addGalleryUser = async (payload) => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    withAuth: true,
    body: payload,
    url: '/medias/enduser/add'
  });

  return { data: data?.data?.data, error };
};

export const deleteGalleryUser = async (id) => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    withAuth: true,
    url: `/medias/enduser/delete/${id}`
  });

  return { data: data?.data?.data, error };
};
