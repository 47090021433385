/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';
import { Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

export default function CommunityGuideline() {
  return (
    <TextCard>
      <header>Panduan Komunitas</header>
      <Title level={4}>Selamat datang di kabelins.com</Title>
      <Title level={5}>Bagaimana caranya mendaftarkan diri menjadi anggota kabelins.com?</Title>
      <Paragraph>
        Mendaftarkan diri menjadi anggota website kabelins.com dapat dilakukan dihalaman registrasi
        yang dapat di akses melalui tombol <Text strong>Daftar</Text> yang terletak dibagian kanan
        atas dari website kabelins.com. Dengan melengkapi data diri seperti nama, nomor telepon,
        email, dan password kamu sudah bisa langsung menjadi anggota.
      </Paragraph>
      <Title level={5}>Ada apa saja fitur yang disediakan oleh kabelins.com?</Title>
      <Paragraph>
        Fitur yang disediakan untuk user yang belum terverifikasi berupa mengedit profil, mengganti
        password, dan membeli iklan.
        <br />
        <br />
        Sedangkan untuk user yang sudah terverifikasi maka akan mendapatkan tambahan akseske fitur:
        <div style={{ margin: '12px 0 12px 18px' }}>
          - Mengirimkan surat pembaca yang dimana akan ditanggapi oleh pihak-pihak berwenang yang
          sudah bekerja sama dengan kabelins.com
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          - Mengirimkan Artikel Opini, dimana anda dapat mengirimkan artikel yang berisikan opini
          anda terhadap sesuatu kejadian ataupun keadaan dan yang dimana isi dari artikelnya tidak
          melanggar norma-norma ataupun hukum yang berlaku.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          - Mengirimkan Gallery, dimana anda dapat mengirimkan gambar-gambar di sekitar anda yang
          menurut anda menarik ataupun dari sebuah kejadian yang terjadi di dekat anda.
        </div>
      </Paragraph>

      <Title level={5}>Bagaimana menjadi user yang terverifikasi?</Title>
      <Paragraph>
        Untuk melakukan verifikasi, pengguna dapat langsung melakukan upload foto KTP dengan format
        jpg/jpeg/png dan maksimum ukuran file sebesar 1 MB. Setelah melakukan upload bukti KTP maka
        data anda akan direview oleh pihak admin kabelins.com dan jika data yang dilampirkan tidak
        ditemukannya pelanggaran hukum maka akun anda akan segera berubah menjadi akun verifikasi
        dan bisa langsung menikmati fitur tambahan.
      </Paragraph>
    </TextCard>
  );
}
