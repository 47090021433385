import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { WEB_ROUTES } from 'constants/routes';
import { AuthProvider } from 'contexts/auth';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

import LoginRegisterPage from 'pages/LoginRegister';
import LoginForm from 'components/Form/Login';
import RegisterForm from 'components/Form/Register';
import NotFound from 'pages/NotFound';
import { GlobalProvider } from 'contexts/global';

const Main = () => (
  <>
    <Navbar />
    <Routes>
      {WEB_ROUTES.map(({ path, childrens, ...rest }) => (
        <Route key={path} path={path} {...rest}>
          {childrens &&
            childrens.map((childRoute) => <Route key={childRoute.path} {...childRoute} />)}
        </Route>
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </>
);

function App() {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <AuthProvider>
          <Routes>
            <Route
              exact
              path="/register"
              element={
                <LoginRegisterPage>
                  <RegisterForm />
                </LoginRegisterPage>
              }
            />
            <Route
              exact
              path="/login"
              element={
                <LoginRegisterPage>
                  <LoginForm />
                </LoginRegisterPage>
              }
            />
            <Route path="/*" element={<Main />} />
          </Routes>
        </AuthProvider>
      </GlobalProvider>
    </BrowserRouter>
  );
}

export default App;
