export default {
  neutral: '#F6F8FA',
  black: '#000000',
  white: '#FFFFFF',

  primaryGrey: '#333333',
  secondaryGrey: '#767676',
  tertiaryGrey: '#C4C4C4',

  primaryBlue: '#3F8FE7',
  secondaryBlue: 'rgba(63, 143, 231, 0.1)',

  primaryRed: '#DB504A',
  secondaryRed: 'rgba(219, 80, 74, 0.1)',

  primaryYellow: '#FAAD14',
  secondaryYellow: '#FF961B',
  tertiaryYellow: 'rgba(255, 150, 27, 0.1)',

  primaryPink: '#DB4ABB',
  secondaryPink: 'rgba(219, 74, 187, 0.1)',

  primaryPurple: '#5843BE',
  secondaryPurple: 'rgba(88, 67, 190, 0.1)',

  primaryGreen: '#66AD20',
  secondaryGreen: 'rgba(102, 173, 32, 0.1)',

  test: 'test'
};
