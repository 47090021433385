/** @jsxImportSource @emotion/react */

import { Form, Input, Button, Divider, Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import useGlobal from 'contexts/global';
import useAuth from 'contexts/auth';
import regex from 'constants/regex';
import authAPI from 'APIs/auth';

import Card from 'components/Card';

import getStyles from './styles';
import { useState } from 'react';

export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  const [isModalVisibile, setModalVisibility] = useState(false);

  const [form] = Form.useForm();
  const [resetPasswordForm] = Form.useForm();

  const handleResetPasswordForm = async () => {
    resetPasswordForm.validateFields();

    const email = resetPasswordForm.getFieldValue('resetPasswordEmail');
    const { error } = await authAPI.resetPassword(email);

    if (error) {
      notification.error({
        message: 'Tolong pastikan E-mail yang Anda masukan sudah benar'
      });
    } else {
      notification.success({
        message: 'Silahkan cek email Anda untuk dapatkan password baru'
      });
      resetPasswordForm.resetFields();
      setModalVisibility(false);
    }
  };

  return (
    <>
      <div css={styles.container}>
        <p css={styles.headerText}>Mari mulai</p>
        <p css={styles.secondaryHeaderText}>Dapatkan berita terbaru disekitar kamu</p>
        <Card css={styles.card}>
          <Form form={form} name="register" layout="vertical" onFinish={login}>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'E-mail yang Anda masukkan tidak valid!'
                },
                {
                  required: true,
                  message: 'Tolong masukkan E-mail Anda!'
                }
              ]}>
              <Input type="email" name="email" css={styles.input} placeholder="Masukkan email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Tolong masukkan password Anda!'
                },
                {
                  pattern: regex.password,
                  message:
                    'Pastikan password kamu terdiri dari minimal 8 karakter alphanumerik dan 1 huruf besar'
                }
              ]}
              hasFeedback>
              <Input.Password
                type="password"
                name="password"
                css={styles.input}
                placeholder="Masukkan password"
                autoComplete="true"
                minLength={8}
              />
            </Form.Item>
            <Button
              type="link"
              shape="round"
              css={styles.forgetPassword}
              onClick={() => setModalVisibility(true)}>
              Lupa Password?
            </Button>
            <Form.Item css={styles.submitButton}>
              <Button type="primary" shape="round" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
          <p css={styles.registerText}>
            Belum punya akun? <span onClick={() => navigate('/register')}>Daftar sekarang</span>
          </p>
          {isMobile && (
            <>
              <div css={styles.divider}>
                <Divider />
                <span>Atau</span>
                <Divider />
              </div>
              <div css={styles.submitButton}>
                <Button type="primary" ghost shape="round" onClick={() => navigate('/')}>
                  Lanjutkan sebagai tamu
                </Button>
              </div>
            </>
          )}
        </Card>
      </div>
      <Form form={resetPasswordForm}>
        <Modal
          title="Atur ulang kata sandi"
          centered
          visible={isModalVisibile}
          onOk={handleResetPasswordForm}
          onCancel={() => setModalVisibility(false)}>
          <p>Masukkan e-mail yang terdaftar. Kami akan mengirimkan password baru untuk Anda</p>
          <Form.Item
            name="resetPasswordEmail"
            rules={[
              {
                type: 'email',
                message: 'E-mail yang Anda masukkan tidak valid!'
              },
              {
                required: true,
                message: 'Tolong masukkan E-mail Anda!'
              }
            ]}>
            <Input type="email" placeholder="Masukkan email" required />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
}
