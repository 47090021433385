import { axiosHttpRequest } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';

export const getConsultants = async () => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.GET,
    url: '/consultants'
  });

  return { data: data?.data?.data, error };
};
