import { axiosHttpRequest } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';

export const getActiveAds = async () => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.GET,
    url: '/billboards/active'
  });

  return { data: data?.data?.data, error };
};

export const getAdsPrice = async () => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.GET,
    url: '/billboards/prices'
  });

  return { data: data?.data?.data, error };
};

export const doBuyAds = async (payload) => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    url: '/billboards/buy',
    withAuth: true,
    body: payload
  });

  return { data: data?.data?.data, error };
};
