import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    position: sticky;
    top: 0;
    z-index: 99;
  `,
  navbarHeader: css`
    background-color: ${colors.primaryGrey};
    color: ${colors.white};
    height: 37px;
    font-size: 0.75rem;
    font-weight: 500;
    align-items: center;
  `,
  iconButton: css`
    cursor: pointer;
    color: ${colors.white};
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: 40px;
  `,
  navbarContent: css`
    height: 137px;
    background-color: ${colors.white};
  `,
  searchContainer: css`
    height: 43px;
    margin-right: 13px;
  `,
  searchInput: css`
    height: 43px;
    border-radius: 10em;
    border-color: ${colors.tertiaryGrey} !important;
  `,
  colorGrey: css`
    color: rgba(0, 0, 0, 0.85);
  `,
  logo: css`
    cursor: pointer;
  `,
  tabNavbar: css`
    align-items: baseline;

    * > .ant-tabs-nav-operations {
      display: none !important;
    }
  `,
  profileButton: css`
    border-radius: 10em;
    height: 43px;
    border-color: ${colors.tertiaryGrey} !important;

    display: flex;
    justify-content: center;
    align-items: center;

    span:nth-of-type(even) {
      margin-right: 9.21px;
      margin-left: 9.21px;
    }
    span {
      font-size: 0.875rem;
      font-weight: 500;
    }

    .ant-comment-avatar {
      img {
        width: inherit;
        height: inherit;
      }
    }
  `,
  button: css`
    border-radius: 10em;
    height: 43px;
    span {
      font-size: 0.875rem;
      font-weight: 500;
    }
  `,
  uploadButton: css`
    border-radius: 10em;
    height: 43px;
    border-color: ${colors.tertiaryGrey} !important;
    span {
      font-size: 0.875rem;
      font-weight: 500;
    }
  `
};
