import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  button: isMobile
    ? css`
        font-size: 0.537rem !important;
        line-height: 11.91px !important;
        height: 17.41px !important;
      `
    : css``,
  card: css`
    width: 100%;
    height: ${isMobile ? 510 : 584}px;
    padding-top: ${isMobile ? 45 : 65}px;

    .ant-card-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      font-weight: 400;
      font-size: ${isMobile ? 0.813 : 0.875}rem;
      line-height: 22px;
      color: ${colors.secondaryGrey};

      p:first-of-type {
        font-weight: 600;
        color: ${colors.black};
        font-size: ${isMobile ? 0.876 : 1.5}rem;
        line-height: ${isMobile ? 18.33 : 32}px;
        margin-top: ${isMobile ? 13.5 : 25}px;
        margin-bottom: ${isMobile ? 2 : 4}px;
      }
    }
  `
});
