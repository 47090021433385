import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    header {
      font-size: 1rem;
      font-weight: 600;
      padding-bottom: 13px;
      border-bottom: 1px solid ${colors.neutral};
    }
    section {
      margin-top: 20px;
      width: 100%;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
    }
    table {
      font-size: 0.688rem;
    }

    table th {
      font-weight: 700;
      padding: 15px;
    }

    table td {
      padding: 8px;
    }
  `,
  text: css`
    color: ${colors.tertiaryGrey};
    font-size: 0.625rem;
    font-weight: 400;
    width: max-content;
    margin-top: 9px;
  `
};
