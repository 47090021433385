/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Skeleton } from 'antd';

import Card from 'components/Card';
import CategoryBadge from 'components/CategoryBadge';
import HeaderTitle from 'components/HeaderTitle';
import DynamicImage from 'components/DynamicImage';
import HorizontalCard from 'components/NewsCard/HorizontalCard';
import Empty from 'components/Empty';

import newsAPI from 'APIs/news';

import styles from './styles';
import { checkIsMobile } from 'utils/isMobile';
import { getBannersThumbnail } from 'utils/getBanners';

const isMobile = checkIsMobile();

const initialState = {
  isLoading: true,
  error: null,
  data: Array.from({ length: 4 })
};

export default function TrendingNews() {
  const navigate = useNavigate();
  const [{ data, isLoading }, setState] = useState(initialState);

  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await newsAPI.getTrending();

    setState((prev) => ({ ...prev, isLoading: false, data: data?.slice(0, 4), error }));
  }, []);

  const redirectToDetail = (slug) => {
    navigate(isLoading ? '/' : `/berita/${slug}`);
    window.scrollTo({ top: 0 });
  };

  const redirectToSearchResultPage = () => {
    navigate('/hasil-pencarian?title=');
    window.scrollTo({ top: 0 });
  };

  if (data?.length === 0 && !isLoading)
    return (
      <Empty
        width={isMobile ? '100%' : '396px'}
        height={isMobile ? '100%' : '525px'}
        title={<HeaderTitle primaryText={'Berita '} secondaryText={'Populer'} />}
        description="Berita terbaru tidak tersedia"
      />
    );

  return (
    <>
      <HeaderTitle
        primaryText={'Berita '}
        secondaryText={'Populer'}
        LeftComponent={() => (
          <Button type="link" css={styles.seeAllButton} onClick={redirectToSearchResultPage}>
            Lihat semua
          </Button>
        )}
      />
      {isMobile ? (
        <HorizontalCard.MobileCard
          data={data}
          isLoading={isLoading}
          onClick={redirectToDetail}
          config={{ displayDate: true }}
        />
      ) : (
        <Card style={styles.card}>
          {data?.map((news, idx) => (
            <div key={idx} css={styles.cardItem} onClick={() => redirectToDetail(news?.slug)}>
              <DynamicImage
                src={getBannersThumbnail(news?.banner_path)}
                alt={news?.title}
                width={139}
                height={97}
              />
              <div css={styles.cardDescription}>
                {isLoading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <>
                    <CategoryBadge color={news?.category?.color} title={news?.category?.name} />
                    <div css={styles.cardTitle}>{news?.title}</div>
                    <div css={styles.cardDate}>
                      {moment(news?.created_at).format('MMMM DD, YYYY')}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </Card>
      )}
    </>
  );
}
