/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import HeaderTitle from 'components/HeaderTitle';
import VerticalCard from 'components/NewsCard/VerticalCard';
import Empty from 'components/Empty';

import newsAPI from 'APIs/news';
import useGlobal from 'contexts/global';

import { generateMargin } from 'styles/utils';
import getStyles from './styles';

const initialState = {
  isLoading: false,
  error: null,
  data: Array.from({ length: 3 })
};

export default function RecentNews() {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  const navigate = useNavigate();
  const [{ data, isLoading }, setState] = useState(initialState);
  const swiper = useRef(null);

  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await newsAPI.getRecent();

    setState((prev) => ({ ...prev, data, isLoading: false, error }));
  }, []);

  const goNext = () => {
    swiper?.current?.slideNext();
  };

  const goPrev = () => {
    swiper?.current?.slidePrev();
  };

  const onCardClickedRedirect = (slug) => {
    navigate(isLoading ? '/' : `/berita/${slug}`);
    window.scrollTo({ top: 0 });
  };

  if (data?.length === 0 && !isLoading)
    return (
      <div css={styles.container}>
        <Empty
          description="Berita terbaru belum tersedia"
          title={<HeaderTitle primaryText="Berita " secondaryText="Terbaru" />}
        />
      </div>
    );

  return (
    <div css={styles.container}>
      <HeaderTitle
        primaryText="Berita "
        secondaryText="Terbaru"
        LeftComponent={() => (
          <div>
            <Button onClick={goPrev} icon={<LeftOutlined />} css={styles.carouselButton} />
            <Button onClick={goNext} icon={<RightOutlined />} css={styles.carouselButton} />
          </div>
        )}
      />
      <div css={styles.content}>
        {isMobile ? (
          data.map((news, idx) => (
            <div key={idx} css={generateMargin({ type: 'initial', value: '5px' })}>
              <VerticalCard.WithActionIcon
                data={news}
                isLoading={isLoading}
                onClick={() => onCardClickedRedirect(news?.slug)}
              />
            </div>
          ))
        ) : (
          <Swiper
            spaceBetween={15}
            slidesPerView={3}
            slidesPerGroup={3}
            loop={true}
            loopFillGroupWithBlank={true}
            modules={[Pagination, Navigation]}
            onInit={(swiperRef) => (swiper.current = swiperRef)}>
            {data?.map((news, idx) => (
              <SwiperSlide key={idx}>
                <div css={generateMargin({ type: 'initial', value: '5px' })}>
                  <VerticalCard.WithActionIcon
                    data={news}
                    isLoading={isLoading}
                    onClick={() => onCardClickedRedirect(news?.slug)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
