import { css } from '@emotion/react';

export default ({ isMobile }) => ({
  container: css`
    cursor: pointer;
    font-size: ${isMobile ? 0.626 : 0.75}rem;
    font-weight: 600;
    padding: ${isMobile ? '0px 11.9572px' : '5px 19px'};

    border-radius: ${isMobile ? 9.72329 : 15.45}px;
    border: none;
  `
});
