/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Dropdown, Form, Input, Menu } from 'antd';
import { SearchOutlined, PlusOutlined, MenuOutlined } from '@ant-design/icons';

import newsAPI from 'APIs/news';
import { mainMenu, otherMenuList, sidebarMenu } from 'constants/navbar';

import styles from './styles';
import { getPublicImagePath } from 'utils/getPublicImage';

import useAuth from 'contexts/auth';

const { SubMenu } = Menu;

const MenuList = ({ redirectPage }) => {
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState({
    main: mainMenu,
    list: [],
    lastMenu: []
  });
  const { logout, authenticated, currentUser } = useAuth();

  useEffect(async () => {
    const { data } = await newsAPI.getNewsCategories();

    if (data?.length) {
      const list = [];
      const lastMenu = [];
      const tempMain = menuList.main;

      data?.forEach((item) => {
        if (item.slug === 'kotak-surat') {
          return;
        }
        if (item.slug === 'surat-pengirim' || item.slug === 'tanggapan') {
          const idx = mainMenu.findIndex((nav) => nav.dataIndex === item.slug);
          tempMain[idx]['isActive'] = item.is_actives;
        } else {
          if (item.slug === 'gallery' || item.slug === 'konsultasi-hukum') {
            lastMenu.push(item);
          } else {
            list.push(item);
          }
        }
      });

      setMenuList({
        main: mainMenu,
        list,
        lastMenu
      });
    }
  }, []);

  return (
    <>
      <Menu css={styles.menu} mode="inline" onClick={redirectPage}>
        {authenticated && (
          <SubMenu
            key="account-settings"
            title={
              <div css={styles.avatarStyle}>
                <Avatar alt="profile picture" size={32.5} src={currentUser?.profile_picture}>
                  {currentUser?.name?.charAt(0)}
                </Avatar>
                <span>My Profile</span>
              </div>
            }>
            {sidebarMenu
              .filter((menu) => menu?.isMobileMenu)
              .map(({ slug, name, childrens, isVerifMenu }) => {
                if (childrens?.length > 0) {
                  return (
                    <>
                      {(Boolean(currentUser?.is_verified_user) === isVerifMenu ||
                        Boolean(currentUser?.is_verified_user)) && (
                        <SubMenu key={`"account-settings"-${name}`} title={name}>
                          {childrens.map(({ slug, name }) => (
                            <Menu.Item key={`pengaturan-akun/${slug}`}>{name}</Menu.Item>
                          ))}
                        </SubMenu>
                      )}
                    </>
                  );
                }
                return (
                  <>
                    {(Boolean(currentUser?.is_verified_user) === isVerifMenu ||
                      Boolean(currentUser?.is_verified_user)) && (
                      <Menu.Item key={`pengaturan-akun/${slug}`}>{name}</Menu.Item>
                    )}
                  </>
                );
              })}
          </SubMenu>
        )}
        {menuList?.main?.map(({ slug, name, tab }) => {
          if (tab) return;
          return <Menu.Item key={slug}>{name}</Menu.Item>;
        })}
        <SubMenu key="news" title="Berita">
          {menuList?.list?.map(({ name, slug }) => (
            <Menu.Item key={`kategori/${slug}`}>{name}</Menu.Item>
          ))}
        </SubMenu>
        {menuList?.lastMenu?.map(({ name, slug }) => (
          <Menu.Item key={`kategori/${slug}`}>{name}</Menu.Item>
        ))}
        <SubMenu key="others" title="Lainnya">
          {otherMenuList.map(({ slug, name }) => (
            <Menu.Item key={slug}>{name}</Menu.Item>
          ))}
        </SubMenu>
      </Menu>
      <div css={styles.buttonContainer}>
        {authenticated ? (
          <Button type="primary" ghost shape="round" onClick={logout} css={styles.button}>
            Keluar
          </Button>
        ) : (
          <>
            <Button
              type="primary"
              shape="round"
              onClick={() => navigate('/login')}
              css={styles.button}>
              Masuk
            </Button>
            <Button
              type="primary"
              ghost
              shape="round"
              onClick={() => navigate('/register')}
              css={styles.button}>
              Daftar
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default function NavbarMobile() {
  const navigate = useNavigate();
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [form] = Form.useForm();
  const { currentUser } = useAuth();

  const onSubmit = ({ search }) => {
    form.resetFields();

    if (!search || search?.length == 0) return;
    navigate(`hasil-pencarian?title=${search}`);
  };

  const onMenuClickedRedirect = (path) => {
    navigate(`/${path.key}`);
    setMenuVisibility(false);
  };

  const toggleMenuDropdown = () => {
    setMenuVisibility((prev) => !prev);
  };

  return (
    <div css={styles.container}>
      <div>
        <img
          src={getPublicImagePath('logo.jpg')}
          alt="kabelins logo"
          width={'auto'}
          height={35}
          css={styles.logo}
          onClick={() => {
            navigate('/');
            setMenuVisibility(false);
          }}
        />
        <div>
          {Boolean(currentUser?.is_verified_user) && (
            <Button
              type="primary"
              shape="round"
              size="small"
              icon={<PlusOutlined />}
              css={styles.uploadButton}
              onClick={() => {
                navigate('/upload-surat');
                setMenuVisibility(false);
              }}
              ghost>
              Upload Surat
            </Button>
          )}
          <Dropdown
            placement="bottomRight"
            visible={isMenuVisible}
            onClick={toggleMenuDropdown}
            overlay={<MenuList redirectPage={onMenuClickedRedirect} />}>
            <MenuOutlined css={styles.menuIcon} />
          </Dropdown>
        </div>
      </div>

      <Form onFinish={onSubmit} form={form} name="search-form">
        <Form.Item name="search">
          <Input
            placeholder="Cari judul berita"
            prefix={<SearchOutlined css={styles.colorGrey} fill="blue" />}
            type="search"
            css={styles.searchInput}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
