/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import styles from './styles';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { generateMargin } from 'styles/utils';
import newsAPI from 'APIs/news';
import { useEffect, useState } from 'react';
import { STATUS_NEWS } from 'constants/status';

export default function CheckMyLetterMobile() {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    newsAPI.viewSuratPengirim().then((res) => {
      setNewsData(res?.data);
    });
  }, []);

  return (
    <div css={styles.container}>
      <header>Cek Surat Saya</header>
      <section>
        {newsData.map((item) => (
          <Card key={item.key} css={styles.cardItemContainer}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <p>{item.title}</p>
              </Col>
              <Col span={24}>
                <div>Status: </div>
                <div style={{ color: STATUS_NEWS?.[item?.status]?.color }}>
                  {STATUS_NEWS?.[item?.status]?.label}
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]} css={generateMargin({ type: 'top', value: '21px' })}>
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="medium"
                  shape="round"
                  onClick={() => navigate(`/pengaturan-akun/surat-detail/${item?.id}`)}>
                  Lihat Tanggapan
                </Button>
              </Col>
            </Row>
          </Card>
        ))}
      </section>
    </div>
  );
}
