/** @jsxImportSource @emotion/react */

import { Col, Row } from 'antd';
import { section } from 'styles/layout';

import Card from 'components/Card';
import SettingSidebar from 'components/SettingSidebar';
import ProtectedRoute from 'components/ProtectedRoute';

import useGlobal from 'contexts/global';

import { generateMargin } from 'styles/utils';
import getStyles from './styles';

export default function AccountSetting({ children = null }) {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  return (
    <ProtectedRoute>
      <div
        css={[
          section,
          generateMargin({ type: 'top', value: '25px' }),
          generateMargin({ type: 'bottom', value: '44px' })
        ]}>
        <Row>
          <Col span={7.5}>
            <SettingSidebar />
          </Col>
          <Col span={isMobile ? 24 : 16}>
            <Card css={styles.content}>{children}</Card>
          </Col>
        </Row>
      </div>
    </ProtectedRoute>
  );
}
