/** @jsxImportSource @emotion/react */

import Card from 'components/Card';

import useGlobal from 'contexts/global';
import getStyles from './styles';
import { Empty as EmptyComponent } from 'antd';

export default function Empty({
  title = null,
  width,
  height,
  position = 'center',
  description = 'Berita tidak ditemukan, silahkan melakukan pencarian dengan kata kunci yang berbeda'
}) {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile, width, height });

  return (
    <div css={styles.container}>
      {title}
      <Card css={styles.emptyCard}>
        <EmptyComponent data-position={position} description={description} />
      </Card>
    </div>
  );
}
