import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  container: css`
    header {
      font-size: 0.875rem;
      font-weight: 600;
    }
    section {
      margin-top: 14px;
      width: 100%;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
      width: 100%;
    }
    .ant-card-body {
      padding: 18px 16px;
    }
    .ant-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ant-row span {
      font-size: 0.688rem;
      color: ${colors.secondaryGrey};
    }
    .ant-col-8 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  `,
  text: css`
    color: ${colors.tertiaryGrey};
    font-size: 0.625rem;
    font-weight: 400;
    width: max-content;
    margin-top: 9px;
  `,
  checklistStyle: css`
    color: ${colors.primaryGreen}!important;
    font-size: 1.25rem !important;
    font-weight: bolder !important;
  `,
  cardItemContainer: css`
    width: 100%;
    margin-bottom: 14px;
    border-radius: 15px;
  `
};
