import { message, notification } from 'antd';
import { createContext, useContext, useState, useEffect } from 'react';
import { getRefreshToken } from 'utils/auth';

import authAPI from 'APIs/auth';
import { useNavigate } from 'react-router-dom';

const authContext = createContext();

notification.config({
  duration: 2
});

function useAuth() {
  const navigate = useNavigate();
  const [authenticated, setAuthentication] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const fetchCurrentUser = async () => {
    const { data } = await authAPI.getCurrentUser();
    setCurrentUser(data?.data);
    setAuthentication(true);
  };

  useEffect(() => {
    const refreshTokenExisted = !!getRefreshToken();

    if (refreshTokenExisted) {
      fetchCurrentUser();
    }
  }, []);

  const login = async (body) => {
    try {
      const { data, error } = await authAPI.login(body);
      console.log(data, error);
      if (error !== undefined) {
        notification.error({
          message: 'Error',
          description: error.response.data.message
        });
        return;
      }
      if (data !== undefined) {
        fetchCurrentUser();
        notification.success({
          message: 'Info',
          description: 'You have successfully login'
        });
        navigate('/');
        return;
      }
      message.error('Login gagal silahkan check kembali data anda dan coba kembali');
    } catch (error) {
      message.error('Login gagal silahkan check kembali data anda dan coba kembali');
    }
  };

  const register = async (body) => {
    const { error } = await authAPI.register(body);

    if (error)
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    else {
      notification.success({
        message: 'Info',
        description: 'You have successfully registered'
      });
      navigate('/login');
    }
  };

  const logout = async () => {
    const { error } = await authAPI.logout();
    if (!error) {
      notification.success({
        message: 'Info',
        description: 'You have successfully logout'
      });
      setAuthentication(false);
      navigate('/');
      setCurrentUser({});
    }
  };

  return {
    fetchCurrentUser,
    authenticated,
    currentUser,
    login,
    register,
    logout
  };
}

export const AuthProvider = ({ children }) => {
  const { Provider } = authContext;
  const auth = useAuth();

  return <Provider value={auth}>{children}</Provider>;
};

const AuthConsumer = () => useContext(authContext);

export default AuthConsumer;
