/** @jsxImportSource @emotion/react */

import { Row, Col } from 'antd';

import OtherNews from 'components/pages/OtherNews';
import Filter from 'components/Filter';

import Ads from 'components/Ads';

import { section } from 'styles/layout';
import { generateMargin } from 'styles/utils';

import styles from './styles';
import { checkIsMobile } from 'utils/isMobile';
import SearchResultNews from 'components/pages/SearchResultNews';

const isMobile = checkIsMobile();
export default isMobile ? SearchResultMobile : SearchResultWeb;

function SearchResultMobile() {
  return (
    <div css={section}>
      <div css={generateMargin({ type: 'vertical', value: '25px' })}>
        <Ads height={96} width={'100%'} size="Small" />
      </div>
      <Filter />
      <div css={generateMargin({ type: 'vertical', value: '15px' })}>
        <SearchResultNews />
      </div>
      <div css={generateMargin({ type: 'vertical', value: '15px' })}>
        <OtherNews configs={{ withIcon: true }} />
      </div>
    </div>
  );
}

function SearchResultWeb() {
  return (
    <div css={section}>
      <Row css={generateMargin({ type: 'vertical', value: '25px' })}>
        <Col>
          <Ads height={250} width={1080} size="Large" />
        </Col>
      </Row>
      <Row css={styles.inlineBlock}>
        <Col>
          <SearchResultNews />
        </Col>
        <Col>
          <Row>
            <Filter />
          </Row>
          <Row css={generateMargin({ type: 'vertical', value: '27px' })}>
            <OtherNews listSize={4} configs={{ withIcon: true }} />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
