import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  content: css`
    display: grid;
    grid-template-columns: repeat(${isMobile ? 1 : 2}, 1fr);
    grid-gap: 21px;
    margin-bottom: 55px;
  `,
  headerCard: css`
    max-width: ${isMobile ? '100%' : '1080px'};
    margin: 25px 0px;
    width: 100%;
    height: ${isMobile ? 86 : 103}px;
    padding: ${isMobile ? '16px' : '23px'};
  `,
  headerTitle: css`
    color: ${colors.black};
    font-size: ${isMobile ? 1.063 : 1.5}rem;
    line-height: ${isMobile ? 23 : 29}px;
    margin-bottom: ${isMobile ? 6 : 10}px;
    font-weight: bold;
  `,
  headerDescription: css`
    color: ${colors.black};
    color: ${colors.secondaryGrey};
    font-size: ${isMobile ? 0.688 : 1}rem;
    line-height: ${isMobile ? 13.19 : 20}px;
    font-weight: 500;
  `,

  card: css`
    width: 100%;
    max-width: ${isMobile ? '100%' : '529px'};
    height: ${isMobile ? 245 : 340}px;
    display: flex;
    position: relative;
  `,
  name: css`
    color: ${colors.black};
    font-weight: bold;
    font-size: ${isMobile ? 0.813 : 1}rem;
    line-height: ${isMobile ? 13 : 20}px;
    margin-bottom: ${isMobile ? 3 : 5}px;
  `,
  address: css`
    font-size: ${isMobile ? 0.563 : 0.688}rem;
    line-height: ${isMobile ? 10.75 : 13}px;
    color: ${colors.secondaryGrey};
    font-weight: 500;
  `,
  consultantContent: css`
    color: ${colors.black};
    font-size: ${isMobile ? 0.688 : 0.75}rem;
    line-height: ${isMobile ? 11.97 : 14.63}px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  `,
  button: css`
    font-weight: 500;
    font-size: ${isMobile ? 0.688 : 0.875}rem;
    line-height: ${isMobile ? 13.19 : 17}px;

    position: absolute;
    width: ${isMobile ? '' : '293px'};
    height: ${isMobile ? 30 : 41}px;

    bottom: ${isMobile ? 16 : 27.23}px;
    left: ${isMobile ? '145px' : 'initial'};
    right: ${isMobile ? '16px' : 'initial'};
  `
});
