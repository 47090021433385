/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Card from 'components/Card';
import Empty from 'components/Empty';
import VerticalCard from 'components/NewsCard/VerticalCard';
import HorizontalCard from 'components/NewsCard/HorizontalCard';

import newsAPI from 'APIs/news';
import useGlobal from 'contexts/global';

import { generateMargin } from 'styles/utils';
import styles from './styles';

const initialState = {
  isLoading: false,
  error: null,
  data: [{}],
  totalData: 0,
  pageSize: 11
};

export default function CategoryNews() {
  const { isMobile } = useGlobal();

  const navigate = useNavigate();
  const location = useLocation();
  const { category } = useParams();
  const [params, setParams] = useSearchParams({});

  const [{ data = [], totalData, isLoading, pageSize }, setState] = useState(initialState);
  const [firstNews = {}, ...rest] = data;
  const currentPage = Number(params.get('page') ?? 1);

  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await newsAPI.getNewsWithConfigs({
      category,
      filter: location.search
    });

    setState((prev) => ({
      ...prev,
      error,
      data: data?.data,
      totalData: data?.total,
      pageSize: data?.per_page ?? 11,
      isLoading: false
    }));
    window.scrollTo({ top: 0 });
  }, [category, location.search]);

  const onCardClickedRedirect = (slug) => {
    navigate(isLoading ? '/' : `/berita/${slug}`);
    window.scrollTo({ top: 0 });
  };

  const onPageChange = (page) => setParams({ page });

  if (data?.length === 0 && !isLoading)
    return (
      <Empty
        width={isMobile ? '100%' : '656px'}
        height={isMobile ? '100%' : '978px'}
        position={isMobile ? 'center' : 'top'}
      />
    );

  if (isMobile) {
    return (
      <HorizontalCard.MobileCard
        isLoading={isLoading}
        data={data}
        onClick={onCardClickedRedirect}
        config={{
          displayCategory: false,
          displayDate: true
        }}>
        <div css={generateMargin({ type: 'initial', value: '47px 10px 10px 10px' })}>
          <Pagination
            current={currentPage}
            total={totalData}
            onChange={onPageChange}
            showSizeChanger={false}
            pageSize={pageSize}
            showLessItems
          />
        </div>
      </HorizontalCard.MobileCard>
    );
  }

  return (
    <>
      <VerticalCard.WithDetails
        data={firstNews}
        isLoading={isLoading}
        config={{ withRawContent: false }}
        onClick={() => onCardClickedRedirect(firstNews?.slug)}
      />
      <div css={styles.newsContainer}>
        {rest.map((news) => (
          <VerticalCard.WithActionIcon
            key={news?.id}
            data={news}
            isLoading={isLoading}
            onClick={() => onCardClickedRedirect(news?.slug)}
          />
        ))}
      </div>
      <Card style={styles.pagination}>
        <Pagination
          total={totalData}
          current={currentPage}
          onChange={onPageChange}
          showSizeChanger={false}
          pageSize={pageSize}
        />
      </Card>
    </>
  );
}
