import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  card: css`
    cursor: pointer;
    max-width: 656px;
    width: 100%;

    .ant-card-body {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 24px;
      width: 622px;

      :before,
      :after {
        content: none;
      }
    }
  `,
  title: css`
    font-weight: 600;
    font-size: 1.5rem;
  `,
  secondaryTitle: css`
    font-weight: 500;
  `,
  cardItem: css`
    cursor: pointer;
    display: flex;
  `,
  cardTitle: css`
    width: 405px;
    color: ${colors.black};
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 17px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  cardDescription: css`
    width: 300px !important;
    color: ${colors.black};
    font-size: 0.625rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `,
  cardHeaderTabs: css`
    display: flex;
    width: 656px;
    margin-bottom: 20px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  emptyCard: css`
    width: 100%;
    width: ${isMobile ? '100%' : '656px'};
    min-height: ${isMobile ? 416 : 978}px;
    padding-top: ${isMobile ? 78 : 115}px;

    .ant-card-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        width: ${isMobile ? '100%' : '296px'};
      }
      font-weight: 400;
      font-size: ${isMobile ? 0.813 : 0.875}rem;
      line-height: 22px;
    }
  `
});
