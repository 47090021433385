/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Skeleton, Card as ANTDCard, Space } from 'antd';
import { CommentOutlined, DislikeOutlined, LikeOutlined, RightOutlined } from '@ant-design/icons';

import Card from 'components/Card';
import CategoryBadge from 'components/CategoryBadge';
import HeaderTitle from 'components/HeaderTitle';
import DynamicImage from 'components/DynamicImage';
import Empty from 'components/Empty';

import { removeHTMLTagsFromString } from 'utils/string';

import { generateMargin } from 'styles/utils';
import styles from './styles';

import newsAPI from 'APIs/news';
import { checkIsMobile } from 'utils/isMobile';
import { getBannersThumbnail } from 'utils/getBanners';

const initialState = {
  isLoading: false,
  error: null,
  data: []
};

const isMobile = checkIsMobile();

FirstNewsContent.Loader = () => <Skeleton active paragraph={{ rows: isMobile ? 2 : 4 }} />;
function FirstNewsContent({ data }) {
  return (
    <>
      <div css={styles.cardHeader}>
        <Space size={'small'} wrap>
          <CategoryBadge color={data?.category?.color} title={data?.category?.name} />
          {data?.sub_categories?.[0] && (
            <CategoryBadge
              key={data?.sub_categories?.[0]?.id}
              color={data?.sub_categories?.[0]?.color}
              title={data?.sub_categories?.[0]?.name}
            />
          )}
        </Space>
      </div>
      <div css={styles.cardTitle}>{data?.title}</div>
      <div css={styles.cardDescription}>{removeHTMLTagsFromString(data?.content)}</div>
      <Button type="link" css={styles.button}>
        Baca selengkapnya
        <RightOutlined />
      </Button>
    </>
  );
}

SecondNewsContent.Loader = () => <Skeleton active paragraph={{ rows: isMobile ? 1 : 2 }} />;
function SecondNewsContent({ data }) {
  return (
    <>
      <div css={styles.secondaryCardCategory}>
        <CategoryBadge color={data?.category?.color} title={data?.category?.name} />
        {data?.sub_categories?.[0] && (
          <CategoryBadge
            key={data?.sub_categories?.[0]?.id}
            color={data?.sub_categories?.[0]?.color}
            title={data?.sub_categories?.[0]?.name}
          />
        )}
      </div>
      <div css={styles.secondCardTitle}>{data?.title}</div>
      <div css={styles.secondCardDescription}>
        {moment(data?.created_at).format('MMMM DD, YYYY')}
      </div>
      <Button type="link" css={styles.secondButton}>
        Baca selengkapnya
        <RightOutlined />
      </Button>
    </>
  );
}

export default function TodayHeadline() {
  let [{ isLoading, data }, setState] = useState(initialState);
  const navigate = useNavigate();
  const firstNews = data[0] ?? {};
  const secondNews = data[1] ?? {};

  const onCardClickedRedirect = (slug) => {
    navigate(isLoading ? '/' : `/berita/${slug}`);
    window.scrollTo({ top: 0 });
  };

  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data = [], error } = await newsAPI.getTodayHeadline();

    setState((prev) => ({ ...prev, data, error, isLoading: false }));
  }, []);

  if (!isLoading && data?.length === 0) {
    const emptyProps = {
      title: <HeaderTitle primaryText="Headline " secondaryText="Hari ini" />,
      description: 'Headline hari ini belum tersedia',
      width: isMobile ? '100%' : '607px',
      height: isMobile ? '100%' : '750px',
      position: isMobile ? 'center' : 'top'
    };

    return <Empty {...emptyProps} />;
  }

  return (
    <>
      <div css={styles.container}>
        <HeaderTitle primaryText="Headline " secondaryText="Hari ini" />
        <Card
          cover={
            <DynamicImage
              src={getBannersThumbnail(firstNews?.banner_path)}
              alt={firstNews?.title}
              height={isMobile ? 236 : 375.74}
            />
          }
          style={styles.card}
          onClick={() => onCardClickedRedirect(firstNews?.slug)}>
          <div css={generateMargin({ type: 'initial', value: isMobile ? '11px' : '15px' })}>
            {isLoading ? <FirstNewsContent.Loader /> : <FirstNewsContent data={firstNews} />}
          </div>
        </Card>
        {secondNews?.slug && (
          <Card css={styles.secondaryCard} onClick={() => onCardClickedRedirect(secondNews?.slug)}>
            <ANTDCard.Meta
              avatar={
                <DynamicImage
                  src={getBannersThumbnail(secondNews?.banner_path)}
                  width={isMobile ? 110 : 175}
                  height={isMobile ? 80 : 124}
                  alt={secondNews?.title}
                />
              }
              description={
                <div>
                  {isLoading ? (
                    <SecondNewsContent.Loader />
                  ) : (
                    <SecondNewsContent data={secondNews} />
                  )}
                </div>
              }
            />
          </Card>
        )}
      </div>
    </>
  );
}
