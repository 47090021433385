/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import Card from 'components/Card';
import { generateMargin } from 'styles/utils';
import { section } from 'styles/layout';
import getStyles from './styles';

import useGlobal from 'contexts/global';

export default function TextCard({ children, width }) {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile, width });

  return (
    <div css={[section, generateMargin({ type: 'vertical', value: isMobile ? '8px' : '25px' })]}>
      <Card css={styles.card}>{children}</Card>
    </div>
  );
}
