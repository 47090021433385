/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function CyberMediaGuideline() {
  return (
    <TextCard>
      <header>Pedoman Media Siber</header>
      <div>
        <Paragraph>
          Kemerdekaan berpendapat, kemerdekaan berekspresi, dan kemerdekaan pers adalah hak asasi
          manusia yang dilindungi Pancasila, Undang-Undang Dasar 1945, dan Deklarasi Universal Hak
          Asasi Manusia PBB. Keberadaan media siber di Indonesia juga merupakan bagian dari
          kemerdekaan berpendapat, kemerdekaan berekspresi, dan kemerdekaan pers.
        </Paragraph>
        <Paragraph>
          Media siber memiliki karakter khusus sehingga memerlukan pedoman agar pengelolaannya dapat
          dilaksanakan secara profesional, memenuhi fungsi, hak, dan kewajibannya sesuai
          Undang-Undang Nomor 40 Tahun 1999 tentang Pers dan Kode Etik Jurnalistik. Untuk itu Dewan
          Pers bersama organisasi pers, pengelola media siber, dan masyarakat menyusun Pedoman
          Pemberitaan Media Siber sebagai berikut:
        </Paragraph>
        <Title level={5}>1. Ruang Lingkup</Title>
        <div style={{ margin: '0 0 12px 18px' }}>
          a. Media Siber adalah segala bentuk media yang menggunakan wahana internet dan
          melaksanakan kegiatan jurnalistik, serta memenuhi persyaratan Undang-Undang Pers dan
          Standar Perusahaan Pers yang ditetapkan Dewan Pers.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          b. Isi Buatan Pengguna (User Generated Content) adalah segala isi yang dibuat dan atau
          dipublikasikan oleh pengguna media siber, antara lain, artikel, gambar, komentar, suara,
          video dan berbagai bentuk unggahan yang melekat pada media siber, seperti blog, forum,
          komentar pembaca atau pemirsa, dan bentuk lain.
        </div>
        <Title level={5}>2. Verifikasi dan keberimbangan berita</Title>
        <div style={{ margin: '0 0 12px 18px' }}>
          a. Pada prinsipnya setiap berita harus melalui verifikasi.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          b. Berita yang dapat merugikan pihak lain memerlukan verifikasi pada berita yang sama
          untuk memenuhi prinsip akurasi dan keberimbangan.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          c. Ketentuan dalam butir (a) di atas dikecualikan, dengan syarat:
          <div style={{ margin: '12px 0 12px 18px' }}>
            - Berita benar-benar mengandung kepentingan publik yang bersifat mendesak;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            - Sumber berita yang pertama adalah sumber yang jelas disebutkan identitasnya, kredibel
            dan kompeten;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            - Subyek berita yang harus dikonfirmasi tidak diketahui keberadaannya dan atau tidak
            dapat diwawancarai;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            - Media memberikan penjelasan kepada pembaca bahwa berita tersebut masih memerlukan
            verifikasi lebih lanjut yang diupayakan dalam waktu secepatnya. Penjelasan dimuat pada
            bagian akhir dari berita yang sama, di dalam kurung dan menggunakan huruf miring.
          </div>
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          d. Setelah memuat berita sesuai dengan butir (c), media wajib meneruskan upaya verifikasi,
          dan setelah verifikasi didapatkan, hasil verifikasi dicantumkan pada berita pemutakhiran
          (update) dengan tautan pada berita yang belum terverifikasi.
        </div>
        <Title level={5}>3. Isi Buatan Pengguna (User Generated Content)</Title>
        <div style={{ margin: '0 0 12px 18px' }}>
          a. Media siber wajib mencantumkan syarat dan ketentuan mengenai Isi Buatan Pengguna yang
          tidak bertentangan dengan Undang-Undang No. 40 tahun 1999 tentang Pers dan Kode Etik
          Jurnalistik, yang ditempatkan secara terang dan jelas.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          b. Media siber mewajibkan setiap pengguna untuk melakukan registrasi keanggotaan dan
          melakukan proses log-in terlebih dahulu untuk dapat mempublikasikan semua bentuk Isi
          Buatan Pengguna. Ketentuan mengenai log-in akan diatur lebih lanjut.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          c. Dalam registrasi tersebut, media siber mewajibkan pengguna memberi persetujuan tertulis
          bahwa Isi Buatan Pengguna yang dipublikasikan:
          <div style={{ margin: '12px 0 12px 18px' }}>
            - Tidak memuat isi bohong, fitnah, sadis dan cabul;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            - Tidak memuat isi yang mengandung prasangka dan kebencian terkait dengan suku, agama,
            ras, dan antargolongan (SARA), serta menganjurkan tindakan kekerasan;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            - Tidak memuat isi diskriminatif atas dasar perbedaan jenis kelamin dan bahasa, serta
            tidak merendahkan martabat orang lemah, miskin, sakit, cacat jiwa, atau cacat jasmani.
          </div>
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          d. Media siber memiliki kewenangan mutlak untuk mengedit atau menghapus Isi Buatan
          Pengguna yang bertentangan dengan butir (c).
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          e. Media siber wajib menyediakan mekanisme pengaduan Isi Buatan Pengguna yang dinilai
          melanggar ketentuan pada butir (c). Mekanisme tersebut harus disediakan di tempat yang
          dengan mudah dapat diakses pengguna.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          f. Media siber wajib menyunting, menghapus, dan melakukan tindakan koreksi setiap Isi
          Buatan Pengguna yang dilaporkan dan melanggar ketentuan butir (c), sesegera mungkin secara
          proporsional selambat-lambatnya 2 x 24 jam setelah pengaduan diterima.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          g. Media siber yang telah memenuhi ketentuan pada butir (a), (b), (c), dan (f) tidak
          dibebani tanggung jawab atas masalah yang ditimbulkan akibat pemuatan isi yang melanggar
          ketentuan pada butir (c).
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          h. Media siber bertanggung jawab atas Isi Buatan Pengguna yang dilaporkan bila tidak
          mengambil tindakan koreksi setelah batas waktu sebagaimana tersebut pada butir (f).
        </div>
        <Title level={5}>4. Ralat, Koreksi, dan Hak Jawab</Title>
        <div style={{ margin: '0 0 12px 18px' }}>
          a. Ralat, koreksi, dan hak jawab mengacu pada Undang-Undang Pers, Kode Etik Jurnalistik,
          dan Pedoman Hak Jawab yang ditetapkan Dewan Pers.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          b. Ralat, koreksi dan atau hak jawab wajib ditautkan pada berita yang diralat, dikoreksi
          atau yang diberi hak jawab.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          c. Di setiap berita ralat, koreksi, dan hak jawab wajib dicantumkan waktu pemuatan ralat,
          koreksi, dan atau hak jawab tersebut.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          d. Bila suatu berita media siber tertentu disebarluaskan media siber lain, maka:
          <div style={{ margin: '12px 0 12px 18px' }}>
            - Tanggung jawab media siber pembuat berita terbatas pada berita yang dipublikasikan di
            media siber tersebut atau media siber yang berada di bawah otoritas teknisnya;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            - Koreksi berita yang dilakukan oleh sebuah media siber, juga harus dilakukan oleh media
            siber lain yang mengutip berita dari media siber yang dikoreksi itu;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            - Media yang menyebarluaskan berita dari sebuah media siber dan tidak melakukan koreksi
            atas berita sesuai yang dilakukan oleh media siber pemilik dan atau pembuat berita
            tersebut, bertanggung jawab penuh atas semua akibat hukum dari berita yang tidak
            dikoreksinya itu.
          </div>
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          e. Sesuai dengan Undang-Undang Pers, media siber yang tidak melayani hak jawab dapat
          dijatuhi sanksi hukum pidana denda paling banyak Rp500.000.000 (Lima ratus juta rupiah).
        </div>
        <Title level={5}>5. Pencabutan Berita</Title>
        <div style={{ margin: '0 0 12px 18px' }}>
          a. Berita yang sudah dipublikasikan tidak dapat dicabut karena alasan penyensoran dari
          pihak luar redaksi, kecuali terkait masalah SARA, kesusilaan, masa depan anak, pengalaman
          traumatik korban atau berdasarkan pertimbangan khusus lain yang ditetapkan Dewan Pers.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          b. Media siber lain wajib mengikuti pencabutan kutipan berita dari media asal yang telah
          dicabut.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          c. Pencabutan berita wajib disertai dengan alasan pencabutan dan diumumkan kepada publik.
        </div>
        <Title level={5}>6. Iklan</Title>
        <div style={{ margin: '0 0 12px 18px' }}>
          a. Media siber wajib membedakan dengan tegas antara produk berita dan iklan.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          b. Setiap berita/artikel/isi yang merupakan iklan dan atau isi berbayar wajib mencantumkan
          keterangan &apos;advertorial&apos;, &apos;iklan&apos;, &apos;ads&apos;,
          &apos;sponsored&apos;, atau kata lain yang menjelaskan bahwa berita/artikel/isi tersebut
          adalah iklan.
        </div>
        <Title level={5}>7. Hak Cipta</Title>
        <Paragraph>
          Media siber wajib menghormati hak cipta sebagaimana diatur dalam peraturan
          perundang-undangan yang berlaku.
        </Paragraph>
        <Title level={5}>8. Pencantuman Pedoman</Title>
        <Paragraph>
          Media siber wajib mencantumkan Pedoman Pemberitaan Media Siber ini di medianya secara
          terang dan jelas.
        </Paragraph>
        <Title level={5}>9. Sengketa</Title>
        <Paragraph>
          Penilaian akhir atas sengketa mengenai pelaksanaan Pedoman Pemberitaan Media Siber ini
          diselesaikan oleh Dewan Pers.
        </Paragraph>
      </div>
    </TextCard>
  );
}
