/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, Skeleton } from 'antd';

import Card from 'components/Card';
import HeaderTitle from 'components/HeaderTitle';
import DynamicImage from 'components/DynamicImage';
import HorizontalCard from 'components/NewsCard/HorizontalCard';
import Empty from 'components/Empty';

import useGlobal from 'contexts/global';
import newsAPI from 'APIs/news';

import getStyles from './styles';
import { getBannersThumbnail } from 'utils/getBanners';

const initialConfig = { withIcon: false };
const OtherNews = ({ listSize = 3, configs = initialConfig }) => {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  const initialState = {
    isLoading: false,
    error: null,
    data: Array.from({ length: listSize })
  };
  const finalConfigs = { initialConfig, ...configs };

  const navigate = useNavigate();
  const { slug } = useParams();
  const [{ data, isLoading }, setState] = useState(initialState);

  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await newsAPI.getOtherNews();

    setState((prev) => ({ ...prev, error, isLoading: false, data }));
  }, [slug]);

  const redirectToDetail = (slug) => {
    navigate(isLoading ? '/' : `/berita/${slug}`);
    window.scrollTo({ top: 0 });
  };

  const redirectToSearchResultPage = () => {
    navigate('/hasil-pencarian?title=');
    window.scrollTo({ top: 0 });
  };

  if (data?.length === 0 && !isLoading)
    return (
      <Empty
        description="Berita lainnya tidak tersedia"
        title={<HeaderTitle primaryText="Berita " secondaryText="Lainnya" />}
      />
    );

  return (
    <div css={styles.container}>
      <HeaderTitle
        primaryText="Berita "
        secondaryText="Lainnya"
        LeftComponent={() =>
          finalConfigs.withIcon && (
            <Button type="link" css={styles.seeAllButton} onClick={redirectToSearchResultPage}>
              Lihat semua
            </Button>
          )
        }
      />
      {isMobile ? (
        <HorizontalCard.MobileCard
          data={data}
          isLoading={isLoading}
          onClick={redirectToDetail}
          config={{
            displayDate: false
          }}
        />
      ) : (
        <Card style={styles.card}>
          {data?.map((news, idx) => (
            <div key={idx} css={styles.cardItem} onClick={() => redirectToDetail(news?.slug)}>
              <DynamicImage
                src={getBannersThumbnail(news?.banner_path)}
                alt={news?.title}
                width={80}
                height={80}
              />
              <div css={styles.cardDescription}>
                {isLoading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <>
                    <div css={styles.cardTitle}>{news?.title}</div>
                    <div css={styles.cardDate}>
                      {moment(news?.created_at).format('MMMM DD, YYYY')}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </Card>
      )}
    </div>
  );
};

export default OtherNews;
