import { axiosHttpRequest } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';

export const getStructures = async () => {
  const { data, error } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.GET,
    url: '/structures'
  });

  return { data: data?.data?.data, error };
};
