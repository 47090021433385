import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  mobileContainer: css`
    background-color: ${colors.neutral};
    width: 100%;
    height: 100vh;
    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  container: css`
    display: flex;
    background-color: ${colors.neutral};
  `,
  left: css`
    height: 100vh;
    width: 565px;
    background-image: url('./login-register-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  right: css`
    display: flex;
    align-items: center;
    margin: 0 auto;
  `,
  description: css`
    color: ${colors.white};
    width: 383px;

    Button {
      color: ${colors.white};
    }
  `,
  primaryText: css`
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 39px;
    margin-top: 21.5px;
    margin-bottom: 16.3px;

    span {
      font-weight: 700;
    }
  `,
  secondaryText: css`
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  `
};
