import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';
import { axiosHttpRequest } from 'utils/httpHandler';

export async function uploadMedia({ data, purpose }) {
  let formData = new FormData();
  formData.append('file', data);

  const { data: uploadData } = await axiosHttpRequest({
    method: HTTP_REQUEST_METHOD.POST,
    url: `/medias?purpose=${purpose}`,
    body: formData,
    isUploadFile: true,
    withAuth: true
  });

  return uploadData?.data;
}
