import { createContext, useContext } from 'react';
import { checkIsMobile } from 'utils/isMobile';

export const globalContext = createContext({
  isMobile: false
});

export function GlobalProvider({ children }) {
  const isMobile = checkIsMobile();
  const { Provider } = globalContext;

  return <Provider value={{ isMobile }}>{children}</Provider>;
}

export default function globalConsumer() {
  return useContext(globalContext);
}
