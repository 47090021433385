/** @jsxImportSource @emotion/react */

import { Carousel } from 'antd';
import DynamicImage from 'components/DynamicImage';
import { useEffect, useState } from 'react';

import getStyles from './styles';

export default function CarouselNewsThumbnail({ data = [], height, width }) {
  const carouselWidth = typeof width === 'string' ? width : `${width}px`;
  const carouselHeight = typeof height === 'string' ? height : `${height}px`;
  const styles = getStyles({ height: carouselHeight, width: carouselWidth });
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (data) {
      if (Array.isArray(data)) {
        setImages(data);
      } else {
        setImages([data]);
      }
    }
  }, [data]);

  return (
    <Carousel autoplay draggable css={styles.container}>
      {images?.map((image, idx) => (
        <DynamicImage
          key={idx}
          src={image}
          width={carouselWidth}
          height={carouselHeight}
          css={styles.adsImages}
          alt="Ads image"
        />
      ))}
    </Carousel>
  );
}
