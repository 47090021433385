/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import styles from './styles';
import { Button, message, Space } from 'antd';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import newsAPI from 'APIs/news';
import moment from 'moment';

export default function CheckTanggapanWeb() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  function fetchData() {
    newsAPI.getEndOfficialTanggapan().then((res) => {
      setTableData(res?.data);
    });
  }

  function handleDeleteTanggapan(id) {
    newsAPI
      .deleteTanggapan(id)
      .then(() => {
        message.error('Berhasil menghapus tanggapan');
        fetchData();
      })
      .catch(() => {
        message.error('Gagal menghapus tanggapan, silahkan coba kembali');
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Ditanggapi tanggal',
      dataIndex: 'created_at',
      width: '20%',
      key: 'created_at',
      render: (time) => moment(time).format('DD-MM-YYYY').toString()
    },
    {
      title: 'Action',
      key: 'operation',
      width: '25%',
      render: ({ id, slug, parent_news }) => (
        <Space>
          <Button ghost type="primary" size="medium" onClick={() => navigate(`/berita/${slug}`)}>
            Lihat
          </Button>
          <Button
            type="primary"
            size="medium"
            onClick={() => navigate(`/pengaturan-akun/tulis-tanggapan/${parent_news}`)}>
            Update
          </Button>
          <Button danger type="primary" size="medium" onClick={() => handleDeleteTanggapan(id)}>
            Delete
          </Button>
        </Space>
      )
    }
  ];

  return (
    <div css={styles.container}>
      <header>Cek Tanggapan</header>
      <section>
        <Table columns={columns} dataSource={tableData} pagination={{ position: ['bottomLeft'] }} />
      </section>
    </div>
  );
}
