import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    background-color: ${colors.primaryGrey};
    color: ${colors.white};

    div {
      justify-content: ${isMobile ? 'center' : 'flex-start'};
    }
  `,
  leftContent: css`
    width: ${isMobile ? 200 : 544}px;
    margin-top: ${isMobile ? 30 : 58}px;
    display: block;
  `,
  iconButton: css`
    cursor: pointer;
    color: ${colors.white};
    font-size: ${isMobile ? 0.751 : 1.125}rem;
    font-weight: 600;
    margin-right: ${isMobile ? 15 : 40}px;
  `,
  address: css`
    font-size: ${isMobile ? 0.563 : 0.875}rem;
    font-weight: 500;
    margin-top: 22px;
  `,
  customerService: css`
    font-size: ${isMobile ? 0.563 : 0.875}rem;
    font-weight: 400;
  `,
  rightContent: css`
    margin-top: ${isMobile ? 56 : 92}px;
    font-size: ${isMobile ? 0.563 : 1.125}rem;
    font-weight: 600;
  `,
  divider: css`
    height: ${isMobile ? 87 : 140}px;
    margin-right: ${isMobile ? 43 : 129}px;
    margin-top: ${isMobile ? 36 : 71}px;
    border-left: 1px solid ${colors.white};
  `
});
