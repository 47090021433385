import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  card: css`
    width: 100%;
    padding: 11px;

    .ant-card-body {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 11px;
      align-items: center;

      :before,
      :after {
        content: none;
      }
    }

    .ant-card-meta {
      display: flex;
      align-items: center;
    }

    .ant-card-meta-detail {
      width: -webkit-fill-available;
    }
  `,
  cardTitle: css`
    font-size: 0.626rem;
    line-height: 10.97px;
    color: ${colors.black};
    margin-top: 7px;
    margin-bottom: 0;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  cardDescription: css`
    color: ${colors.black};
    margin-top: 3px;
    font-size: 0.563rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  cardHeaderTabs: css`
    display: flex;
    width: 656px;
    margin-bottom: 20px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `
};
