import { axiosHttpRequest, httpRequestWithoutAwait } from 'utils/httpHandler';
import { HTTP_REQUEST_METHOD } from 'constants/httpRequestMethod';

export default {
  getNewsCategories: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: '/menus/available'
    });

    return { data: data?.data?.data, error };
  },
  getTodayHeadline: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: '/news/headline'
    });

    return { data: data?.data?.data, error };
  },
  getRecent: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: '/news/recent'
    });

    return { data: data?.data?.data, error };
  },
  getTrending: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: '/news/trending'
    });

    return { data: data?.data?.data, error };
  },
  getTopPic: async (id) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/top-pick?category_id=${id}`
    });

    return { data: data?.data?.data, error };
  },
  getNewsWithConfigs: async ({ category = null, title = null, filter = '' }) => {
    const filterParams = filter.includes('page') ? filter : `${filter}&page=1`;
    let url = `/news/end-user?${filterParams}`;

    if (category) {
      url = `${url}&category=${category}`;
    }
    if (title) {
      url = `${url}&title=${title}`;
    }

    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url
    });

    return { data: data?.data.data, error };
  },
  getNewsBySlug: async (slug) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/title/${slug}`
    });

    return { data: data?.data, error };
  },
  getOtherNews: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/others`
    });

    return { data: data?.data?.data, error };
  },
  likeOrDislikeNews: async (id, status) => {
    return httpRequestWithoutAwait({
      method: HTTP_REQUEST_METHOD.PUT,
      url: `/news/${id}/statistic`,
      body: {
        status
      },
      withAuth: true
    });
  },
  addComment: async (id, comment) => {
    const { error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/news/${id}/comments`,
      body: {
        comment
      },
      withAuth: true
    });
    return { error };
  },
  likeOrDislikComment: (newsId, commentId, status) => {
    return httpRequestWithoutAwait({
      method: HTTP_REQUEST_METHOD.PUT,
      url: `/news/${newsId}/comments/${commentId}/statistic`,
      body: {
        status
      },
      withAuth: true
    });
  },
  getGallery: async (page = 1) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/medias?page=${page}`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  createSuratPengirim: async (payload) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/news/enduser/create-surat`,
      body: payload,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  viewSuratPengirim: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-surat`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  viewSuratPengirimDetail: async (id) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-surat/${id}`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  verifyEditSuratPengirim: async ({ id, status }) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/news/enduser/verify-surat/${id}`,
      body: {
        status
      },
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  createEndUserOpini: async (payload) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/news/enduser/create-opini`,
      body: payload,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  viewEndUserOpini: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-opini`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  viewEndUserSuratOfficial: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-surat-official`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  getSuratById: async (id) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-surat-official/${id}`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  createTanggapan: async (payload) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/news/enduser/create-tanggappan`,
      body: payload,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  getEndUserTanggapan: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-tanggapan`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  getEndOfficialTanggapan: async () => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-user-tanggapan`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  updateTanggapan: async (payload, id) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/news/enduser/update-tanggappan/${id}`,
      body: payload,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  deleteTanggapan: async (id) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.POST,
      url: `/news/enduser/delete-tanggappan/${id}`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  },
  getTanggapanByParentId: async (id) => {
    const { data, error } = await axiosHttpRequest({
      method: HTTP_REQUEST_METHOD.GET,
      url: `/news/enduser/view-tanggapan/${id}`,
      withAuth: true
    });

    return { data: data?.data?.data, error };
  }
};
