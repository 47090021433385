/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom';

import { Form, Input, Button, Row, Col, InputNumber, Checkbox, Divider } from 'antd';
import Card from 'components/Card';
import useAuth from 'contexts/auth';
import useGlobal from 'contexts/global';

import { spaceBetween } from 'styles/utils';
import getStyles from './styles';
import regex from 'constants/regex';

export default function RegisterForm() {
  const { register } = useAuth();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onSubmit = async (values) =>
    register({
      email: values.email,
      password: values.password,
      phone: `${values.phoneNumber}`,
      name: `${values.firstName} ${values.lastName}`
    });

  return (
    <div css={styles.container}>
      <p css={styles.headerText}>Daftar</p>
      <p css={styles.secondaryHeaderText}>Buat akun kamu untuk fitur selengkapnya</p>
      <Card css={styles.card}>
        <Form form={form} name="register" layout="vertical" onFinish={onSubmit}>
          <Row css={[spaceBetween]}>
            <Col span={isMobile ? 24 : 'initial'}>
              <Form.Item
                name="firstName"
                label="Nama Depan"
                rules={[
                  {
                    required: true,
                    message: 'Tolong masukkan nama depan Anda!'
                  }
                ]}>
                <Input type="text" name="firstName" css={styles.input} placeholder="Nama Depan" />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 24 : 'initial'}>
              <Form.Item
                name="lastName"
                label="Nama Belakang"
                rules={[
                  {
                    required: true,
                    message: 'Tolong masukkan nama belakang Anda!'
                  }
                ]}>
                <Input type="text" name="lastName" css={styles.input} placeholder="Nama Belakang" />
              </Form.Item>
            </Col>
          </Row>

          <Row css={[spaceBetween]}>
            <Col span={isMobile ? 24 : 'initial'}>
              <Form.Item
                name="phoneNumber"
                label="Nomor Telepon"
                rules={[
                  {
                    required: true,
                    message: 'Tolong masukkan no telepon Anda!'
                  },
                  {
                    pattern: regex.IDPhoneNumber,
                    message: 'Tolong masukkan no telepon yang valid!'
                  }
                ]}>
                <Input type="tel" name="phoneNumber" css={styles.input} placeholder="08•••••••••" />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 24 : 'initial'}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'E-mail yang Anda masukkan tidak valid!'
                  },
                  {
                    required: true,
                    message: 'Tolong masukkan E-mail Anda!'
                  }
                ]}>
                <Input type="email" name="email" css={styles.input} placeholder="Masukkan email" />
              </Form.Item>
            </Col>
          </Row>

          <Row css={[spaceBetween]}>
            <Col span={isMobile ? 24 : 'initial'}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Tolong masukkan password Anda!'
                  },
                  {
                    pattern: regex.password,
                    message:
                      'Pastikan password kamu terdiri dari minimal 8 karakter alphanumerik dan 1 huruf besar'
                  }
                ]}
                hasFeedback>
                <Input.Password
                  type="password"
                  name="password"
                  css={styles.input}
                  placeholder="Masukkan password"
                  autoComplete="true"
                  minLength={8}
                />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 24 : 'initial'}>
              <Form.Item
                name="confirm"
                label="Konfirmasi Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password yang dimasukkan tidak cocok'));
                    }
                  })
                ]}>
                <Input.Password
                  type="confirmPassword"
                  name="confirmPassword"
                  css={styles.input}
                  placeholder="Konfirmasi Password"
                  autoComplete="true"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox css={styles.tncText} required>
              Saya setuju pada{' '}
              <span onClick={() => navigate('/syarat-dan-ketentuan')}>syarat & ketentuan</span>
            </Checkbox>
          </Form.Item>

          <Form.Item css={styles.submitButton}>
            <Button type="primary" shape="round" htmlType="submit">
              Buat Akun
            </Button>
          </Form.Item>
        </Form>
        <p css={styles.registerText}>
          Sudah punya akun? <span onClick={() => navigate('/login')}>Masuk sekarang</span>
        </p>
        {isMobile && (
          <>
            <div css={styles.divider}>
              <Divider />
              <span>Atau</span>
              <Divider />
            </div>
            <div css={styles.submitButton}>
              <Button type="primary" ghost shape="round" onClick={() => navigate('/')}>
                Lanjutkan sebagai tamu
              </Button>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}
