/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function TermsAndConditions() {
  return (
    <TextCard>
      <header>HAK JAWAB</header>
      <div>
        <Title level={3} style={{ margin: '0 0 12px' }}>
          Pedoman Hak Jawab
        </Title>
        <Paragraph>
          Kemerdekaan pers adalah salah satu wujud dari kedaulatan rakyat berasaskan prinsip-prinsip
          demokrasi, keadilan, supremasi hukum, dan Hak Asasi Manusia. Kemerdekaan pers perlu
          dimanfaatkan sebesar-besarnya untuk kepentingan masyarakat, bangsa, dan negara.
        </Paragraph>
        <Paragraph>
          Pelaksanaan kemerdekaan pers dapat diwujudkan oleh pers yang merdeka, profesional, patuh
          pada asas, fungsi, hak, kewajiban, dan peranannya sesuai Undang-Undang Nomor 40 Tahun 1999
          tentang Pers, serta Kode Etik Jurnalistik.
        </Paragraph>
        <Paragraph>
          Dalam menjalankan peran dan fungsinya, pers wajib memberi akses yang proporsional kepada
          masyarakat untuk ikut berpartisipasi memelihara kemerdekaan pers dan menghormati Hak Jawab
          yang dimiliki masyarakat. Untuk itu, Pedoman Hak Jawab ini disusun:
        </Paragraph>
        <div style={{ margin: '0 0 12px 18px' }}>
          1. Hak Jawab adalah hak seseorang, sekelompok orang, organisasi atau badan hukum untuk
          menanggapi dan menyanggah pemberitaan atau karya jurnalistik yang melanggar Kode Etik
          Jurnalistik, terutama kekeliruan dan ketidakakuratan fakta, yang merugikan nama baiknya
          kepada pers yang memublikasikan.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          2. Hak Jawab berasaskan keadilan, kepentingan umum, proporsionalitas, dan profesionalitas.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>3. Pers wajib melayani setiap Hak Jawab.</div>
        <div style={{ margin: '0 0 12px 18px' }}>
          4. Fungsi Hak Jawab adalah:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Memenuhi hak masyarakat untuk mendapatkan informasi yang akurat;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Menghargai martabat dan kehormatan orang yang merasa dirugikan akibat pemberitaan
            pers;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Mencegah atau mengurangi munculnya kerugian yang lebih besar bagi masyarakat dan
            pers;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. Bentuk pengawasan masyarakat terhadap pers.
          </div>
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          5. Tujuan Hak Jawab untuk:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Memenuhi pemberitaaan atau karya jurnalistik yang adil dan berimbang;{' '}
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Melaksanakan tanggung jawab pers kepada masyarakat;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Menyelesaikan sengketa pemberitaan pers; d.Mewujudkan iktikad baik pers.
          </div>
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          6. Hak Jawab berisi sanggahan dan tanggapan dari pihak yang dirugikan.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          7. Hak Jawab diajukan langsung kepada pers yang bersangkutan, dengan tembusan ke Dewan
          Pers.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          8. Dalam hal kelompok orang, organisasi atau badan hukum, Hak Jawab diajukan oleh pihak
          yang berwenang dan atau sesuai statuta organisasi, atau badan hukum bersangkutan.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          9. Pengajuan Hak Jawab dilakukan secara tertulis (termasuk digital) dan ditujukan kepada
          penanggung jawab pers bersangkutan atau menyampaikan langsung kepada redaksi dengan
          menunjukkan identitas diri.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          10. Pihak yang mengajukan Hak Jawab wajib memberitahukan informasi yang dianggap merugikan
          dirinya baik bagian per bagian atau secara keseluruhan dengan data pendukung.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          11. Pelayanan Hak Jawab tidak dikenakan biaya.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          12. Pers dapat menolak isi Hak Jawab jika:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Panjang/durasi/jumlah karakter materi Hak Jawab melebihi pemberitaan atau karya
            jurnalistik yang dipersoalkan;{' '}
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Memuat fakta yang tidak terkait dengan pemberitaan atau karya jurnalistik yang
            dipersoalkan;{' '}
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Pemuatannya dapat menimbulkan pelanggaran hukum;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. Bertentangan dengan kepentingan pihak ketiga yang harus dilindungi secara hukum.
          </div>
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          13. Hak Jawab dilakukan secara proporsional:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Hak Jawab atas pemberitaan atau karya jurnalistik yang keliru dan tidak akurat
            dilakukan baik pada bagian per bagian atau secara keseluruhan dari informasi yang
            dipermasalahkan;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Hak Jawab dilayani pada tempat atau program yang sama dengan pemberitaan atau karya
            jurnalistik yang dipermasalahkan, kecuali disepakati lain oleh para pihak;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Hak Jawab dengan persetujuan para pihak dapat dilayani dalam format ralat, wawancara,
            profil,features, liputan, talkshow, pesan berjalan, komentar media siber, atau format
            lain tetapi bukan dalam format iklan;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. Pelaksanaan Hak Jawab harus dilakukan dalam waktu yang secepatnya, atau pada
            kesempatan pertama sesuai dengan sifat pers yang bersangkutan:
            <div style={{ margin: '12px 0 12px 18px' }}>
              1. Untuk pers cetak wajib memuat Hak Jawab pada edisi berikutnya atau
              selambat-lambatnya pada dua edisi sejak Hak Jawab dimaksud diterima redaksi.
            </div>
            <div style={{ margin: '0 0 12px 18px' }}>
              2. Untuk pers televisi dan radio wajib memuat Hak Jawab pada program berikutnya.
            </div>
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            5. Pemuatan Hak Jawab dilakukan satu kali untuk setiap pemberitaaan.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            6. Dalam hal terdapat kekeliruan dan ketidakakuratan fakta yang bersifat menghakimi,
            fitnah dan atau bohong, pers wajib meminta maaf.
          </div>
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          14. Pers berhak menyunting Hak Jawab sesuai dengan prinsip-prinsip pemberitaan atau karya
          jurnalistik, namun tidak boleh mengubah substansi atau makna Hak Jawab yang diajukan.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          15. Tanggung jawab terhadap isi Hak Jawab ada pada penanggung jawab pers yang
          memublikasikannya.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          16. Hak Jawab tidak berlaku lagi jika setelah 2 (dua) bulan sejak berita atau karya
          jurnalistik dipublikasikan pihak yang dirugikan tidak mengajukan Hak Jawab, kecuali atas
          kesepakatan para pihak.
        </div>
        <div style={{ margin: '0 0 12px 18px' }}>
          17. Sengketa mengenai pelaksanaan Hak Jawab diselesaikan oleh Dewan Pers.
        </div>
        <Paragraph>
          Sesuai Undang-Undang Nomor 40 Tahun 1999 tentang Pers, pers yang tidak melayani Hak Jawab
          selain melanggar Kode Etik Jurnalistik juga dapat dijatuhi sanksi hukum pidana denda
          paling banyak Rp.500.000.000,00 (Lima ratus juta rupiah).
        </Paragraph>
      </div>
    </TextCard>
  );
}
