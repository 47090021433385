/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import styles from './styles';
import { Button } from 'antd';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import newsAPI from 'APIs/news';
import moment from 'moment';

export default function CheckFeedbackWeb() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    newsAPI.getEndUserTanggapan().then((res) => {
      setTableData(res?.data);
    });
  }, []);

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Ditanggapi',
      dataIndex: 'created_at',
      render: (time) => moment(time).format('DD-MM-YYYY').toString()
    },
    {
      title: 'Action',
      width: 80,
      render: (data) => (
        <Button type="primary" size="medium" onClick={() => navigate(`/berita/${data?.slug}`)}>
          Lihat Tanggapan
        </Button>
      )
    }
  ];

  return (
    <div css={styles.container}>
      <header>Cek Tanggapan</header>
      <section>
        <Table columns={columns} dataSource={tableData} pagination={{ position: ['bottomLeft'] }} />
      </section>
    </div>
  );
}
