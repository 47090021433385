/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Button } from 'antd';

import Card from 'components/Card';
import { useNavigate } from 'react-router-dom';
import { generateMargin } from 'styles/utils';
import { getPublicImagePath } from 'utils/getPublicImage';
import { section } from 'styles/layout';

import useGlobal from 'contexts/global';

import getStyles from './styles';

export default function Notfound() {
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });

  return (
    <div css={[section, generateMargin({ type: 'vertical', value: '25px' })]}>
      <Card css={styles.card}>
        <img
          src={getPublicImagePath('not-found.png')}
          width={isMobile ? 136.44 : 252}
          height={'auto'}
        />
        <p>Oops, Halaman tidak ditemukan</p>
        <p>
          Halaman tidak tersedia atau kamu salah memasukkan URL.
          <br />
          Mohon pastikan kamu telah mengakses URL yang benar, atau kembali ke beranda
        </p>
        <Button type="primary" onClick={() => navigate('/')}>
          Kembali ke Beranda
        </Button>
      </Card>
    </div>
  );
}
