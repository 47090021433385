import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  card: css`
    width: ${isMobile ? '100%' : '703px'};
    padding: 26px;
  `,
  buttonContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  commentContainer: css`
    cursor: pointer;

    > .ant-comment-content-author-name {
      line-height: ${isMobile ? 12 : 17.07}px;
    }

    .ant-comment-avatar {
      img {
        width: inherit;
        height: inherit;
      }
    }
  `,
  commentWriter: css`
    font-weight: 700;
    font-size: ${isMobile ? 0.75 : 0.875}rem;
    color: ${colors.black};
    display: flex;
    align-items: center;
  `,
  commentContent: css`
    cursor: pointer;
    font-weight: 400;
    font-size: ${isMobile ? 0.688 : 0.75}rem;
    line-height: ${isMobile ? 12 : 14.63}px;
  `,
  date: css`
    font-weight: 400;
    font-size: ${isMobile ? 0.75 : 0.875}rem;
    line-height: ${isMobile ? 12 : 17.07}px;
  `,
  sosmedInfo: css`
    display: flex;
    font-weight: 600;
    font-size: ${isMobile ? 0.689 : 0.876}rem !important;
    line-height: ${isMobile ? 12 : 14.63}px;

    .anticon {
      color: ${colors.secondaryGrey};
      margin-right: ${isMobile ? 8.85 : 12.67}px;
    }
    > div {
      cursor: pointer;

      &[data-state='liked'] {
        color: ${colors.primaryGreen} !important;

        .anticon {
          color: ${colors.primaryGreen} !important;
        }
      }

      &[data-state='disliked'] {
        color: ${colors.primaryRed} !important;
        .anticon {
          color: ${colors.primaryRed} !important;
        }
      }
    }
  `,
  dot: css`
    width: ${isMobile ? 4 : 5}px;
    height: ${isMobile ? 4 : 5}px;
    border-radius: 10px;
    background-color: ${colors.tertiaryGrey};
    margin-left: 5px;
  `
});
