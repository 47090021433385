/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Image } from 'antd';

export default function DynamicImage({ src, alt, width, height, radius = 10, ...rest }) {
  return (
    <Image
      preview={false}
      fallback={`https://via.placeholder.com/${width}x${height}`}
      src={src ?? `https://via.placeholder.com/${width}x${height}`}
      alt={alt ?? 'placeholder'}
      width={width || '100%'}
      height={height || '100%'}
      {...rest}
      css={css`
        border-radius: ${radius}px !important;
        overflow: hidden;
        object-fit: contain !important;
      `}
    />
  );
}
