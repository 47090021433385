/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import styles from './styles';
import { Button, Card, Col, Divider, Input, message, Modal, notification, Row, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import { generateMargin } from 'styles/utils';
import { useEffect, useState } from 'react';
import { STATUS_NEWS } from 'constants/status';
import { addGalleryUser, deleteGalleryUser, getGalleryUser } from 'APIs/gallery';
import { uploadMedia } from 'APIs/media';
import { CheckOutlined } from '@ant-design/icons';
import DynamicImage from 'components/DynamicImage';

export default function CheckMyLetterMobile() {
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [uploadData, setUploadData] = useState({
    name: '',
    path: ''
  });

  function fetchData() {
    getGalleryUser()
      .then((res) => {
        setTableData(res?.data);
      })
      .catch(() => {
        message.error('Gagal mendapatkan data dari server silahkan refresh halaman anda');
      });
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 1) {
        return false;
      }
      message.error('Ukuran File lebih besar dari 1 MB');
      return true;
    } else {
      message.error(`${file.name} tidak bertipe jpg, jpeg, atau png `);
      return true;
    }
  }

  function handleImageUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'gallery' })
        .then(({ data }) => {
          const imgPath = data?.path;
          handleOnChange(imgPath, 'path');
        })
        .catch(() => {
          notification.error({
            message: 'Gallery gagal upload',
            description: 'Mohon periksa kembali dan upload ulang gambarnya'
          });
        });
    }
  }

  function doValidation() {
    if (!uploadData?.name) {
      message.error('Judul harus diisi');
      return false;
    }
    if (!uploadData?.path) {
      message.error('Gambar harus diupload terlebih dahulu');
      return false;
    }
    return true;
  }

  function handleOnChange(value, key) {
    setUploadData((prev) => ({ ...prev, [key]: value }));
  }

  function handleDelete(id) {
    deleteGalleryUser(id)
      .then(() => {
        message.success('Berhasil menghapus gallery');
        fetchData();
      })
      .catch(() => {
        message.error('Gagal mengahapus gallery, silahkan coba beberapa saat lagi');
      });
  }

  function handleSubmit() {
    if (doValidation()) {
      addGalleryUser(uploadData)
        .then(() => {
          message.success('Berhasil mengupload gallery');
          setShowModal(false);
          fetchData();
        })
        .catch(() => {
          message.error('Gagal mengupload gallery, silahkan coba kembali beberapa saat');
        });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div css={styles.container}>
      <header>Cek Gallery Saya</header>
      <Divider></Divider>
      <Button ghost block type="primary" size="medium" onClick={() => setShowModal(true)}>
        Upload Gambar
      </Button>
      <Divider></Divider>

      <section>
        {tableData.map((item) => (
          <div key={item.key} css={styles.divItemContainer}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <p>{item.name}</p>
              </Col>
              <Col span={24}>
                <div>Status:</div>
                <div>
                  {item?.verifier_id ? (
                    <CheckOutlined css={styles.checklistStyle} />
                  ) : (
                    'Menunggu verifikasi admin'
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 8]} css={generateMargin({ type: 'top', value: '21px' })}>
              <Col span={24}>
                <Button ghost block type="primary" danger onClick={() => handleDelete(item?.id)}>
                  Hapus
                </Button>
              </Col>
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="medium"
                  // shape="round"
                  onClick={() => (window.location.href = item?.path)}>
                  Lihat Gambar
                </Button>
              </Col>
            </Row>
            <Divider></Divider>
          </div>
        ))}
      </section>
      <Modal
        className="styModal"
        title="Upload Gallery"
        visible={showModal}
        centered
        maskClosable
        mask
        footer={null}
        onCancel={() => {
          setShowModal(false);
        }}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div>Judul</div>
            <Input
              placeholder="Masukan judul gambar"
              onChange={(e) => handleOnChange(e?.target?.value, 'name')}
            />
          </Col>
          {uploadData?.path && (
            <Col span={24} style={{ textAlign: 'center' }}>
              <DynamicImage src={uploadData?.path} width={342}></DynamicImage>
            </Col>
          )}
          <Col span={24} style={{ marginTop: '16px' }} css={styles.styUpload}>
            <div className="upload-notes">
              Note: format yang diperbolehkan adalah jpg,jpeg,png dan maximum 1 Mb
            </div>
            <Upload
              maxCount={1}
              listType="picture"
              accept=".jpg,.png,.jpeg"
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleImageUploader}>
              <Button block ghost type="primary">
                Upload File
              </Button>
            </Upload>
          </Col>
          <Col span={24}>
            <Button block type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
