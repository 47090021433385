export const categories = [
  {
    id: '0ff493ef-244b-47a8-b899-911a16694db6',
    name: 'Nasional',
    slug: 'nasional',
    color: 'blue'
  },
  {
    id: '660ea151-b953-4f97-a51f-eae3c3c07aff',
    name: 'Opini',
    slug: 'opini',
    color: 'magenta'
  },
  {
    id: '88046d69-33b4-4270-8cb4-e88336cc42f2',
    name: 'Cek Fakta',
    slug: 'cek-fakta',
    color: 'purple'
  },
  {
    id: '98109273-99ef-4704-aafd-758a81899ac9',
    name: 'Pro Kontra',
    slug: 'pro-kontra',
    color: 'orange'
  },
  {
    id: 'd9a2b8d0-4909-4a22-9b04-8a48d30c07e9',
    name: 'Internasional',
    slug: 'internasional',
    color: 'geekblue'
  }
];
