/** @jsxImportSource @emotion/react */

import { Row, Col } from 'antd';

import OtherNews from 'components/pages/OtherNews';
import CategoryNews from 'components/pages/CategoryNews';
import Filter from 'components/Filter';

import Ads from 'components/Ads';

import { section } from 'styles/layout';
import { generateMargin } from 'styles/utils';

import styles from './styles';
import { checkIsMobile } from 'utils/isMobile';

const isMobile = checkIsMobile();
export default isMobile ? CategoryPageMobile : CategoryPageWeb;

function CategoryPageMobile() {
  return (
    <div css={section}>
      <div css={generateMargin({ type: 'vertical', value: '25px' })}>
        <Ads height={96} width={'100%'} />
      </div>
      <Filter />
      <div css={generateMargin({ type: 'vertical', value: '15px' })}>
        <CategoryNews />
      </div>
      <div css={generateMargin({ type: 'vertical', value: '15px' })}>
        <Ads height={96} width={'100%'} />
      </div>
    </div>
  );
}

function CategoryPageWeb() {
  return (
    <div css={section}>
      <Row css={generateMargin({ type: 'vertical', value: '25px' })}>
        <Col>
          <Ads size="Large" height={250} width={1080} />
        </Col>
      </Row>
      <Row css={styles.inlineBlock}>
        <Col span={isMobile ? 24 : 'initial'}>
          <CategoryNews />
        </Col>
        <Col>
          <Row>
            <Filter />
          </Row>
          <Row css={generateMargin({ type: 'vertical', value: '27px' })}>
            <OtherNews />
          </Row>
          <Row>
            <Ads size="Small" height={280} width={350} />
          </Row>
          <Row css={generateMargin({ type: 'top', value: '27px' })}>
            <Ads size="Medium" width={350} height={600} />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
