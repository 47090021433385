import { css } from '@emotion/react';
import colors from 'styles/colors';

export default {
  tabsBoldText: css`
    font-size: 0.75rem;
    font-weight: 600;
  `,
  tabboldTextNeutral: css`
    font-size: 0.75rem;
    font-weight: 600;
    color: ${colors.secondaryGrey};
  `
};
