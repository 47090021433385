/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Button } from 'antd';
import { Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import styles from './styles';
import { useEffect, useState } from 'react';
import newsAPI from 'APIs/news';

export default function CheckOpinionWeb() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    newsAPI.viewEndUserOpini().then((res) => {
      setTableData(res?.data);
    });
  }, []);

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      width: 340,
      key: 'title'
    },
    {
      title: 'Kunjungan',
      dataIndex: 'total_visit',
      key: 'total_visit',
      align: 'center'
    },
    {
      title: 'Terverifikasi',
      align: 'center',
      width: 100,
      render: (data) => <>{data?.approved_by && <CheckOutlined css={styles.checklistStyle} />}</>
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      render: (record) => (
        <>
          {record?.approved_by && (
            <Button
              type="primary"
              size="medium"
              onClick={() => navigate(`/berita/${record?.slug}`)}>
              Lihat Detail
            </Button>
          )}
        </>
      )
    }
  ];

  return (
    <div css={styles.container}>
      <header>Cek Opini</header>
      <section>
        <Table columns={columns} dataSource={tableData} pagination={{ position: ['bottomLeft'] }} />
      </section>
    </div>
  );
}
