/** @jsxImportSource @emotion/react */
import { Tabs } from 'antd';

import styles from './styles';
import useTabList from './useTabList';

export default function TabList() {
  const { menuList, redirectPage, navigate } = useTabList();

  return (
    <Tabs defaultActiveKey="1" onChange={redirectPage}>
      {menuList?.main.map(({ name, slug, icon, iconStyle, style, tab, props, isActive = true }) => {
        if (!isActive) return null;
        return (
          <Tabs.TabPane
            key={slug}
            tab={
              tab || (
                <span onClick={() => navigate(`/${slug}`)} css={style || styles.tabsBoldText}>
                  <span css={iconStyle}>{icon}</span>
                  {name}
                </span>
              )
            }
            {...props}
          />
        );
      })}
      {menuList?.list.map(
        ({ name, is_active, slug }, idx) =>
          is_active && (
            <Tabs.TabPane
              key={`kategori/${slug ?? idx}`}
              tab={<span css={styles.tabboldTextNeutral}>{name}</span>}
            />
          )
      )}
    </Tabs>
  );
}
