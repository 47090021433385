import { css } from '@emotion/react';

export default {
  container: css`
    padding: 23px 16px;
    border-radius: 16px;
    width: fit-content;

    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

    .ant-card-body {
      padding: 0px !important;
    }
    .ant-card-meta {
      margin: 0 !important;
    }
    .ant-card-meta-description {
      div {
        width: 100%;
      }
    }
  `
};
