import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    margin-top: 25px;

    > .ant-row {
      margin-bottom: 68px;
    }
  `,
  headerCard: css`
    max-width: ${isMobile ? '100%' : '1080px'};
    margin: 25px 0px;
    width: 100%;
    height: ${isMobile ? 86 : 103}px;
    padding: ${isMobile ? '16px' : '23px'};
  `,
  headerTitle: css`
    color: ${colors.black};
    font-size: ${isMobile ? 1.063 : 1.5}rem;
    line-height: ${isMobile ? 23 : 29}px;
    margin-bottom: ${isMobile ? 6 : 10}px;
    font-weight: bold;
  `,
  headerDescription: css`
    color: ${colors.black};
    color: ${colors.secondaryGrey};
    font-size: ${isMobile ? 0.688 : 1}rem;
    line-height: ${isMobile ? 13.19 : 20}px;
    font-weight: 500;
  `,
  galleryImageWrapper: css`
    width: ${isMobile ? '100%' : '342px'};
    padding: 13px 0;
  `
});
