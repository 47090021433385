/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import TextCard from 'components/pages/TextCard';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export default function FAQ() {
  return (
    <TextCard>
      <header>KODE ETIK</header>
      <div>
        <Title level={3} style={{ margin: '0 0 12px' }}>
          Kode Etik Jurnalistik
        </Title>
        <Paragraph>
          Kemerdekaan berpendapat, berekspresi, dan pers adalah hak asasi manusia yang dilindungi
          Pancasila, Undang-Undang Dasar 1945, dan Deklarasi Universal Hak Asasi Manusia PBB.
          Kemerdekaan pers adalah sarana masyarakat untuk memperoleh informasi dan berkomunikasi,
          guna memenuhi kebutuhan hakiki dan meningkatkan kualitas kehidupan manusia. Dalam
          mewujudkan kemerdekaan pers itu, wartawan Indonesia juga menyadari adanya kepentingan
          bangsa, tanggung jawab sosial, keberagaman masyarakat, dan norma-norma agama.
        </Paragraph>
        <Paragraph>
          Dalam melaksanakan fungsi, hak, kewajiban dan peranannya, pers menghormati hak asasi
          setiap orang, karena itu pers dituntut profesional dan terbuka untuk dikontrol oleh
          masyarakat.
        </Paragraph>
        <Paragraph>
          Untuk menjamin kemerdekaan pers dan memenuhi hak publik untuk memperoleh informasi yang
          benar, wartawan Indonesia memerlukan landasan moral dan etika profesi sebagai pedoman
          operasional dalam menjaga kepercayaan publik dan menegakkan integritas serta
          profesionalisme. Atas dasar itu, wartawan Indonesia menetapkan dan menaati Kode Etik
          Jurnalistik:
        </Paragraph>
        {/* Section Start */} <br />
        <Paragraph strong>Pasal 1</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia bersikap independen, menghasilkan berita yang akurat, berimbang, dan
          tidak beritikad buruk.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Independen berarti memberitakan peristiwa atau fakta sesuai dengan suara hati nurani
            tanpa campur tangan, paksaan, dan intervensi dari pihak lain termasuk pemilik perusahaan
            pers.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Akurat berarti dipercaya benar sesuai keadaan objektif ketika peristiwa terjadi.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Berimbang berarti semua pihak mendapat kesempatan setara.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. Tidak beritikad buruk berarti tidak ada niat secara sengaja dan semata-mata untuk
            menimbulkan kerugian pihak lain.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 2</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia menempuh cara-cara yang profesional dalam melaksanakan tugas
          jurnalistik.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <br />
          <br />
          Cara-cara yang profesional adalah:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. menunjukkan identitas diri kepada narasumber;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>2. menghormati hak privasi;</div>
          <div style={{ margin: '0 0 12px 18px' }}>3. tidak menyuap;</div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. menghasilkan berita yang faktual dan jelas sumbernya;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            5. rekayasa pengambilan dan pemuatan atau penyiaran gambar, foto, suara dilengkapi
            dengan keterangan tentang sumber dan ditampilkan secara berimbang;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            6. menghormati pengalaman traumatik narasumber dalam penyajian gambar, foto, suara;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            7. tidak melakukan plagiat, termasuk menyatakan hasil liputan wartawan lain sebagai
            karya sendiri;
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            8. penggunaan cara-cara tertentu dapat dipertimbangkan untuk peliputan berita
            investigasi bagi kepentingan publik.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 3</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia selalu menguji informasi, memberitakan secara berimbang, tidak
          mencampurkan fakta dan opini yang menghakimi, serta menerapkan asas praduga tak bersalah.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Menguji informasi berarti melakukan check and recheck tentang kebenaran informasi
            itu.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Berimbang adalah memberikan ruang atau waktu pemberitaan kepada masing-masing pihak
            secara proporsional.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Opini yang menghakimi adalah pendapat pribadi wartawan. Hal ini berbeda dengan opini
            interpretatif, yaitu pendapat yang berupa interpretasi wartawan atas fakta.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. Asas praduga tak bersalah adalah prinsip tidak menghakimi seseorang.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 4</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia tidak membuat berita bohong, fitnah, sadis, dan cabul.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Bohong berarti sesuatu yang sudah diketahui sebelumnya oleh wartawan sebagai hal yang
            tidak sesuai dengan fakta yang terjadi.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Fitnah berarti tuduhan tanpa dasar yang dilakukan secara sengaja dengan niat buruk.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Sadis berarti kejam dan tidak mengenal belas kasihan.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. Cabul berarti penggambaran tingkah laku secara erotis dengan foto, gambar, suara,
            grafis atau tulisan yang semata-mata untuk membangkitkan nafsu birahi.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            5. Dalam penyiaran gambar dan suara dari arsip, wartawan mencantumkan waktu pengambilan
            gambar dan suara.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 5</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia tidak menyebutkan dan menyiarkan identitas korban kejahatan susila dan
          tidak menyebutkan identitas anak yang menjadi pelaku kejahatan.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Identitas adalah semua data dan informasi yang menyangkut diri seseorang yang
            memudahkan orang lain untuk melacak.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Anak adalah seorang yang berusia kurang dari 16 tahun dan belum menikah.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 6</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia tidak menyalahgunakan profesi dan tidak menerima suap.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Identitas adalah semua data dan informasi yang menyangkut diri seseorang yang
            memudahkan orang lain untuk melacak.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Anak adalah seorang yang berusia kurang dari 16 tahun dan belum menikah.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 7</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia memiliki hak tolak untuk melindungi narasumber yang tidak bersedia
          diketahui identitas maupun keberadaannya, menghargai ketentuan embargo, informasi latar
          belakang, dan off the record sesuai dengan kesepakatan.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Hak tolak adalak hak untuk tidak mengungkapkan identitas dan keberadaan narasumber
            demi keamanan narasumber dan keluarganya.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Embargo adalah penundaan pemuatan atau penyiaran berita sesuai dengan permintaan
            narasumber.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Informasi latar belakang adalah segala informasi atau data dari narasumber yang
            disiarkan atau diberitakan tanpa menyebutkan narasumbernya.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            4. Off the record adalah segala informasi atau data dari narasumber yang tidak boleh
            disiarkan atau diberitakan.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 8</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia tidak menulis atau menyiarkan berita berdasarkan prasangka atau
          diskriminasi terhadap seseorang atas dasar perbedaan suku, ras, warna kulit, agama, jenis
          kelamin, dan bahasa serta tidak merendahkan martabat orang lemah, miskin, sakit, cacat
          jiwa atau cacat jasmani.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Prasangka adalah anggapan yang kurang baik mengenai sesuatu sebelum mengetahui secara
            jelas.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>2. Diskriminasi adalah pembedaan perlakuan.</div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 9</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia menghormati hak narasumber tentang kehidupan pribadinya, kecuali untuk
          kepentingan publik.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Menghormati hak narasumber adalah sikap menahan diri dan berhati-hati.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Kehidupan pribadi adalah segala segi kehidupan seseorang dan keluarganya selain yang
            terkait dengan kepentingan publik.`
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 10</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia segera mencabut, meralat, dan memperbaiki berita yang keliru dan tidak
          akurat disertai dengan permintaan maaf kepada pembaca, pendengar, dan atau pemirsa.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Segera berarti tindakan dalam waktu secepat mungkin, baik karena ada maupun tidak ada
            teguran dari pihak luar.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Permintaan maaf disampaikan apabila kesalahan terkait dengan substansi pokok.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>Pasal 11</Paragraph>
        <Paragraph strong>
          Wartawan Indonesia melayani hak jawab dan hak koreksi secara proporsional.
        </Paragraph>
        <Paragraph>
          Penafsiran:
          <div style={{ margin: '12px 0 12px 18px' }}>
            1. Hak jawab adalah hak seseorang atau sekelompok orang untuk memberikan tanggapan atau
            sanggahan terhadap pemberitaan berupa fakta yang merugikan nama baiknya.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            2. Hak koreksi adalah hak setiap orang untuk membetulkan kekeliruan informasi yang
            diberitakan oleh pers, baik tentang dirinya maupun tentang orang lain.
          </div>
          <div style={{ margin: '0 0 12px 18px' }}>
            3. Proporsional berarti setara dengan bagian berita yang perlu diperbaiki.
          </div>
        </Paragraph>
        {/* Section Start */} <br />
        <br />
        <Paragraph strong>
          Penilaian akhir atas pelanggaran kode etik jurnalistik dilakukan Dewan Pers. Sanksi atas
          pelanggaran kode etik jurnalistik dilakukan oleh organisasi wartawan dan atau perusahaan
          pers.
        </Paragraph>
        <Paragraph>
          Kode Etik Jurnalistik ditetapkan Dewan Pers melalui Peraturan Dewan Pers Nomor:
          6/Peraturan-DP/V/2008 Tentang Pengesahan Surat Keputusan Dewan Pers Nomor
          03/SK-DP/III/2006 tentang Kode Etik Jurnalistik Sebagai Peraturan Dewan Pers.
        </Paragraph>
      </div>
    </TextCard>
  );
}
