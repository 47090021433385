/** @jsxImportSource @emotion/react */

import { memo } from 'react';
import styles from './styles';
import { Card as ANTDCard } from 'antd';
import useGlobal from 'contexts/global';
import { useLocation } from 'react-router-dom';

export default memo(function Card({ children, style, ...props }) {
  const { isMobile } = useGlobal();
  const path = useLocation();

  if (isMobile) {
    if (
      path.pathname === '/pengaturan-akun/cek-opini' ||
      path.pathname === '/pengaturan-akun/cek-tanggapan' ||
      path.pathname === '/pengaturan-akun/cek-surat-saya' ||
      path.pathname === '/pengaturan-akun/beli-iklan'
    ) {
      return children;
    }
  }

  return (
    <ANTDCard css={[styles.container, style]} {...props}>
      {children}
    </ANTDCard>
  );
});
