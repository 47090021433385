/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import {
  Form,
  Button,
  Input,
  Upload,
  Row,
  Col,
  message,
  notification,
  Modal,
  Carousel
} from 'antd';
import useGlobal from 'contexts/global';

import getStyles from './styles';
import { useState } from 'react';
import { uploadMedia } from 'APIs/media';
import { InboxOutlined } from '@ant-design/icons';
import DynamicImage from 'components/DynamicImage';
import ReactQuill from 'react-quill';
import newsAPI from 'APIs/news';

export default function WriteOpinion() {
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const [newsData, setNewsData] = useState({
    title: '',
    content: ''
  });
  const [banners, setBanners] = useState([]);

  function doValidate(form) {
    if (!form?.title) {
      message.error('Masukan judul article');
      return false;
    }
    if (banners?.length < 1) {
      message.error('Masukan gambar pendukung');
      return false;
    }
    if (!form?.content) {
      message.error('Content artikel tidak boleh kosong');
      return false;
    }

    return true;
  }

  const onFinish = () => {
    const payload = {
      ...newsData,
      banner_path: JSON.stringify(banners?.map((img) => img?.url))
    };
    if (doValidate(payload)) {
      newsAPI
        .createEndUserOpini(payload)
        .then((res) => {
          notification.success({
            message: 'Berhasil mengupload Opini'
          });
          setTimeout(() => {
            window.location.href = '/pengaturan-akun/cek-opini';
          }, 1000);
        })
        .catch(() => {
          notification.error({
            message: 'Gagal mengupload Opini, silahkan coba kembali'
          });
        });
    }
  };

  function handleOnChange(value, key) {
    setNewsData((prev) => ({ ...prev, [key]: value }));
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 1) {
        return false;
      }
      Modal.error({
        title: 'Error',
        content: `Ukuran File lebih besar dari 1 MB`,
        maskClosable: true
      });

      return true;
    } else {
      Modal.error({
        title: 'Error',
        content: `${file.name} tidak bertipe jpg, jpeg, atau png `,
        maskClosable: true
      });

      return true;
    }
  }

  function handleImageUploaderRemove(file) {
    setBanners((prev) => prev?.filter((img) => img?.name !== file?.name));
  }

  function handleImageUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: 'news' }).then((res) => {
        const imgData = res?.data;
        setBanners((prev) => [...prev, { name: imgData?.name, url: imgData?.path }]);
      });
    }
  }

  return (
    <div css={styles.container}>
      <header>Tulis Opini</header>
      <section>
        <Form css={styles.form} onFinish={onFinish} autoComplete="off" layout="vertical">
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Form.Item label="Judul">
                <Input
                  placeholder="Masukkan judul berita..."
                  value={newsData?.title}
                  onChange={(e) => {
                    handleOnChange(e.target.value, 'title');
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Gambar Pendukung">
                <Upload.Dragger
                  fileList={banners}
                  listType="picture"
                  accept="jpg,jpeg,png"
                  maxCount={5}
                  beforeUpload={handleBeforeUpload}
                  onChange={handleImageUploader}
                  onRemove={handleImageUploaderRemove}
                  style={{ width: '550px', maxWidth: '550px' }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Upload gambar (Max 1MB)</p>
                  <p className="ant-upload-hint">
                    Format .jpg,.jpeg,.png
                    <br />
                    dimensi yang direkomendasikan 653 x 416px atau 1306 x 832px
                    <br />
                    Minimum 1 gambar dan maksimum 5 gambar
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
            <Col span={24}>
              {Boolean(banners?.length) && (
                <Carousel autoplay dotPosition="bottom" style={{ width: 653 }}>
                  {banners?.map((img, index) => (
                    <div key={index}>
                      <DynamicImage width={653} height={416} src={img} />
                    </div>
                  ))}
                </Carousel>
              )}
            </Col>
            <Col span={24}>
              <Form.Item label="Isi Surat">
                <ReactQuill
                  value={newsData.content}
                  onChange={(val) => handleOnChange(val, 'content')}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: [1, 2, 3, 4, false] }],
                        ['bold', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }]
                      ]
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" shape="round" block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </section>
    </div>
  );
}
