import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  card: css`
    max-width: ${isMobile ? '100%' : '446px'};
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  youTubeImage: css`
    height: ${isMobile ? 202.55 : 229.55}px;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
  `,
  youtubeIcon: css`
    height: ${isMobile ? 40 : 50}px;
    width: ${isMobile ? 40 : 50}px;
  `,
  title: css`
    font-size: ${isMobile ? 1.188 : 1.375}rem;
    line-height: ${isMobile ? 22.94 : 26.82}px;
    color: ${colors.black};
    font-weight: 600;
    margin-bottom: 5px;
  `,
  description: css`
    font-size: ${isMobile ? 0.813 : 0.875}rem;
    line-height: ${isMobile ? 15.63 : 17.07}px;
    color: ${colors.black};
    font-weight: 400;
  `,
  youtubeButton: css`
    width: -webkit-fill-available;
  `
});
