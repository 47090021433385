export function getBannersThumbnail(bannersPath = '') {
  // check if bannerPath only contain single Link for json Link
  if (bannersPath?.indexOf('[') !== -1) {
    const bannerList = JSON.parse(bannersPath);
    return bannerList?.[0] || '';
  }
  return bannersPath;
}

export function formatNewsBanner(bannersPath = '') {
  // check if bannerPath only contain single Link for json Link
  if (bannersPath?.indexOf('[') !== -1) {
    const bannerList = JSON.parse(bannersPath);
    return bannerList;
  }
  return [bannersPath];
}
