/** @jsxImportSource @emotion/react */

import { useLocation, useNavigate } from 'react-router-dom';

import Card from 'components/Card';

import useGlobal from 'contexts/global';
import useAuth from 'contexts/auth';
import { sidebarMenu, sidebarMenuOfc } from 'constants/navbar';

import styles from './styles';

export default function SettingSidebar() {
  const { isMobile } = useGlobal();
  if (isMobile) return <></>;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  let [currentActiveSlug] = pathname.split('/').slice(-1);
  if (currentActiveSlug == 'pengaturan-akun') currentActiveSlug = '';

  const navigateToSettings = (e) => {
    const slug = e.target.dataset.target;
    if (!slug && slug !== '') return;
    navigate(`/pengaturan-akun/${slug}`);
  };

  return (
    <Card css={styles.sidebar}>
      {currentUser?.is_official ? (
        <>
          {sidebarMenuOfc.map(({ name, slug, childrens }) => (
            <div
              key={name}
              onClick={navigateToSettings}
              data-target={slug}
              data-active={currentActiveSlug === slug}>
              {name}
              {childrens &&
                childrens.map(({ name, slug }) => (
                  <div key={name} data-target={slug} data-active={currentActiveSlug === slug}>
                    {name}
                  </div>
                ))}
            </div>
          ))}
        </>
      ) : (
        <>
          {sidebarMenu.map(({ name, slug, childrens, isVerifMenu }) => (
            <>
              {(Boolean(currentUser?.is_verified_user) === isVerifMenu ||
                Boolean(currentUser?.is_verified_user)) && (
                <div
                  key={name}
                  onClick={navigateToSettings}
                  data-target={slug}
                  data-active={currentActiveSlug === slug}>
                  {name}
                  {childrens &&
                    childrens.map(({ name, slug }) => (
                      <div key={name} data-target={slug} data-active={currentActiveSlug === slug}>
                        {name}
                      </div>
                    ))}
                </div>
              )}
            </>
          ))}
        </>
      )}
    </Card>
  );
}
