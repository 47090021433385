/** @jsxImportSource @emotion/react */

import { Carousel } from 'antd';
import DynamicImage from 'components/DynamicImage';
import { useEffect, useState } from 'react';
import { initialSmallAds, initialMediumAds, initialLargeAds } from 'constants/AdsInitialValue.js';
import { getActiveAds } from 'APIs/ads.js';
import getStyles from './styles';

const sizeMap = {
  small: initialSmallAds,
  medium: initialMediumAds,
  large: initialLargeAds
};

export default function Ads({ height, width, size = 'Large' }) {
  const [adsData, setAdsData] = useState([]);
  const carouselWidth =
    typeof width === 'string'
      ? `${document.documentElement.getBoundingClientRect().width - 2 * 16}px`
      : `${width}px`;

  const carouselHeight = typeof height === 'string' ? height : `${height}px`;
  const styles = getStyles({ height: carouselHeight, width: carouselWidth });

  useEffect(async () => {
    getActiveAds().then((res) => {
      setAdsData(res?.data?.[size]);
    });
  }, []);

  if (adsData?.length < 1) return <></>;

  return (
    <Carousel autoplay css={styles.container} draggable>
      {adsData?.map(({ id, path }) => (
        <div key={id}>
          <DynamicImage
            src={path}
            width={carouselWidth}
            height={carouselHeight}
            css={styles.adsImages}
            alt="Ads image"
          />
        </div>
      ))}
    </Carousel>
  );
}
