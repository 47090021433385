import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  secondaryTitle: css`
    font-weight: 500;
    font-size: ${isMobile ? 0.688 : 1}rem;
    margin-top: ${isMobile ? -5 : -15}px;
  `,
  info: css`
    background-color: ${colors.secondaryRed};
    border: 1px solid ${colors.primaryRed};
    padding: ${isMobile ? '12px' : '13px 16px'};
    border-radius: 6px;

    margin: 20px 0;

    p:nth-of-type(even) {
      margin-bottom: 0;
    }
  `,
  form: css`
    .ant-form-item-label {
      font-weight: bold;
    }

    input,
    textarea,
    .ant-upload .ant-upload-drag,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      font-weight: 500;
      font-size: 0.75rem;
      height: 40px;
      /* border-radius: 6px; */

      display: flex;
      align-items: center;
    }

    #content {
      height: 200px;
      margin-bottom: ${isMobile ? 65 : 40}px;
    }

    textarea {
      height: initial;
    }

    .ant-form-item-explain-error {
      font-size: 0.65rem;
    }

    .ant-upload-text,
    .ant-upload-hint {
      font-size: 0.8rem !important;
    }

    .ql-editor {
      min-height: 300px;
    }
  `,
  uploadButton: css`
    font-size: 1.125rem;
  `
});
