import ProtectedRoute from 'components/ProtectedRoute';

import Home from 'pages/Home';
import Profile from 'pages/Profile';
import DetailsPage from 'pages/Details';
import CategoryPage from 'pages/Category';
import LawConsultation from 'pages/LawConsultation';
import SearchResult from 'pages/SearchResult';
import AboutUs from 'pages/AboutUs';
import CyberMediaGuideline from 'pages/CyberMediaGuideline';
import TermsAndConditions from 'pages/TermsAndConditions';
import CommunityGuideline from 'pages/CommunityGuideline';
import FAQ from 'pages/FAQ';
import AdsInfo from 'pages/AdsInfo';
import AccountSetting from 'components/pages/AccountSettings';
import EditProfile from 'pages/AccountSetting/EditProfile';
import ChangePassword from 'pages/AccountSetting/ChangePassword';
import Gallery from 'pages/Gallery';
import UploadLetter from 'pages/UploadLetter';
import BuyAds from 'pages/BuyAds';
import CheckOpinion from 'pages/AccountSetting/CheckOpinion';
import WriteOpinion from 'pages/AccountSetting/WriteOpinion';
import OpinionDetail from 'pages/AccountSetting/OpinionDetail';
import CheckFeedback from 'pages/AccountSetting/CheckFeedback';
import WriteFeedback from 'pages/AccountSetting/WriteFeedback';
import CheckMyLetter from 'pages/AccountSetting/CheckMyLetter';
import LetterDetail from 'pages/AccountSetting/LetterDetail';
import CheckSuratPengirim from 'pages/AccountSetting/CheckSurat';
import CheckTanggapan from 'pages/AccountSetting/CheckTanggapan';
import CheckGallery from 'pages/AccountSetting/Gallery';
import StrukturRedaksi from 'pages/StrukturRedaksi';

export const WEB_ROUTES = [
  {
    path: 'profile',
    exact: true,
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    )
  },
  {
    path: 'kategori/:category',
    exact: true,
    element: <CategoryPage />
  },
  {
    path: '/kategori/konsultasi-hukum',
    exact: true,
    element: <LawConsultation />
  },
  {
    path: 'hasil-pencarian',
    exact: true,
    element: <SearchResult />
  },
  {
    path: 'berita/:slug',
    exact: true,
    element: <DetailsPage />
  },
  {
    path: 'tentang-kami',
    exact: true,
    element: <AboutUs />
  },
  {
    path: 'pedoman-cyber-media',
    exact: true,
    element: <CyberMediaGuideline />
  },
  {
    path: 'syarat-dan-ketentuan',
    exact: true,
    element: <TermsAndConditions />
  },
  {
    path: 'panduan-komunitas',
    exact: true,
    element: <CommunityGuideline />
  },
  {
    path: 'struktur-redaksi',
    exact: true,
    element: <StrukturRedaksi />
  },
  {
    path: 'mengenai-iklan',
    exact: true,
    element: <AdsInfo />
  },
  {
    path: 'kategori/gallery',
    exact: true,
    element: <Gallery />
  },
  {
    path: 'pengaturan-akun',
    childrens: [
      {
        path: '',
        exact: true,
        element: (
          <AccountSetting>
            <EditProfile />
          </AccountSetting>
        )
      },
      {
        path: 'ganti-password',
        exact: true,
        element: (
          <AccountSetting>
            <ChangePassword />
          </AccountSetting>
        )
      },
      {
        path: 'cek-surat-saya',
        exact: true,
        element: (
          <AccountSetting>
            <CheckMyLetter />
          </AccountSetting>
        )
      },
      {
        path: 'surat-detail/:id',
        exact: true,
        element: (
          <AccountSetting>
            <LetterDetail />
          </AccountSetting>
        )
      },
      {
        path: 'beli-iklan',
        exact: true,
        element: (
          <AccountSetting>
            <BuyAds />
          </AccountSetting>
        )
      },
      {
        path: 'cek-opini',
        exact: true,
        element: (
          <AccountSetting>
            <CheckOpinion />
          </AccountSetting>
        )
      },
      {
        path: 'opini-detail/:id',
        exact: true,
        element: (
          <AccountSetting>
            <OpinionDetail />
          </AccountSetting>
        )
      },
      {
        path: 'tulis-opini',
        exact: true,
        element: (
          <AccountSetting>
            <WriteOpinion />
          </AccountSetting>
        )
      },
      {
        path: 'tulis-tanggapan/:id',
        exact: true,
        element: (
          <AccountSetting>
            <WriteFeedback />
          </AccountSetting>
        )
      },
      {
        path: 'cek-tanggapan',
        exact: true,
        element: (
          <AccountSetting>
            <CheckFeedback />
          </AccountSetting>
        )
      },
      {
        path: 'tanggapan-saya',
        exact: true,
        element: (
          <AccountSetting>
            <CheckTanggapan />
          </AccountSetting>
        )
      },
      {
        path: 'surat-pengirim',
        exact: true,
        element: (
          <AccountSetting>
            <CheckSuratPengirim />
          </AccountSetting>
        )
      },
      {
        path: 'gallery-saya',
        exact: true,
        element: (
          <AccountSetting>
            <CheckGallery />
          </AccountSetting>
        )
      }
    ]
  },
  {
    path: 'bantuan',
    exact: true,
    element: <FAQ />
  },
  {
    path: 'upload-surat',
    exact: true,
    element: <UploadLetter />
  },
  {
    path: '',
    exact: true,
    element: <Home />
  }
];
