/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import styles from './styles';
import { Button } from 'antd';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import newsAPI from 'APIs/news';
import { STATUS_NEWS } from 'constants/status';

export default function CheckMyLetterWeb() {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    newsAPI.viewSuratPengirim().then((res) => {
      setNewsData(res?.data);
    });
  }, []);

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      width: 220,
      key: 'id'
    },
    {
      title: 'Status',
      width: 140,
      render: (data) => (
        <div style={{ color: STATUS_NEWS?.[data?.status]?.color }}>
          {STATUS_NEWS?.[data?.status]?.label}
        </div>
      )
    },
    {
      title: 'Action',
      width: 20,
      render: (data) => (
        <Button
          type="primary"
          size="medium"
          onClick={() => navigate(`/pengaturan-akun/surat-detail/${data?.id}`)}>
          Lihat Detail
        </Button>
      )
    }
  ];

  return (
    <div css={styles.container}>
      <header>Cek Surat Saya</header>
      <section>
        <Table columns={columns} dataSource={newsData} pagination={{ position: ['bottomLeft'] }} />
      </section>
    </div>
  );
}
