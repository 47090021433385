import { css } from '@emotion/react';
import colors from 'styles/colors';

export default ({ isMobile }) => ({
  container: css`
    header {
      font-size: ${isMobile ? 0.875 : 1}rem;
      font-weight: 600;
      padding-bottom: 13px;
      border-bottom: 1px solid ${colors.neutral};
    }
    section {
      margin-top: 20px;
      width: ${isMobile ? '100%' : '439px'};
    }
    label {
      font-size: 0.75rem;
      font-weight: 600;
    }
    input {
      font-size: 0.75rem;
      height: 40px;
    }
    button {
      font-size: 0.75rem;
      font-weight: 500;
    }
  `,
  text: css`
    color: ${colors.tertiaryGrey};
    font-size: 0.625rem;
    font-weight: 400;
    width: ${isMobile ? 'auto' : 'max-content'};
    margin-top: 9px;
  `
});
