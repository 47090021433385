/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { Pagination, Card as ANTDCard, Skeleton } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import newsAPI from 'APIs/news';

import Card from 'components/Card';
import HorizontalCard from 'components/NewsCard/HorizontalCard';
import DynamicImage from 'components/DynamicImage';
import CategoryBadge from 'components/CategoryBadge';
import Empty from 'components/Empty';

import { removeHTMLTagsFromString } from 'utils/string';
import useGlobal from 'contexts/global';

import { generateMargin } from 'styles/utils';
import getStyles from './styles';

const initialState = {
  isLoading: false,
  error: null,
  data: Array.from({ length: 15 }),
  totalData: 0
};

export default function SearchResultNews() {
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const [params, setParams] = useSearchParams({});
  const title = params.get('title');
  const currentPage = Number(params.get('page') ?? 1);

  const [{ data, totalData, isLoading }, setState] = useState(initialState);
  useEffect(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { data, error } = await newsAPI.getNewsWithConfigs({
      title,
      filter: location.search
    });

    setState((prev) => ({
      ...prev,
      error,
      data: data?.data,
      totalData: data?.total,
      isLoading: false
    }));

    window.scrollTo({ top: 0 });
  }, [title, location.search]);

  const onCardClickedRedirect = (slug) => {
    navigate(isLoading ? '/' : `/berita/${slug}`);
    window.scrollTo({ top: 0 });
  };

  const onPageChange = (page) => {
    setParams({ page, title });
  };

  if (data?.length === 0 && !isLoading)
    return (
      <Empty
        width={isMobile ? '100%' : '656px'}
        height={isMobile ? '100%' : '978px'}
        position={isMobile ? 'center' : 'top'}
      />
    );

  if (isMobile) {
    return (
      <HorizontalCard.MobileCard
        isLoading={isLoading}
        data={data}
        onClick={onCardClickedRedirect}
        config={{
          displayDate: true
        }}>
        <div css={generateMargin({ type: 'initial', value: '47px 10px 10px 10px' })}>
          <Pagination
            current={currentPage}
            total={totalData}
            onChange={onPageChange}
            showSizeChanger={false}
            showLessItems
          />
        </div>
      </HorizontalCard.MobileCard>
    );
  }

  return (
    <>
      <Card style={styles.card}>
        {data?.map((news, idx) => (
          <ANTDCard.Meta
            key={idx}
            onClick={() => onCardClickedRedirect(news?.slug)}
            avatar={
              <DynamicImage src={news?.banner_path} alt={news?.title} width={176} height={123} />
            }
            description={
              <>
                {isLoading ? (
                  <Skeleton active paragraph={{ rows: 2 }} />
                ) : (
                  <>
                    <CategoryBadge color={news?.category?.color} title={news?.category?.name} />
                    <div css={styles.cardTitle}>{news?.title}</div>
                    <div css={styles.cardDescription}>
                      {removeHTMLTagsFromString(news?.content)}
                    </div>
                  </>
                )}
              </>
            }
          />
        ))}
        <div css={generateMargin({ type: 'top', value: '20px' })}>
          <Pagination
            total={totalData}
            current={currentPage}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </div>
      </Card>
    </>
  );
}
