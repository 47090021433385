/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Select, Form } from 'antd';
import moment from 'moment';

import Card from 'components/Card';
import useGlobal from 'contexts/global';

import utilsAPI from 'APIs/utils';

import getStyles from './styles';
import { useParams, useSearchParams } from 'react-router-dom';

const datePlaceholder = ['Dari tanggal', 'Hingga tanggal'];

const initialState = {
  locations: [],
  sorts: []
};

export const WORLD = [
  'Bulgaria',
  'Botswana',
  'Guinea',
  'Barbados',
  'Senegal',
  'Guadeloupe',
  'French Guiana',
  'Mali',
  'Namibia',
  'Ukraine',
  'Cook Islands',
  'Georgia',
  'North Macedonia',
  'Afghanistan',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Venezuela',
  'São Tomé and Príncipe',
  'Hungary',
  'Solomon Islands',
  'DR Congo',
  'Brazil',
  'Mozambique',
  'Central African Republic',
  'Norfolk Island',
  'Gibraltar',
  'Cayman Islands',
  'Laos',
  'Turkey',
  'South Africa',
  'Sri Lanka',
  'Nigeria',
  'Turks and Caicos Islands',
  'Sweden',
  'Faroe Islands',
  'Eswatini',
  'Philippines',
  'Romania',
  'Dominica',
  'Kyrgyzstan',
  'South Sudan',
  'United States Virgin Islands',
  'Antarctica',
  'Benin',
  'Uzbekistan',
  'Pakistan',
  'Rwanda',
  'Malta',
  'El Salvador',
  'Peru',
  'Italy',
  'South Georgia',
  'Vietnam',
  'Zambia',
  'New Zealand',
  'Somalia',
  'Monaco',
  'Kenya',
  'Bouvet Island',
  'Sudan',
  'United States Minor Outlying Islands',
  'Vanuatu',
  'Fiji',
  'Austria',
  'Saint Lucia',
  'Aruba',
  'Algeria',
  'Seychelles',
  'Netherlands',
  'Micronesia',
  'Sint Maarten',
  'Tonga',
  'Montenegro',
  'Wallis and Futuna',
  'Ireland',
  'Antigua and Barbuda',
  'Gambia',
  'Isle of Man',
  'Kiribati',
  'Togo',
  'Anguilla',
  'Brunei',
  'Bhutan',
  'Germany',
  'Denmark',
  'Chad',
  'United Arab Emirates',
  'Sierra Leone',
  'Luxembourg',
  'Svalbard and Jan Mayen',
  'Belarus',
  'Gabon',
  'Tunisia',
  'Cyprus',
  'American Samoa',
  'Hong Kong',
  'Haiti',
  'Mauritania',
  'Colombia',
  'Comoros',
  'Tuvalu',
  'Iran',
  'San Marino',
  'Poland',
  'Ghana',
  'Guam',
  'British Indian Ocean Territory',
  'Kazakhstan',
  'Liberia',
  'Republic of the Congo',
  'Falkland Islands',
  'Bangladesh',
  'Tanzania',
  'Finland',
  'Indonesia',
  'Jersey',
  'Panama',
  'Myanmar',
  'Turkmenistan',
  'Heard Island and McDonald Islands',
  'Curaçao',
  'Equatorial Guinea',
  'Albania',
  'Egypt',
  'Martinique',
  'Bermuda',
  'Cocos (Keeling) Islands',
  'North Korea',
  'Qatar',
  'Zimbabwe',
  'Bahrain',
  'Cape Verde',
  'Marshall Islands',
  'Ivory Coast',
  'Kuwait',
  'Macau',
  'Liechtenstein',
  'Saudi Arabia',
  'Nicaragua',
  'Russia',
  'Moldova',
  'Croatia',
  'Suriname',
  'Samoa',
  'Jordan',
  'Iraq',
  'Argentina',
  'Tajikistan',
  'Switzerland',
  'Spain',
  'Taiwan',
  'India',
  'Norway',
  'Estonia',
  'Dominican Republic',
  'Malawi',
  'Saint Kitts and Nevis',
  'Mauritius',
  'Niger',
  'Canada',
  'Yemen',
  'South Korea',
  'Trinidad and Tobago',
  'Andorra',
  'Pitcairn Islands',
  'Guinea-Bissau',
  'Djibouti',
  'Cameroon',
  'Australia',
  'French Southern and Antarctic Lands',
  'Singapore',
  'Réunion',
  'Papua New Guinea',
  'Greece',
  'France',
  'Saint Vincent and the Grenadines',
  'Uruguay',
  'Syria',
  'Bolivia',
  'Grenada',
  'Lithuania',
  'Åland Islands',
  'Jamaica',
  'Guernsey',
  'Czechia',
  'Belgium',
  'Serbia',
  'Guatemala',
  'Timor-Leste',
  'Bosnia and Herzegovina',
  'Eritrea',
  'Nauru',
  'Paraguay',
  'Lebanon',
  'Morocco',
  'Honduras',
  'Slovenia',
  'United Kingdom',
  'Saint Martin',
  'Azerbaijan',
  'Costa Rica',
  'Kosovo',
  'China',
  'Palau',
  'Portugal',
  'Chile',
  'Cambodia',
  'Latvia',
  'Western Sahara',
  'Angola',
  'United States',
  'Mayotte',
  'Caribbean Netherlands',
  'Vatican City',
  'Mexico',
  'Belize',
  'Thailand',
  'Tokelau',
  'British Virgin Islands',
  'Israel',
  'Maldives',
  'Uganda',
  'Cuba',
  'Iceland',
  'Mongolia',
  'Malaysia',
  'Nepal',
  'Saint Pierre and Miquelon',
  'Japan',
  'Saint Barthélemy',
  'Northern Mariana Islands',
  'Burundi',
  'Greenland',
  'Libya',
  'Armenia',
  'Ecuador',
  'French Polynesia',
  'Burkina Faso',
  'Oman',
  'Guyana',
  'Ethiopia',
  'Madagascar',
  'Christmas Island',
  'Puerto Rico',
  'Slovakia',
  'Lesotho',
  'Montserrat',
  'New Caledonia',
  'Niue',
  'Palestine',
  'Bahamas'
];

export default function Filter() {
  const [form] = Form.useForm();
  const { category } = useParams();
  const [params, setSearchParams] = useSearchParams({});

  const { isMobile } = useGlobal();
  const styles = getStyles({ isMobile });
  const [{ locations, sorts }, setFilter] = useState(initialState);
  const [subCtg, setSubCtg] = useState([]);

  useEffect(() => {
    utilsAPI.getSubCtg().then((res) => {
      setSubCtg(res?.data?.map((ctg) => ({ name: ctg?.name, id: ctg?.id })));
    });
  }, []);

  const onFormSubmit = ({ location = '', sort = '', date, sub_category }) => {
    const title = params.get('title');

    let queryParams = {
      page: 1,
      location,
      sort,
      date,
      sub_category
    };

    if (!location) {
      delete queryParams.location;
    }
    if (!sort) {
      delete queryParams.sort;
    }
    if (!sub_category) {
      delete queryParams.sub_category;
    }

    if (title) {
      queryParams = { ...queryParams, title };
    }

    if (date && date.length > 0) {
      const start_date = moment(date[0]).format('YYYY-MM-DD');
      const end_date = moment(date[1]).format('YYYY-MM-DD');

      queryParams = { ...queryParams, start_date, end_date };
    }

    delete queryParams.date;
    setSearchParams(queryParams);
  };

  useEffect(() => {
    async function fetchContent() {
      const [{ data: locations = [] }, { data: sorts = [] }] = await Promise.all([
        utilsAPI.getLocations(),
        utilsAPI.getSorts()
      ]);

      setFilter({ locations, sorts });
    }

    fetchContent();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [category]);

  return (
    <Card style={styles.filter}>
      <p>Filter</p>
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormSubmit}
        initialValues={{
          location: params.get('location'),
          sort: params.get('sort'),
          date: params.get('start_date') && [
            moment(params.get('start_date')),
            moment(params.get('end_date'))
          ]
        }}>
        <Form.Item label="Lokasi Kejadian" name="location">
          <Select
            placeholder="Pilih Lokasi Kejadian"
            css={styles.input}
            disabled={locations.length == 0}>
            {[...locations, ...WORLD].map((location) => (
              <Select.Option value={location} key={location}>
                {location}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Tanggal" name="date">
          <DatePicker.RangePicker css={styles.input} placeholder={datePlaceholder} />
        </Form.Item>
        <Form.Item label="Urutkan berdasarkan" name="sort">
          <Select placeholder="Likes terbanyak" css={styles.input} disabled={sorts.length == 0}>
            {sorts.map(({ key, label }) => (
              <Select.Option value={key} key={key}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Kategori" name="sub_category">
          <Select placeholder="Kategori" css={styles.input} disabled={sorts.length == 0}>
            {subCtg.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button shape="round" css={styles.button} htmlType="submit">
          Filter
        </Button>
      </Form>
    </Card>
  );
}
